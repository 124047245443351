import { CrudBase } from '@app/mock/models';
import {
  ApiResponseOfIEnumerableOfVehicleTypeDto,
  ApiResponseOfListOfApiResponseOfVehicleTypeDto,
  ApiResponseOfVehicleTypeDto,
  ApiSortDirection,
  IVehicleTypesApiService,
  VehicleTypeDto
} from '@api';
import { Injectable } from '@angular/core';
import { VEHICLE_TYPES } from '@app/mock/data/vehicle-types';
import { Observable } from 'rxjs';

@Injectable()
export class VehicleTypeApiMockService extends CrudBase<VehicleTypeDto> implements IVehicleTypesApiService {
  constructor() {
    super(VEHICLE_TYPES);
  }

  getPagedVehicleTypes(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                       sortName?: string[], sortDirection?: ApiSortDirection)
    : Observable<ApiResponseOfIEnumerableOfVehicleTypeDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getVehicleType(id: number): Observable<ApiResponseOfVehicleTypeDto> {
    return this.get(id);
  }

  createVehicleType(dto: VehicleTypeDto): Observable<ApiResponseOfVehicleTypeDto> {
    return this.create(dto);
  }

  updateVehicleType(id: number, dto: VehicleTypeDto): Observable<ApiResponseOfVehicleTypeDto> {
    return this.update(id, dto);
  }

  deleteVehicleType(id: number): Observable<ApiResponseOfVehicleTypeDto> {
    return this.delete(id);
  }

  deleteBulkVehicleTypes(id?: number[]): Observable<ApiResponseOfListOfApiResponseOfVehicleTypeDto> {
    return this.deleteBulkVehicleTypes(id);
  }


}
