import { Injectable } from '@angular/core';
import { CurrentUserService } from '@core/authentication/services/current-user.service';
import { PermissionsService } from '@shared/permissions/services/permissions.service';
import { Observable } from 'rxjs';
import { PermissionDto, RuiterUserDto } from '@api';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Permission, PermissionCheckType } from '../models';

@Injectable({
  providedIn: 'root'
})
export class HasPermissionService {

  private static readonly adminPermission = Permission.GLOBAL_ADMIN;

  private readonly currentUser$: Observable<RuiterUserDto | null>;
  private readonly permissions$: Observable<PermissionDto[]>;

  constructor(private currentUserService: CurrentUserService,
              private permissionsService: PermissionsService) {
    this.currentUser$ = this.currentUserService.getCurrentUser();
    this.permissions$ = this.permissionsService.getAll();
  }

  hasPermission(permissionKey: Permission | Permission[] | string | string[] | null | undefined): Observable<boolean> {
    return this.currentUser$.pipe(
      distinctUntilChanged(),
      map(user => this.hasUserPermission(user, permissionKey)),
      distinctUntilChanged(),
    );
  }

  hasAllPermissions(permissionKey: Permission | Permission[] | string | string[] | null | undefined): Observable<boolean> {
    return this.currentUser$.pipe(
      distinctUntilChanged(),
      map(user => this.hasUserPermission(user, permissionKey, 'every')),
      distinctUntilChanged(),
    );
  }

  hasPermissionInstant(permissionKey: Permission | string | null | undefined | string[], checkType: PermissionCheckType = 'some'): boolean {
    const currentUser = this.currentUserService.getCurrentUserInstant();
    return this.hasUserPermission(currentUser, permissionKey, checkType);
  }

  private hasUserPermission(user: RuiterUserDto | null, permissionKey: Permission | Permission[] | string | string[] | null | undefined, checkType: PermissionCheckType = 'some'): boolean {
    if (!user) {
      return false;
    }
    if (!permissionKey || permissionKey.length < 1) {
      return true;
    }
    const permissions = user?.permissions || [];

    if (Array.isArray(permissionKey)) {
      if (checkType === 'some') {
        return permissionKey.some(permission => permissions.includes(permission))
          || permissions.includes(HasPermissionService.adminPermission);
      } else {
        return permissionKey.every(permission => permissions.includes(permission))
          || permissions.includes(HasPermissionService.adminPermission);
      }
    }
    return permissions.includes(permissionKey)
      || permissions.includes(HasPermissionService.adminPermission);
  }
}
