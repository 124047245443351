<mat-card class="mat-elevation-z4">
  <ng-container *ngIf="user$ | async as context; else loading">
    <div mat-card-avatar>
      {{ context.username | slice:0:1}}
    </div>
    <mat-card-header>
      <mat-card-title>{{ context.username }}</mat-card-title>
    </mat-card-header>
  </ng-container>

  <mat-list dense>
    <mat-list-item *ngFor="let viewAction of viewActions" (click)="onViewActionClick(viewAction)">
      <mat-icon mat-list-icon>{{ viewAction.buildIcon() | translate }}</mat-icon>
      <p mat-line>{{ viewAction.buildLabel() | translate }}</p>
    </mat-list-item>
  </mat-list>

  <mat-card-footer>
    <div class="version-text">{{ version }}</div>
  </mat-card-footer>
</mat-card>
<ng-template #loading>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</ng-template>
