import { Injectable } from '@angular/core';
import {
  ApiResponseOfIEnumerableOfRuiterUserDto,
  ApiResponseOfRuiterUserDto,
  ApiSortDirection,
  InviteUserDto,
  IUsersApiService,
  ReinviteUserDto,
  RuiterUserDto,
  UserStatus
} from '@api';
import { USERS } from '@app/mock/data/users';
import { CrudBase } from '@app/mock/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersApiMockService extends CrudBase<RuiterUserDto> implements IUsersApiService {

  constructor() {
    super(USERS);
  }

  deleteUser(id: number): Observable<ApiResponseOfRuiterUserDto> {
    return this.delete(id);
  }

  getPagedUsers(filterName?: string[], filterValue?: string, sortName?: string[],
                sortDirection?: ApiSortDirection, skip?: number,
                take?: number): Observable<ApiResponseOfIEnumerableOfRuiterUserDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getUser(id: number): Observable<ApiResponseOfRuiterUserDto> {
    return this.get(id);
  }

  updateUser(id: number, dto: RuiterUserDto): Observable<ApiResponseOfRuiterUserDto> {
    return this.update(id, dto);
  }

  inviteUser(dto: InviteUserDto): Observable<ApiResponseOfRuiterUserDto> {
    return this.create({
      id: null,
      username: dto.mailAddress,
      firstname: 'Max',
      lastname: 'Mustermann',
      isEnabled: true,
      isPasswordChangeRequired: false,
      status: UserStatus.Invited,
      permissions: [],
      phoneNumber: null,
      created: null,
      lastChange: null
    });
  }

  reinviteUser(dto: ReinviteUserDto): Observable<ApiResponseOfRuiterUserDto> {
    return this.get(dto.userId).pipe(
      map(user => ({
        data: user.data,
        success: true,
        message: null,
        count: null,
        maxCount: null
      }))
    );
  }
}
