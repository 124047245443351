import { OrderStatusApiService } from '@api/api.service';
import { ReceiptStatusService } from '../base';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService extends ReceiptStatusService {

  constructor(api: OrderStatusApiService) {
    super(api);
  }

}
