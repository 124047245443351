import { ConstructionProjectInvoicesApiService, InvoiceDto } from '@api/api.service';
import { AntCollectionSortLoadingParams, GetCollectionApiService } from '@api/models';
import { LoadingParamsUtils } from '@api/utils';
import { Injectable } from '@angular/core';
import { AntDataResponse } from '@antony/ng-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConstructionProjectInvoiceService implements GetCollectionApiService<InvoiceDto> {

  constructor(protected api: ConstructionProjectInvoicesApiService) {
  }

  getData(params: AntCollectionSortLoadingParams<InvoiceDto>): Observable<AntDataResponse<InvoiceDto>> {
    return this.api.getAllInvoices(
      LoadingParamsUtils.getIdFrom(params),
      ...LoadingParamsUtils.transformToArray(params)
    ).pipe(
      map((res: any) => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }
}
