import { ApiResponseOfListOfInvoiceDto, ApiSortDirection, IConstructionProjectInvoicesApiService, InvoiceDto } from '@api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudBase } from '@app/mock/models';
import { INVOICES } from '@app/mock/data/invoices';

@Injectable()
export class ConstructionProjectInvoiceApiMockService extends CrudBase<InvoiceDto> implements IConstructionProjectInvoicesApiService {

  constructor() {
    super(INVOICES);
  }

  getAllInvoices(constructionProjectId: number, skip?: number, take?: number, filterName?: string[] | null, filterValue?: string | null,
                 sortName?: string[] | null, sortDirection?: ApiSortDirection): Observable<ApiResponseOfListOfInvoiceDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

}
