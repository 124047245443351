import { ExtendedFormGroup, SearchSelectField, SearchSelectFieldOptions } from '@antony/ng-forms';

export class SelectForm<T> extends ExtendedFormGroup<{ data: T}> {
  constructor(options: SearchSelectFieldOptions<T>) {
    super({
      controls: {
        data: new SearchSelectField<T>({
          icon: 'APP.ACTIONS.SELECT.ICON',
          label: 'APP.ACTIONS.SELECT.LABEL',
          required: true,
          ...options
        })
      }
    });
  }
}
