import { WarehouseDto } from '@api';

export const WAREHOUSES: WarehouseDto[] = [
  {
    id: 1,
    name: 'Hauptstandort Bocholt',
    address: '46395 Bocholt, Brinkstegge 37',
    created: new Date().toISOString(),
    lastChange: new Date().toISOString(),
  }
];
