import { ApiSortDirection, ContactPersonDto, IContactPersonsApiService } from '@api';
import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { CONTACT_PERSONS } from '@app/mock/data/contact-persons';
import { NEVER, Observable } from 'rxjs';

@Injectable()
export class ContactPersonsApiMockService extends CrudBase<ContactPersonDto> implements IContactPersonsApiService {

  constructor() {
    super(CONTACT_PERSONS);
  }

  getPagedContactPersons(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                         sortName?: string[], sortDirection?: ApiSortDirection): Observable<GenericApiResponse<ContactPersonDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getContactPerson(id: number): Observable<GenericApiResponse<ContactPersonDto>> {
    return this.get(id);
  }

  createContactPerson(item: ContactPersonDto): Observable<GenericApiResponse<ContactPersonDto>> {
    return this.create(item);
  }

  updateContactPerson(id: number, item: ContactPersonDto): Observable<GenericApiResponse<ContactPersonDto>> {
    return this.update(id, item);
  }

  deleteContactPerson(id: number): Observable<GenericApiResponse<ContactPersonDto>> {
    return this.delete(id);
  }

  deleteBulkContactPersons(id?: number[]): Observable<GenericApiResponse<GenericApiResponse<ContactPersonDto>[]>> {
    return NEVER;
  }

}
