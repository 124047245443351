import { CrudBase, GenericApiResponse } from '@app/mock/models';
import {
  AddCleaningPositionDto,
  AddRollingPositionDto,
  AddWaitingTimePositionDto,
  ApiResponseOfCreditNoteDto,
  ApiResponseOfCreditNotePositionDto,
  ApiResponseOfCreditNoteVersionDto,
  ApiResponseOfDownloadReportDto,
  ApiResponseOfIEnumerableOfCreditNoteDto,
  ApiResponseOfListOfCreditNoteVersionDto,
  ApiSortDirection,
  CreditNoteDto,
  CreditNoteStatusDto,
  CreditNoteVersionDto,
  DefaultPositionCalculationDto,
  FileResponse,
  ICreditNotesApiService,
  ReceiptStatusUpdateDto
} from '@api';
import { CREDIT_NOTES } from '@app/mock/data/credit_notes';
import { NEVER, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseReceiptSystemPropertiesDto } from '@api/models/receipt/base/base-receipt-system-properties.dto';

@Injectable()
export class CreditNotesApiMockService extends CrudBase<CreditNoteDto> implements ICreditNotesApiService {
  constructor() {
    super(CREDIT_NOTES);
  }

  updateStatus(updateDto: ReceiptStatusUpdateDto): Observable<ApiResponseOfListOfCreditNoteVersionDto> {
    return NEVER;
  }

  getPagedReceipts(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                   sortName?: string[], sortDirection?: ApiSortDirection): Observable<GenericApiResponse<CreditNoteDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getReceipt(id: number): Observable<GenericApiResponse<CreditNoteDto>> {
    return this.get(id);
  }

  requestReportDownload(creditNoteId: number): Observable<ApiResponseOfDownloadReportDto> {
    return NEVER;
  }

  downloadReport(token?: string | null | undefined): Observable<FileResponse> {
    return NEVER;
  }

  updateReceipt(creditNoteId: number, dto: CreditNoteVersionDto): Observable<ApiResponseOfCreditNoteVersionDto> {
    return NEVER;
  }

  getTransferredCreditNotes(creditNoteId: number, skip?: number, take?: number,
                            filterName?: string[] | null, filterValue?: string | null,
                            sortName?: string[] | null, sortDirection?: ApiSortDirection)
    : Observable<ApiResponseOfIEnumerableOfCreditNoteDto> {
    return NEVER;
  }

  transferReceipt(creditNoteId: number): Observable<ApiResponseOfCreditNoteDto> {
    return NEVER;
  }

  addUnsuccessfullDeliveryPositions(creditNoteId: number): Observable<ApiResponseOfCreditNoteVersionDto> {
    return NEVER;
  }

  addWaitingTimePosition(dto: AddWaitingTimePositionDto, creditNoteId: number): Observable<ApiResponseOfCreditNoteVersionDto> {
    return NEVER;
  }

  release(creditNoteId: number): Observable<ApiResponseOfCreditNoteVersionDto> {
    return NEVER;
  }

  addRollingPosition(dto: AddRollingPositionDto, creditNoteId: number): Observable<ApiResponseOfCreditNoteVersionDto> {
    return NEVER;
  }

  addCleaningPosition(dto: AddCleaningPositionDto, creditNoteId: number): Observable<ApiResponseOfCreditNoteVersionDto> {
    return NEVER;
  }

  updateReleasedReceipt(creditNoteId: number,
                        data: BaseReceiptSystemPropertiesDto<CreditNoteStatusDto>): Observable<ApiResponseOfCreditNoteVersionDto> {
    return NEVER;
  }

  calculatePositionDefaults(receiptId: number, dto: DefaultPositionCalculationDto): Observable<ApiResponseOfCreditNotePositionDto> {
    return NEVER;
  }
}
