import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, map, switchMap, toArray } from 'rxjs/operators';
import { AntDataResponse } from '@antony/ng-ui';
import {
  ConstructionProjectContactPersonsApiService,
  ConstructionProjectContactPersonsDto,
  ContactPersonDto
} from '@api/api.service';
import { AntCollectionSortLoadingParams, ResourceCollectionApiService } from '@api/models';
import { LoadingParamsUtils } from '@api/utils';

@Injectable({
  providedIn: 'root'
})
export class ConstructionProjectContactPersonService implements ResourceCollectionApiService<ContactPersonDto> {
  constructor(protected api: ConstructionProjectContactPersonsApiService) {
  }

  getData(params: AntCollectionSortLoadingParams<ContactPersonDto>): Observable<AntDataResponse<ContactPersonDto>> {
    return this.api.getAllContactPersons(
      LoadingParamsUtils.getIdFrom(params),
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

  add(constructionProjectId: number, contactPersonId: number): Observable<boolean> {
    return this.api.createContactPersons(constructionProjectId, contactPersonId).pipe(
      map(res => res.success)
    );
  }

  addBulk(constructionProjectId: number, contactPersonIds: number[]): Observable<boolean[]> {
    return from(contactPersonIds).pipe(
      concatMap(contactPersonId => this.add(constructionProjectId, contactPersonId)),
      toArray()
    );
  }

  remove(constructionProjectId: number, contactPersonId: number): Observable<boolean> {
    return this.api.deleteContactPerson(constructionProjectId, contactPersonId).pipe(
      map(res => res.success)
    );
  }

  removeBulk(constructionProjectId: number, contactPersonIds: number[]): Observable<boolean[]> {
    return this.api.deleteBulkContactPersons(constructionProjectId, contactPersonIds).pipe(
      map(res => res.data || []),
      switchMap(responses => from(responses)),
      map(res => res.success),
      toArray()
    );
  }

  setBulk(cpId: number, contactPersonIds: number[]): Observable<ConstructionProjectContactPersonsDto | null> {
    const o: ConstructionProjectContactPersonsDto = {
      contactPersons: contactPersonIds.map(x => ({
        id: null,
        created: null,
        lastChange: null,
        constructionProjectId: cpId,
        contactPersonId: x
      }))
    };
    return this.api.setContactPersons(cpId, o).pipe(map(result => result.data));
  }
}
