import { Injectable } from '@angular/core';
import { ResourceApiService } from '@antony/ng-ui';
import {
  DeliveryNoteReplyApiService,
  DeliveryNoteReplyDto,
  DeliveryNoteReplyWithOnlyDeliveryDateDto
} from '@core/api';
import {
  Observable,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteReplyService implements ResourceApiService<DeliveryNoteReplyDto> {
  constructor(private api: DeliveryNoteReplyApiService) {
  }

  get(id: number | null): Observable<DeliveryNoteReplyDto> {
    return this.api.getDeliveryNoteReply(id ?? 0).pipe(
      map(response => response.data)
    );
  }

  create(deliveryNoteReply: DeliveryNoteReplyDto): Observable<DeliveryNoteReplyDto> {
    return this.api.createDeliveryNoteReply(deliveryNoteReply).pipe(
      map(result => result.data),
    );
  }

  getNewDeliveryNoteReply(remoteAccessToken: string): Observable<DeliveryNoteReplyDto> {
    return this.api.getNewForDeliveryNote(remoteAccessToken).pipe(
      map(reply => reply.data)
    );
  }

  getAlreadyExisting(remoteAccessToken: string): Observable<DeliveryNoteReplyWithOnlyDeliveryDateDto[]> {
    return this.api.getDeliveryDatesOfRepliesForDeliveryNote(remoteAccessToken).pipe(
      map(reply => reply.data)
    );
  }

  getReplyByDeliveryNote(id: number): Observable<DeliveryNoteReplyDto[]> {
    return this.api.loadByDeliveryNote(id).pipe(
      map(response => response.data),
      catchError(() => of([]))
    );
  }

  requestDownload(id: number): Observable<string> {
    return this.api.requestReportDownload(id).pipe(
      map(data => data.data.token)
    );
  }
}
