import { Injectable } from "@angular/core";
import { AntDataResponse, AntSortLoadingParams } from "@antony/ng-ui";
import { DeliveryNoteReplyStatusApiService, DeliveryNoteReplyStatusDto } from "@api/api.service";
import { GetDataApiService } from "@api/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class DeliveryNoteReplyStatusService implements GetDataApiService<DeliveryNoteReplyStatusDto> {
    constructor(protected api: DeliveryNoteReplyStatusApiService) { }

    getData(
        params: Partial<AntSortLoadingParams<DeliveryNoteReplyStatusDto>>,
    ): Observable<AntDataResponse<DeliveryNoteReplyStatusDto>> {
        return this.api.getAllStatus().pipe(
            map(r => {
                return {
                    ...r,
                    maxCount: r.data.length,
                };
            }),
        );
    }
}
