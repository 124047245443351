import { DeliveryNoteAssignmentDto } from '@api';
import { DELIVERY_NOTES } from '@app/mock/data/delivery_notes';
import { VEHICLES } from '@app/mock/data/vehicles';

const today = new Date().toISOString();

const baseDeliveryNoteAssignment: DeliveryNoteAssignmentDto = {
  id: 0,
  created: today,
  lastChange: today,

  deliveryNote: DELIVERY_NOTES[0],
  deliveryNoteId: DELIVERY_NOTES[0].id ?? 0,
  vehicle: VEHICLES[0],
  vehicleId: VEHICLES[0].id ?? 0,

  dispositionDate: today,
  order: 0,
};

export const DELIVERY_NOTE_ASSIGNMENTS: DeliveryNoteAssignmentDto[] = [
  {
    ...baseDeliveryNoteAssignment,
    id: 1,
    order: 1
  }
];
