import { Injectable } from '@angular/core';
import { GetDataApiService } from '@api/models';
import {
  CreditNoteDto,
  CreditNoteKindsApiService,
  CreditNotePositionDto,
  CreditNoteVersionDto,
  InvoiceKindsApiService
} from '@api/api.service';
import { AntDataResponse } from '@antony/ng-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReceiptKindService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteKindService extends ReceiptKindService<CreditNoteDto, CreditNoteVersionDto, CreditNotePositionDto>
  implements GetDataApiService<string> {

  constructor(protected api: CreditNoteKindsApiService) {
    super(api);
  }

}
