import { Pipe, PipeTransform } from '@angular/core';
import { Person } from '@shared/models/person';
import { DisplayNamePipe } from '@shared/pipes/display-name.pipe';

@Pipe({
  name: 'displayNameMultiline'
})
export class DisplayNameMultilinePipe implements PipeTransform {
  public static getMultilineHTML(value: string): string {
    return value.replace(/\n/gm, '<br>');
  }

  transform(value: Person | null | undefined, args?: any): string | null {
    if (!value) {
      return null;
    }
    return DisplayNameMultilinePipe.getMultilineHTML(DisplayNamePipe.getName(value as Person));
  }
}
