import { VehicleTypePriceDto } from '@api';

const baseVehiclePrice: VehicleTypePriceDto = {
  id: null,
  created: null,
  lastChange: null,
  basePrice: 1.00,
  pricePerHour: 2.00
};

export const VEHICLE_TYPE_PRICES: VehicleTypePriceDto[] = [
  {
    ...baseVehiclePrice,
    id: 1
  }
];
