import { Injectable } from '@angular/core';
import { AntDataResponse } from '@antony/ng-ui';
import { ResourceApiService } from '@antony/ng-ui/lib/detail/models/resource-api-service';
import {
  ReceiptExportFieldSettingsApiService,
  ReceiptExportFieldSettingsDto,
  ReceiptExportFieldSettingsSummaryModel,
  ReceiptExportType,
} from '@api';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReceiptExportFieldSettingsService implements ResourceApiService<ReceiptExportFieldSettingsSummaryModel> {
  constructor(private exportFieldSettingsApi: ReceiptExportFieldSettingsApiService) {
  }

  getAllByReceiptType(receiptType: ReceiptExportType): Observable<AntDataResponse<ReceiptExportFieldSettingsDto>> {
    return this.exportFieldSettingsApi.getAllByType(receiptType).pipe(
      map(response => ({
        data: response.data,
        maxCount: response.maxCount ?? 0,
      })),
    );
  }

  get(id: ReceiptExportFieldSettingsSummaryModel['id']): Observable<ReceiptExportFieldSettingsSummaryModel> {
    return combineLatest([
      this.getAllByReceiptType(ReceiptExportType.Offer),
      this.getAllByReceiptType(ReceiptExportType.Order),
      this.getAllByReceiptType(ReceiptExportType.Invoice),
      this.getAllByReceiptType(ReceiptExportType.CreditNote),
      this.getAllByReceiptType(ReceiptExportType.DeliveryNote),
    ]).pipe(
      map(([
        offerResponse,
        orderResponse,
        invoiceResponse,
        creditNoteResponse,
        deliveryNoteResponse,
      ]): ReceiptExportFieldSettingsDto[][] => ([
        offerResponse.data,
        orderResponse.data,
        invoiceResponse.data,
        creditNoteResponse.data,
        deliveryNoteResponse.data,
      ])),
      map(([
        offerSettings,
        orderSettings,
        invoiceSettings,
        creditNoteSettings,
        deliveryNoteSettings,
      ]): ReceiptExportFieldSettingsSummaryModel => {
        return {
          id: null,
          lastChange: null,
          created: null,
          offerSettings,
          orderSettings,
          invoiceSettings,
          creditNoteSettings,
          deliveryNoteSettings,
        };
      }),
    );
  }

  updateAllByReceiptType(receiptType: ReceiptExportType, fields: ReceiptExportFieldSettingsDto[]) {
    let mappedFields: any = {};
    for (const field of fields) {
      mappedFields = {
        ...mappedFields,
        [field.fieldName]: field.fieldIndex,
      };
    }
    return this.exportFieldSettingsApi.updateSettings(receiptType, mappedFields);
  }
}
