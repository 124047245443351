<h2 matDialogTitle>{{ (config?.title || '') | translate }}</h2>
<ng-container *ngIf="form">
  <mat-dialog-content>
    <ant-form #formComp [form]="form" (submitted)="submit()"></ant-form>
    <mat-progress-spinner *ngIf="!form"></mat-progress-spinner>
  </mat-dialog-content>
  <mat-dialog-actions>
    <mat-error class="form-errors" *ngIf="getError(form?.errors) as error">
      <ng-container *ngIf="!!form.errorMessages">
        {{ (form.errorMessages[error.key] || '') | translate }}
      </ng-container>
    </mat-error>
    <ant-button (click)="abort()"
                actionName="ABORT"
    ></ant-button>
    <ant-button mode="stroked"
                *ngIf="config.extraButton"
                [color]="config.extraButton.color"
                (click)="onClickExtraButton()"
                [prefix]="config.extraButton.submitPrefix ?? 'empty'"
                [actionName]="config.extraButton.submitLabel ?? 'empty'">
    </ant-button>
    <ant-button mode="raised"
                color="primary"
                *appHasPermission="permission"
                (click)="formComp?.submit()"
                [disabled]="!canSubmit()"
                [loading]="submitting"
                [prefix]="config.submitPrefix"
                [actionName]="config.submitLabel">
    </ant-button>
  </mat-dialog-actions>
</ng-container>
<mat-dialog-content *ngIf="!form">
  <mat-progress-spinner></mat-progress-spinner>
</mat-dialog-content>
