import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, map, switchMap, toArray } from 'rxjs/operators';
import { AntDataResponse } from '@antony/ng-ui';
import { AntCollectionSortLoadingParams, ResourceCollectionApiService } from '@api/models';
import { CustomerDto, CustomerSubCustomersApiService } from '@api/api.service';
import { LoadingParamsUtils } from '@api/utils';

@Injectable({
  providedIn: 'root'
})
export class CustomerSubCustomerService implements ResourceCollectionApiService<CustomerDto> {

  constructor(private api: CustomerSubCustomersApiService) {
  }

  getData(params: AntCollectionSortLoadingParams<CustomerDto>): Observable<AntDataResponse<CustomerDto>> {
    return this.api.getPagedSubCustomers(
      LoadingParamsUtils.getIdFrom(params),
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

  add(customerId: number, subCustomerId: number): Observable<boolean> {
    return this.api.createSubCustomer(customerId, subCustomerId).pipe(
      map(res => res.success)
    );
  }

  addBulk(customerId: number, subCustomerIds: number[]): Observable<boolean[]> {
    return from(subCustomerIds).pipe(
      concatMap(subCustomerId => this.add(customerId, subCustomerId)),
      toArray()
    );
  }

  remove(customerId: number, subCustomerId: number): Observable<boolean> {
    return this.api.deleteSubCustomer(customerId, subCustomerId).pipe(
      map(res => res.success)
    );
  }

  removeBulk(customerId: number, subCustomerIds: number[]): Observable<boolean[]> {
    return this.api.deleteBulkSubCustomers(customerId, subCustomerIds).pipe(
      map(res => res.data || []),
      switchMap(responses => from(responses)),
      map(res => res.success),
      toArray()
    );
  }

}
