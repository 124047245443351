import { OfferStatusApiService } from '@api/api.service';
import { ReceiptStatusService } from '../base';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OfferStatusService extends ReceiptStatusService {

  constructor(api: OfferStatusApiService) {
    super(api);
  }

}
