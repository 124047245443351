import { Provider } from '@angular/core';
import {
  CreditNoteKindsApiService,
  CreditNotesApiService,
  CreditNoteStatusService,
  CreditNoteVersionsApiService,
  DeliveryNoteApiService,
  DeliveryNoteAssignmentApiService,
  DeliveryNoteStatusApiService,
  DeliveryNoteVersionApiService,
  InvoiceKindsApiService,
  InvoicesApiService,
  InvoiceStatusApiService,
  InvoiceVersionsApiService,
  OfferKindsApiService,
  OffersApiService,
  OfferStatusApiService,
  OfferVersionsApiService,
  OrderKindsApiService,
  OrdersApiService,
  OrderStatusApiService,
  OrderVersionsApiService
} from '@api';
import { InvoicesApiMockService } from './invoices.api-mock.service';
import { OffersApiMockService } from './offers.api-mock.service';
import { OrdersApiMockService } from './orders.api-mock.service';
import { OfferVersionsApiMockService } from './offer-versions.api-mock.service';
import { InvoiceVersionsApiMockService } from './invoice-versions.api-mock.service';
import { OrderVersionsApiMockService } from './order-versions.api-mock.service';
import { CreditNotesApiMockService } from './credit-note.api-mock.service';
import { CreditNoteVersionsApiMockService } from './credit-note-versions.api-mock.service';
import { BaseReceiptStatusApiMockService } from './base-receipt-status.api-mock-service';
import { BaseReceiptKindsApiMockService } from './base-receipt-kinds.api-mock-service';
import { DeliveryNotesApiMockService } from './delivery-note.api-mock.service';
import { DeliveryNoteVersionsApiMockService } from './delivery-note-versions.api-mock.service';
import { DeliveryNoteAssignmentsApiMockService } from './delivery-note-assignments.api-mock-service';
import { OFFER_STATUS } from '@app/mock/data/offer_status';
import { ORDER_STATUS } from '@app/mock/data/order_status';
import { DELIVERY_NOTE_STATUS } from '@app/mock/data/delivery_note_status';
import { INVOICE_STATUS } from '@app/mock/data/invoice_status';
import { CREDIT_NOTE_STATUS } from '@app/mock/data/credit_note_status';

export const RECEIPT_PROVIDER: Provider[] = [
  {provide: OffersApiService, useClass: OffersApiMockService},
  {provide: OrdersApiService, useClass: OrdersApiMockService},
  {provide: DeliveryNoteApiService, useClass: DeliveryNotesApiMockService},
  {provide: InvoicesApiService, useClass: InvoicesApiMockService},
  {provide: CreditNotesApiService, useClass: CreditNotesApiMockService},

  {provide: OfferVersionsApiService, useClass: OfferVersionsApiMockService},
  {provide: OrderVersionsApiService, useClass: OrderVersionsApiMockService},
  {provide: DeliveryNoteVersionApiService, useClass: DeliveryNoteVersionsApiMockService},
  {provide: InvoiceVersionsApiService, useClass: InvoiceVersionsApiMockService},
  {provide: CreditNoteVersionsApiService, useClass: CreditNoteVersionsApiMockService},

  {provide: OfferStatusApiService, useValue: new BaseReceiptStatusApiMockService(OFFER_STATUS)},
  {provide: OrderStatusApiService, useValue: new BaseReceiptStatusApiMockService(ORDER_STATUS)},
  {provide: DeliveryNoteStatusApiService, useValue: new BaseReceiptStatusApiMockService(DELIVERY_NOTE_STATUS)},
  {provide: InvoiceStatusApiService, useValue: new BaseReceiptStatusApiMockService(INVOICE_STATUS)},
  {provide: CreditNoteStatusService, useValue: new BaseReceiptStatusApiMockService(CREDIT_NOTE_STATUS)},

  {provide: OfferKindsApiService, useValue: new BaseReceiptKindsApiMockService([])},
  {provide: OrderKindsApiService, useValue: new BaseReceiptKindsApiMockService([])},
  {provide: InvoiceKindsApiService, useValue: new BaseReceiptKindsApiMockService([])},
  {provide: CreditNoteKindsApiService, useValue: new BaseReceiptKindsApiMockService([])},

  {provide: DeliveryNoteAssignmentApiService, useClass: DeliveryNoteAssignmentsApiMockService}
];
