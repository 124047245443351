import { Injectable } from '@angular/core';
import { NEVER, Observable, of } from 'rxjs';
import {
  ApiException,
  AuthApiService,
  LoginDto,
  LoginResultDtoOfRuiterUserDto,
  LogoutDto,
  TokenRenewalDto,
  TokenRenewalResultDtoOfRuiterUserDto
} from '@api';
import { catchError, exhaustMap, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { AuthenticationStorageService } from '@core/authentication/services/authentication-storage.service';

export type LoginResponseType = 'success' | 'invalid' | 'error' | 'timeout' | 'disabled';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private router: Router,
    private authService: AuthApiService,
    private authStorage: AuthenticationStorageService
  ) {
  }

  authenticate(username: string, password: string): Observable<LoginResponseType> {
    const loginDto = this.getLoginDto(username, password);
    return this.authService.login(loginDto).pipe(
      map((result): LoginResponseType => {
        if (!!result) {
          const data = result.data as LoginResultDtoOfRuiterUserDto;
          if (data.isUserDisabled) {
            return 'disabled';
          } else {
            this.authStorage.saveTokenData(data.tokenData as TokenRenewalResultDtoOfRuiterUserDto);
            return 'success';
          }
        } else {
          return 'invalid';
        }
      }),
      catchError((err, caught)
        : Observable<LoginResponseType> => {
        if (err.success !== undefined &&
            !err.data) {
          return of('invalid');

        } else if (!!err.data && !!err.data.isUserDisabled) {
          return of('disabled');

        } else {
          return of('error');
        }
      })
    );
  }

  renew(): Observable<boolean> {
    const renewDto = this.getRenewDto();
    if (!renewDto) {
      // TODO: throw exception
      return NEVER;
    }
    return this.authService.renewToken(renewDto).pipe(
      // TODO: throw exception if null
      map(res => res.data as TokenRenewalResultDtoOfRuiterUserDto),
      tap(token => this.saveToken(token)),
      mapTo(true),
      catchError(() => this.logout().pipe(mapTo(false)))
    );
  }

  logout(forceRefresh: boolean = false): Observable<boolean> {
    const logoutDto = this.getLogoutDto();
    if (!logoutDto && !forceRefresh) {
      // TODO: throw exception if null
      return NEVER;
    }
    return this.authService.logout(logoutDto ?? { } as LogoutDto).pipe(
      map(res => res?.success || false),
      catchError(err => of(false)),
      tap(() => this.removeToken()),
      exhaustMap(() => {
        const routerOptions: NavigationExtras = { };
        if (forceRefresh) {
          routerOptions.queryParams = {
            forceRefresh,
          };
        }
        return this.router.navigate(['auth', 'login'], routerOptions);
      }),
      mapTo(true),
    );
  }

  isAuthenticated(): boolean {
    return this.authStorage.hasTokenData();
  }

  getApiToken(): string | null {
    return this.authStorage.getApiToken();
  }

  private saveToken(tokenData: TokenRenewalResultDtoOfRuiterUserDto): void {
    this.authStorage.saveTokenData(tokenData);
  }

  private removeToken() {
    this.authStorage.removeTokenData();
  }

  private getLoginDto(username: string, password: string): LoginDto {
    return {
      username, password
    };
  }

  private getRenewDto(): TokenRenewalDto | null {
    const username = this.authStorage.getUsername();
    const refreshToken = this.authStorage.getRenewToken();
    if (!username || !refreshToken) {
      return null;
    }

    return { username, refreshToken };
  }

  private getLogoutDto(): LogoutDto | null {
    const refreshToken = this.authStorage.getRenewToken();
    if (!refreshToken) {
      return null;
    }
    return { refreshToken };
  }
}
