<mat-card class="mat-elevation-z0" *ngIf="loginForm">
  <form [formGroup]="loginForm" (ngSubmit)="submitForm()" #form="ngForm">
    <mat-card-header>
      <mat-card-title>{{ 'AUTHENTICATION.LOGIN.TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="outline" *ngIf="controls?.username as username">
        <mat-label>{{ 'AUTHENTICATION.PROPERTY.USERNAME.LABEL' | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="username"
          autocomplete="username"
        >
        <mat-icon matSuffix>{{ 'AUTHENTICATION.PROPERTY.USERNAME.ICON' | translate }}</mat-icon>
        <mat-error *ngIf="(username.dirty || username.touched) && username.errors as errors">
          <ng-container *ngIf="errors.required || errors.minLength">
            {{ 'AUTHENTICATION.PROPERTY.USERNAME.ERRORS.EMPTY' | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="controls?.password as password">
        <mat-label>{{ 'AUTHENTICATION.PROPERTY.PASSWORD.LABEL' | translate }}</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          autocomplete="current-password"
        >
        <mat-icon matSuffix (click)="toggleVisibilityOfPassword()">
          <ng-container *ngIf="!showPassword">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD.SUFFIX.HIDDEN' | translate }}
          </ng-container>
          <ng-container *ngIf="showPassword">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD.SUFFIX.SHOWN' | translate }}
          </ng-container>
        </mat-icon>
        <mat-error *ngIf="(password.dirty || password.touched) && password.errors as errors">
          <ng-container *ngIf="errors.required || errors.minLength">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.EMPTY' | translate }}
          </ng-container>
          <ng-container *ngIf="errors.wrongCredentials">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.INVALID_CREDENTIALS' | translate }}
          </ng-container>
          <ng-container *ngIf="errors.disabled">
            {{ 'AUTHENTICATION.PROPERTY.USERNAME.ERRORS.DISABLED' | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <span class="spacer"></span>
      <ant-button
        routerLink="../forgot"
        [hideIcon]="true"
        mode="stroked"
        role="link"
        type="button"
        [labelHideBreakpoints]="labelHideBreakpoints"
        prefix="AUTHENTICATION.LOGIN."
        actionName="PASSWORD_FORGOT"
      ></ant-button>
      <ant-button
        color="primary"
        [disabled]="!loginForm.valid"
        [loading]="submitting"
        [labelHideBreakpoints]="labelHideBreakpoints"
        (click)="submitForm()"
        mode="flat"
        [hideIcon]="true"
        role="button"
        type="submit"
        prefix="AUTHENTICATION.ACTIONS."
        actionName="LOGIN"
      ></ant-button>
    </mat-card-actions>
  </form>
</mat-card>
