import { Component, OnInit } from '@angular/core';
import { ActivateForm } from '@core/authentication/models/activate.form';
import { ActivateService } from '@core/authentication/services/activate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ActivateUserDto } from '@api';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

  private static readonly TOKEN_PARAMETER_NAME = 't';

  form = new ActivateForm();
  showPassword = false;
  showPasswordRepeat = false;

  submitted = false;
  labelHideBreakpoints: string[] = [];

  constructor(private api: ActivateService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const formData: ActivateUserDto | null = this.form.value;
    if (this.form.invalid || this.submitted || !formData) {
      return;
    }
    this.submitted = true;
    formData.activationToken = this.getToken();
    this.api.activateAccount(formData).pipe(
      finalize(() => this.submitted = false)
    ).subscribe(
      () => this.router.navigate(['auth', 'login']),
      (err) => {
        this.form.enable();
        this.submitted = false;
      }
    );
  }

  private getToken(): string | null {
    const snapshot = this.activatedRoute.snapshot;
    const paramMap = snapshot.queryParamMap;
    if (!paramMap.has(ActivateComponent.TOKEN_PARAMETER_NAME)) {
      return null;
    }
    return paramMap.get(ActivateComponent.TOKEN_PARAMETER_NAME);
  }
}
