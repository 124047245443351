import { Component, Inject, InjectionToken, Injector, OnInit, Optional, Type } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SELECT_DIALOG_SERVICE } from '@core/data/injection-tokens';
import { SelectForm } from '@shared/models/forms/select.form';
import { FilterService } from '@core/models/form/filter-service';
import { Observable } from 'rxjs';
import { PaginationFilterSettings } from '@antony/ng-forms/lib/models/abstract/pagination-settings';
import { PagedSelectOptions } from '@antony/ng-forms/lib/models/utils';
import { map } from 'rxjs/operators';

export interface SelectDialogOptions<T> {
  title?: string;
  injectionToken?: InjectionToken<FilterService<T>> | Type<FilterService<T>>;
  defaultValue?: T | null;
}

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent<T> implements OnInit {
  form: SelectForm<T> | null = null;
  private dialogService: FilterService<T> | null = null;

  constructor(@Optional()
              public dialogRef: MatDialogRef<SelectDialogComponent<T>, T>,
              @Optional() @Inject(MAT_DIALOG_DATA)
              public config: SelectDialogOptions<T>,
              private injector: Injector) {
  }

  ngOnInit() {
    this.dialogService = this.injector.get<FilterService<T> | null>(
      !!this.config?.injectionToken ? this.config?.injectionToken : SELECT_DIALOG_SERVICE,
      null
    );
    if (!this.dialogService) {
      return;
    }
    this.form = new SelectForm({
      loadPagedFilteredOptions: settings => this.filterData(settings),
      getTriggerLabel: (val: T | null | undefined) => this.dialogService?.getLabelFromValue(val) || '',
      value: this.config.defaultValue
    });
  }

  submit() {
    if (!this.form) {
      return;
    }
    this.dialogRef.close(this.form.value?.data);
  }

  abort() {
    this.dialogRef.close();
  }

  private filterData(settings: PaginationFilterSettings): Observable<PagedSelectOptions<T>> {
    return this.dialogService!.filter(settings.filterValue).pipe(
      map(res => ({
        data: res ?? [],
        maxCount: res.length
      }))
    );
  }
}
