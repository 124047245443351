import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleApiKeyApiService } from '@core/api';

@Injectable({
  providedIn: 'root',
})
export class GoogleApiKeyService {

  constructor(private readonly api: GoogleApiKeyApiService) { }

  loadApiKey(): Observable<string> {
    return this.api.loadGoogleApiKey();
  }
}
