import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'extendedDate'
})
export class ExtendedDatePipe extends DatePipe implements PipeTransform {
  private customFormats: { [key: string]: string } = {
    LONG: 'APP.DATE.FORMAT.LONG',
    dateWithCW: 'APP.DATE.FORMAT.DATE_WITH_CALENDAR_WEEK'
  };

  constructor(@Inject(LOCALE_ID) locale: string, private translateService: TranslateService) {
    super(locale);
  }

  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
    format = this.translateFormat(format || 'LONG');
    return super.transform(value, format, timezone, locale);
  }

  private translateFormat(format: string) {
    if (this.customFormats[format]) {
      return this.translateService.instant(this.customFormats[format]);
    }
    return format;
  }
}
