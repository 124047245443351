import { AntFilterLoadingParams, AntLoadingParams, AntSortLoadingParams } from '@antony/ng-ui';
import { ApiSortDirection, BaseDto } from '@api/api.service';
import { AntCollectionSortLoadingParams } from '@api/models';

export class LoadingParamsUtils {

  static getTakeFrom<T>(params: Partial<AntLoadingParams<T>>): number {
    return params.pageSize || 20;
  }

  static getSkipFrom<T>(params: Partial<AntLoadingParams<T>>): number {
    const pageIndex = params.pageIndex || 0;
    return pageIndex * this.getTakeFrom(params);
  }

  static getFilterNamesFrom<T>(params: Partial<AntFilterLoadingParams<T>>): string[] {
    return LoadingParamsUtils.transformStringToArray(params.filterName);
  }

  static getFilterValueFrom<T>(params: Partial<AntFilterLoadingParams<T>>): string {
    return params.filterValue || '';
  }

  static getSortNamesFrom<T>(params: Partial<AntSortLoadingParams<T>>): string[] {
    return LoadingParamsUtils.transformStringToArray(params.sortName);
  }

  static getApiSortDirection<T>(params: Partial<AntSortLoadingParams<T>>): ApiSortDirection {
    return params?.sortDir === 'desc' ? ApiSortDirection.Descending : ApiSortDirection.Ascending;
  }

  static getIdFrom<T extends BaseDto>(params: Partial<AntCollectionSortLoadingParams<T>>): number {
    return params.id || 0;
  }

  static transformToArray<T>(params: Partial<AntSortLoadingParams<T>>): [number, number, string[], string, string[], ApiSortDirection] {
    return [
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ];
  }

  private static transformStringToArray(target: string | null | undefined): string[] {
    if (!target) {
      return [];
    }
    const split = target.split(',');
    return split.map(name => name.trim());
  }
}
