import { Injectable } from '@angular/core';
import { CreditNoteVersionDto, CreditNoteVersionsApiService } from '@api/api.service';
import { ReceiptVersionService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteVersionService extends ReceiptVersionService<CreditNoteVersionDto> {
  constructor(protected api: CreditNoteVersionsApiService) {
    super(api);
  }
}
