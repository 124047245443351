import { ExtendedFormGroup, ExtendedFormGroupOptions, HiddenField } from '@antony/ng-forms';
import { ResetPasswordDto } from '@api';
import { DEFAULT_ERROR_MESSAGES } from '@app/default-error-messages';
import { UserValidator } from '@app/user/models/userValidator';
import { PASSWORD_REPEAT_FIELDS } from '@core/authentication/models/password-repeat.fields';

export interface PasswordResetFormOptions extends ExtendedFormGroupOptions<ResetPasswordDto> {
}

export class PasswordResetForm extends ExtendedFormGroup<ResetPasswordDto> {
  constructor(options: PasswordResetFormOptions = { }) {
    super({
      ...options,
      controls: {
        token: new HiddenField<string>(),
        ...PASSWORD_REPEAT_FIELDS,
        ...options.controls
      },
      validators: [
        UserValidator.requirePasswordRepeated()
      ],
      errorMessages: {
        ...DEFAULT_ERROR_MESSAGES,
        ...options.errorMessages
      }
    });
  }
}
