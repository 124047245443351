import { CrudBase } from '@app/mock/models';
import { ApiResponseOfIEnumerableOfCreditNoteVersionDto, CreditNoteVersionDto, ICreditNoteVersionsApiService } from '@api';
import { Observable } from 'rxjs';
import { CREDIT_NOTE_VERSIONS } from '@app/mock/data/credit_note_versions';
import { Injectable } from '@angular/core';

@Injectable()
export class CreditNoteVersionsApiMockService extends CrudBase<CreditNoteVersionDto> implements ICreditNoteVersionsApiService {
  constructor() {
    super(CREDIT_NOTE_VERSIONS);
  }

  getPagedReceiptVersions(creditNoteId: number, skip?: number, take?: number): Observable<ApiResponseOfIEnumerableOfCreditNoteVersionDto> {
    return this.getRange(skip, take);
  }
}
