import { Material, VehicleTypeDto } from '@api';
import { VEHICLE_TYPE_PRICES } from '@app/mock/data/vehicle-type-prices';

const baseVehicleType: VehicleTypeDto = {
  id: 0,
  created: null,
  lastChange: null,
  name: 'BASE',
  loadCapacity: 0,
  turnoverSpeed: 0,
  speed: 0,
  material: Material.Steel,
  price: VEHICLE_TYPE_PRICES[0]
};

export const VEHICLE_TYPES: VehicleTypeDto[] = [
  {
    ...baseVehicleType,
    id: 1,
    speed: 70.5,
    turnoverSpeed: 12.5,
    loadCapacity: 40,
    name: 'T1200'
  }
];
