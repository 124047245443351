import { Injectable } from '@angular/core';
import { GetDataApiService } from '@api/models';
import { OfferDto, OfferKindsApiService, OfferPositionDto, OfferVersionDto } from '@api/api.service';
import { ReceiptKindService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class OfferKindService extends ReceiptKindService<OfferDto, OfferVersionDto, OfferPositionDto>
  implements GetDataApiService<string> {

  constructor(protected api: OfferKindsApiService) {
    super(api);
  }

}
