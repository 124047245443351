import { Injectable } from '@angular/core';
import {
  ApiResponseOfDownloadReportDto,
  ApiResponseOfIListOfDeliveryNoteDto,
  ApiResponseOfListOfDeliveryNoteVersionDto, ApiSeekPaginationResponseOfDeliveryNoteDto,
  ApiSortDirection,
  DefaultPositionCalculationDto,
  DeliveryNoteDto,
  DeliveryNotePositionDto,
  DeliveryNoteVersionDto,
  FileResponse,
  IDeliveryNoteApiService,
  ReceiptStatusUpdateDto,
  ReportMode,
} from '@api';
import { DELIVERY_NOTES } from '@app/mock/data/delivery_notes';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { NEVER, Observable } from 'rxjs';

@Injectable()
export class DeliveryNotesApiMockService extends CrudBase<DeliveryNoteDto> implements IDeliveryNoteApiService {
  constructor() {
    super(DELIVERY_NOTES);
  }

  getPagedReceipts(skip?: number, take?: number, filterName?: string[], filterValue?: string,
    sortName?: string[], sortDirection?: ApiSortDirection): Observable<GenericApiResponse<DeliveryNoteDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getReceipt(receiptId: number): Observable<GenericApiResponse<DeliveryNoteDto>> {
    return this.get(receiptId);
  }

  updateReceipt(receiptId: number,
    dto: DeliveryNoteVersionDto): Observable<GenericApiResponse<DeliveryNoteVersionDto>> {
    return NEVER;
  }

  calculatePositionDefaults(receiptId: number,
    dto: DefaultPositionCalculationDto): Observable<GenericApiResponse<DeliveryNotePositionDto>> {
    return NEVER;
  }

  deleteDeliveryNote(receiptId: number): Observable<GenericApiResponse<DeliveryNoteDto>> {
    return this.delete(receiptId);
  }

  requestReportDownload(deliveryNoteId: number, documentMode?: ReportMode, attachAgb?: boolean,
    attachImportantNotes?: boolean): Observable<ApiResponseOfDownloadReportDto> {
    return NEVER;
  }

  downloadReport(token?: string | null): Observable<FileResponse> {
    return NEVER;
  }

  updateStatus(updateDto: ReceiptStatusUpdateDto): Observable<ApiResponseOfListOfDeliveryNoteVersionDto> {
    return NEVER;
  }

  getBacklog(customerIds?: number[] | null, constructionProjectId?: number | null,
    kind?: string | null, statusId?: number | null,
    articleId?: number | null,
    deliveryDateFrom?: string | null, deliveryDateTo?: string | null,
    zipCodeFrom?: string | null, zipCodeTo?: string | null,
    articleCountFrom?: number | null, articleCountTo?: number | null,
    fromItemId?: number | null | undefined, take?: number | undefined
  ): Observable<ApiSeekPaginationResponseOfDeliveryNoteDto> {
    return NEVER;
  }


  loadUnprocessed(constructionProjectId?: number,
    ignoredInvoiceId?: number | null): Observable<ApiResponseOfIListOfDeliveryNoteDto> {
    return NEVER;
  }
}
