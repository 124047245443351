import { Injectable } from '@angular/core';
import { AntDataResponse, AntSortLoadingParams, ResourceApiService, TableApiService } from '@antony/ng-ui';
import { LoadingParamsUtils, RapDto, RapsApiService } from '@core/api';
import { from, Observable } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RapService
  implements ResourceApiService<RapDto>, TableApiService<RapDto> {

  constructor(private api: RapsApiService) {
  }

  get(id: number): Observable<RapDto> {
    return this.api.getRap(id).pipe(
      map(res => res.data as RapDto),
    );
  }

  getData(params: Partial<AntSortLoadingParams<RapDto>>): Observable<AntDataResponse<RapDto>> {
    return this.api.getPagedRaps(
      ...LoadingParamsUtils.transformToArray(params)
    ).pipe(
      map(res => ({
        data: res.data ?? [],
        maxCount: res.maxCount ?? 0
      }))
    );
  }

  getOpenRaps(params: Partial<AntSortLoadingParams<RapDto>>, openOnly: boolean): Observable<AntDataResponse<RapDto>> {
    return this.api.getOpenPagesRaps(openOnly, ...LoadingParamsUtils.transformToArray(params)).pipe(
      map(response => ({
        data: response.data ?? [],
        maxCount: response.maxCount ?? 0,
      }))
    );
  }

  create(rap: RapDto): Observable<RapDto> {
    return this.api.createRap(rap).pipe(
      map(x => x.data as RapDto)
    );
  }

  update(id: number, rap: RapDto): Observable<RapDto> {
    return this.api.updateRap(id, rap).pipe(
      map(x => x.data as RapDto)
    );
  }

  delete(id: number): Observable<boolean> {
    return this.api.deleteRap(id).pipe(
      map(x => x.success)
    );
  }

  deleteBulk(ids: number[]): Observable<boolean[]> {
    return this.api.deleteBulkRaps(ids).pipe(
      map(res => res.data || []),
      switchMap(responses => from(responses)),
      map(res => res.success),
      toArray()
    );
  }
}
