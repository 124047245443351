import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

/**
 * This class is used to translate the labels of the pagination
 * of tables.
 */
@Injectable({
  providedIn: 'root'
})
export class MatPaginatorIntlService extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
  }

  itemsPerPageLabel: string =
    this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
  nextPageLabel: string =
    this.translate.instant('PAGINATOR.NEXT_PAGE');
  previousPageLabel: string =
    this.translate.instant('PAGINATOR.PREV_PAGE');
  firstPageLabel: string =
    this.translate.instant('PAGINATOR.FIRST_PAGE');
  lastPageLabel: string =
    this.translate.instant('PAGINATOR.LAST_PAGE');

  getRangeLabel = (pageIndex: number, pageSize: number, length: number) => {
    let lastItem = (pageIndex + 1) * pageSize;
    const firstItem = (pageIndex * pageSize) + 1;

    if (lastItem > length) {
      lastItem = length;
    }
    return this.translate.instant('PAGINATOR.RANGE', { firstItem, lastItem, itemCount: length});
  };
}
