import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';

/**
 * This guard ensures that the user is logged in,
 * if the user is not logged in, redirecting the user to the login page
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private router: Router,
              private authService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.isAuthenticated();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.isAuthenticated();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree {
    return this.isAuthenticated();
  }

  private isAuthenticated(): boolean | UrlTree {
    const authenticated = this.authService.isAuthenticated();
    if (!authenticated) {
      this.authService.logout().subscribe();
      return this.router.createUrlTree(['auth', 'login']);
    }
    return authenticated;
  }
}
