import { ExtendedFormGroup, ExtendedFormGroupOptions, TextField } from '@antony/ng-forms';
import { ForgotPasswordDto } from '@api';
import { Validators } from '@angular/forms';
import { AppConstants } from '@app/app-constants';
import { UserConstants } from '@app/user/models/user-constants';

export interface PasswordForgotFormOptions extends ExtendedFormGroupOptions<ForgotPasswordDto> {
}

export class PasswordForgotForm extends ExtendedFormGroup<ForgotPasswordDto> {
  constructor(options: PasswordForgotFormOptions = { }) {
    super({
      ...options,
      controls: {
        mailAddress: new TextField({
          required: true,
          validators: [Validators.email],
          maxLength: UserConstants.usernameMaxLength
        }),
        ...options.controls
      }
    });
  }
}
