import { Component, Inject, InjectionToken, Injector, OnDestroy, OnInit, Type } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExtendedFormGroup } from '@antony/ng-forms';
import { FormProvider } from '@core/models/form/form-provider';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, take, takeUntil, tap } from 'rxjs/operators';
import { ApiException } from '@api';
import { FORM_DIALOG_SERVICE } from '@core/data/injection-tokens';
import { FormError } from '@core/models/form/form-error';
import { HasPermissionService } from '@shared/permissions/services/has-permission.service';
import { ValidationErrors } from '@angular/forms';
import { CommonDialogsService } from '@shared/services/common-dialogs.service';
import { DEFAULT_ERROR_MESSAGES } from '@app/default-error-messages';
import { ThemePalette } from '@angular/material/core';

export interface FormDialogService<TModel = any, TFormModel = TModel> extends FormProvider<TModel, TFormModel> {
  submit(form: ExtendedFormGroup<TFormModel>): Observable<TModel | TFormModel | null>;
  onClickExtraButton?(form: ExtendedFormGroup<TFormModel>): Observable<any>;
}

export interface ExtraButtonOptions {
  color?: ThemePalette;
  submitLabel?: string;
  submitPrefix?: string;
}

export interface FormDialogConfig<T = any, C = T> {
  title: string;
  injectionToken?: Type<FormDialogService<T, C>> | InjectionToken<FormDialogService<T, C>>;
  formData?: C | null;
  submitPrefix?: string;
  permission?: string | null;
  submitLabel?: string;

  extraButton?: ExtraButtonOptions | null;
}

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent<T = any, C = T> implements OnInit, OnDestroy {

  form: ExtendedFormGroup<C> | null = null;
  submitting = false;
  permission: string;

  config: Required<FormDialogConfig<T, C>>;

  private dialogService: FormDialogService<T, C>;
  private readonly destroyed$ = new Subject();

  constructor(public dialogRef: MatDialogRef<FormDialogComponent<C>, T>,
              private permissions: HasPermissionService,
              private commonDialogs: CommonDialogsService,
              private injector: Injector,
              @Inject(MAT_DIALOG_DATA) config: FormDialogConfig<T, C>) {
    this.dialogRef.disableClose = true;
    this.config = {
      injectionToken: FORM_DIALOG_SERVICE,
      submitLabel: 'SAVE',
      submitPrefix: 'APP.ACTIONS.',
      formData: null,
      permission: null,
      extraButton: null,
      ...config
    };
    this.permission = this.config.permission || '';
    this.dialogService = this.injector.get<FormDialogService<T, C>>(this.config.injectionToken);
    this.dialogService.getForm(this.config.formData || undefined).pipe(
      takeUntil(this.destroyed$),
      tap(form => this.updateFormErrorMessages(form))
    ).subscribe(form => this.form = form);
  }

  ngOnInit() {
    this.dialogRef.backdropClick().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(() => this.abort());
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  submit() {
    if (!this.form || !this.canSubmit()) {
      return;
    }
    this.submitting = true;
    this.dialogService.submit(this.form).pipe(
      take(1),
      catchError((err: any) => {
        if (!this.form) {
          throw err;
        }
        if (err instanceof ApiException && err.status === 400) {
          this.form.setErrors({ serverValidation: true });
        } else if (err.status === 500) {
          this.form.setErrors({ serverError: true });
        } else if (err instanceof FormError) {
          this.form.setErrors({
            [err.key]: err.value
          });
        } else {
          Object.keys(err).forEach((key) => {
            if (!this.form) {
              return;
            }
            const ctrl = this.form.get(key);
            if (ctrl) {
              ctrl.setErrors(err[key]);
            }
          });
        }

        throw err;
      }),
      finalize(() => this.submitting = false)
    ).subscribe((item) => {
      this.dialogRef.close(item as T);
    });
  }

  abort(): void {
    this.askForCloseIfHasChanges().pipe(
      filter(shouldClose => shouldClose)
    ).subscribe(() => {
      this.dialogRef.close();
    });
  }

  canSubmit(): boolean {
    if (!this.form) {
      return false;
    }
    return this.form?.valid && !this.form?.pending;
  }

  getError(errors?: ValidationErrors | null): { key: string, value: any } | null {
    if (!errors) {
      return null;
    }
    const keys = Object.keys(errors);
    if (!keys.length) {
      return null;
    }

    return {
      key: keys[0],
      value: errors[keys[0]]
    };
  }

  askForCloseIfHasChanges(): Observable<boolean> {
    if (!this.hasChanges()) {
      return of(true);
    }
    return this.askForClose();
  }

  askForClose(): Observable<boolean> {
    return this.commonDialogs
               .confirmUnsavedChanges()
               .afterClosed().pipe(
        map(shouldClose => !!shouldClose)
      )
      ;
  }

  onClickExtraButton() {
    if (!this.form || !this.dialogService.onClickExtraButton) {
      return;
    }
    this.dialogService.onClickExtraButton(this.form).pipe(
      take(1)
    ).subscribe();
  }

  private hasChanges(): boolean {
    return !this.form?.pristine;
  }

  private updateFormErrorMessages(form: ExtendedFormGroup<C> | null): void {
    if (!form) {
      return;
    }

    form.errorMessages = {
      ...form.errorMessages,
      ...DEFAULT_ERROR_MESSAGES
    };
  }
}
