import { Injectable } from '@angular/core';
import {
  AntDataResponse,
  AntSortLoadingParams,
  ResourceApiService,
  TableApiService
} from '@antony/ng-ui';
import {
  LoadingParamsUtils,
  WarehouseDto,
  WarehousesApiService
} from '@core/api';
import {
  from,
  Observable
} from 'rxjs';
import {
  map,
  switchMap,
  toArray
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService
  implements ResourceApiService<WarehouseDto>, TableApiService<WarehouseDto> {

  constructor(private api: WarehousesApiService) { }

  get(id: number): Observable<WarehouseDto> {
    return this.api.getWarehouseById(id).pipe(
      map(res => res.data as WarehouseDto),
    );
  }

  getData(params: AntSortLoadingParams<WarehouseDto>): Observable<AntDataResponse<WarehouseDto>> {
    return this.api.getPaged(
      ...LoadingParamsUtils.transformToArray(params)
    ).pipe(
      map(res => ({
        data: res.data ?? [],
        maxCount: res.maxCount ?? 0
      }))
    );
  }

  create(warehouse: WarehouseDto): Observable<WarehouseDto> {
    return this.api.createWarehouse(warehouse).pipe(
      map(x => x.data as WarehouseDto)
    );
  }

  update(id: number, warehouse: WarehouseDto): Observable<WarehouseDto> {
    return this.api.updateWarehouse(id, warehouse).pipe(
      map(x => x.data as WarehouseDto)
    );
  }

  delete(id: number): Observable<boolean> {
    return this.api.deleteWarehouse(id).pipe(
      map(x => x.success)
    );
  }

  deleteBulk(ids: number[]): Observable<boolean[]> {
    return this.api.deleteBulkWarehouses(ids).pipe(
      map(res => res.data || []),
      switchMap(responses => from(responses)),
      map(res => res.success),
      toArray()
    );
  }
}
