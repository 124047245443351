import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { EMPTY, Observable } from 'rxjs';
import { RuiterUserDto } from '../../api/api.service';
import { CurrentUserService } from '@core/authentication/services/current-user.service';
import { ViewAction } from '@antony/ng-ui';
import { PROFILE_VIEW_ACTIONS } from '@core/navigation/view-actions/injection-token';
import { environment } from '@env';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user$: Observable<RuiterUserDto | null> = EMPTY;
  readonly version = environment.version;

  @Output()
  closed = new EventEmitter();

  constructor(private authService: AuthenticationService,
              private currentUserService: CurrentUserService,
              @Inject(PROFILE_VIEW_ACTIONS)
              public viewActions: ViewAction[]) {
  }

  ngOnInit() {
    this.user$ = this.currentUserService.getCurrentUser();
  }

  logout() {
    this.closed.emit();
    this.authService.logout().subscribe();
  }

  onViewActionClick(viewAction: ViewAction): void {
    this.closed.emit();
    viewAction.onClick();
  }
}
