import { Injectable } from '@angular/core';
import { RuiterUserDto } from '../api/api.service';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor() {
  }

  monitorUser(user: RuiterUserDto | null): void {
    Sentry.configureScope(scope => {
      const sentryUser = this.mapRuiterUserToSentryUser(user);
      scope.setUser(sentryUser);
    });
  }

  private mapRuiterUserToSentryUser(user: RuiterUserDto | null): Sentry.User | null {
    if (!user) {
      return null;
    }

    return {
      id: user.id?.toString() || undefined,
      username: user.username || undefined
    };
  }
}
