import { LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ArticleDto } from '@api';
import { formatNumber } from '@angular/common';
import { APP_INJECTOR } from '@core/data/app-injector';

@Pipe({
  name: 'articleName'
})
export class ArticleNamePipe implements PipeTransform {
  locale: string = APP_INJECTOR.get(LOCALE_ID);

  transform(value: ArticleDto | null | undefined, args?: any): string {
    if (!value) {
      return '';
    }
    return `${value.name} ${this.displayNumeric(this.centimeterToMeter(value.length))} m x ${this.displayNumeric(this.centimeterToMeter(value.width))} m`;
  }

  private centimeterToMeter(val: number): number {
    return val / 100;
  }

  private displayNumeric(val: number): string {
    return `${formatNumber(val, this.locale, '1.1-2')}`;
  }
}
