import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { merge, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { catchStatusCode } from '@core/services/catch-operators';

/**
 * This interceptor listens to internal server errors
 * and to connection errors, e.g. if the user has no internet connection.
 * If one of these errors appears, it shows a snackbar, to notify the user.
 * This snackbar holds also an option to retry the request.
 */
@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(private snackbar: MatSnackBar,
              private translate: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchStatusCode(0, (err, caught) => {

        const ref = this.snackbar.open(
          this.translate.instant('APP.ERRORS.NO_CONNECTION'),
          this.translate.instant('APP.ACTIONS.RETRY.LABEL'),
        );
        return merge(
          ref.afterDismissed().pipe(
            map(() => {
              throw err;
            })
          ),
          ref.onAction().pipe(
            switchMap(() => {
              return caught;
            })
          )
        );
      }),
      catchStatusCode(500, (err, caught) => {
        const ref = this.snackbar.open(
          this.translate.instant('APP.ERRORS.INTERNAL_SERVER'),
          this.translate.instant('APP.ACTIONS.RETRY.LABEL'),
        );
        return merge(
          ref.afterDismissed().pipe(
            map(() => {
              throw err;
            })
          ),
          ref.onAction().pipe(
            switchMap(() => {
              return caught;
            })
          )
        );
      })
    );
  }
}
