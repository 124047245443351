<nav class="breadcrumbs" role="navigation">
  <div class="breadcrumb">
    <mat-icon routerLink="/">home</mat-icon>
  </div>
  <ng-container *ngFor="let bc of breadcrumbs | slice:0:maxBreadcrumbsStart; let i = index">
    <ng-container [ngTemplateOutlet]="breadcrumbTemplate"
                  [ngTemplateOutletContext]="{ $implicit:
                        { label: bc.label, index: i }
                      }"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="breadcrumbs.length < maxBreadcrumbCount">
    <ng-container *ngFor="let bc of breadcrumbs | slice:maxBreadcrumbsStart; let i = index">
      <ng-container [ngTemplateOutlet]="breadcrumbTemplate"
                    [ngTemplateOutletContext]="{ $implicit:
                        { label: bc.label, index: (maxBreadcrumbsStart + i) }
                      }"
      ></ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="breadcrumbs.length >= maxBreadcrumbCount">
    <div class="breadcrumb" *ngIf="breadcrumbs.length > maxBreadcrumbCount">
      <mat-icon>chevron_right</mat-icon>
      <span> ... </span>
    </div>
    <ng-container *ngFor="let bc of breadcrumbs | slice:-maxBreadcrumbsEnd; let i = index">
        <ng-container [ngTemplateOutlet]="breadcrumbTemplate"
                      [ngTemplateOutletContext]="{ $implicit:
                        { label: bc.label, index: ((breadcrumbs.length - maxBreadcrumbsEnd) + i) }
                      }"
        ></ng-container>
    </ng-container>
  </ng-container>
</nav>

<ng-template #breadcrumbTemplate let-config>
  <div class="breadcrumb">
    <mat-icon>chevron_right</mat-icon>
    <span [routerLink]="getLinkByIndex(config?.index)">{{ config?.label | translate }}</span>
  </div>
</ng-template>
