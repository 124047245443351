import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SelectDialogComponent, SelectDialogOptions } from '@shared/components/select-dialog/select-dialog.component';
import { MarkdownDialogComponent, MarkdownDialogConfig } from '../components/markdown-dialog/markdown-dialog.component';
import { SimpleDialogComponent, SimpleDialogConfig } from '../components/simple-dialog/simple-dialog.component';

/**
 * This class provides functionality to use common dialogs easily
 * every function opens a single dialog, and defines by passing a typed
 * return value, which dialog is opened and what type of value is returned,
 * when the dialogs closes
 */
@Injectable({
  providedIn: 'root'
})
export class CommonDialogsService {

  constructor(private matDialog: MatDialog) {
  }

  /**
   * This dialog asks the user, if he or she is sure to delete.
   * The message is generic, so this dialog can be used in many contexts.
   * If the user wants to proceed, this dialog returns true, else false.
   */
  confirmDelete(): MatDialogRef<SimpleDialogComponent, boolean> {
    return this.confirm({
      title: 'APP.DIALOG.CONFIRM_DELETE.TITLE',
      message: 'APP.DIALOG.CONFIRM_DELETE.MESSAGE',
      dismissActionName: 'ABORT',
      submitActionName: 'DELETE'
    });
  }

  confirmRemove(): MatDialogRef<SimpleDialogComponent, boolean> {
    return this.confirm({
      title: 'APP.DIALOG.CONFIRM_REMOVE.TITLE',
      message: 'APP.DIALOG.CONFIRM_REMOVE.MESSAGE',
      dismissActionName: 'ABORT',
      submitActionName: 'REMOVE'
    });
  }

  /**
   * This dialog warns the user, that there are unsaved changes, and asks him,
   * if he is sure to leave the current page.
   * The message is generic, so this dialog can be used in many contexts.
   * If the user wants to proceed, this dialog returns true, else false.
   */
  confirmUnsavedChanges(): MatDialogRef<SimpleDialogComponent, boolean> {
    return this.confirm({
      title: 'APP.DIALOG.CONFIRM_UNSAVED_CHANGES.TITLE',
      message: 'APP.DIALOG.CONFIRM_UNSAVED_CHANGES.MESSAGE',
      dismissActionName: 'BACK',
      submitActionName: 'PROCEED_WITHOUT_SAVE'
    });
  }

  confirm(options: SimpleDialogConfig): MatDialogRef<SimpleDialogComponent, boolean> {
    return this.matDialog.open(SimpleDialogComponent, {
      data: {
        ...options
      },
      disableClose: true
    });
  }

  confirmWithMarkdown(options: MarkdownDialogConfig): MatDialogRef<MarkdownDialogComponent, boolean> {
    return this.matDialog.open(MarkdownDialogComponent, {
      data: {
        ...options,
      },
      disableClose: true,
    });
  }

  select<T>(options?: SelectDialogOptions<T>): MatDialogRef<SelectDialogComponent<T>, T> {
    return this.matDialog.open<SelectDialogComponent<T>>(SelectDialogComponent, {
      data: options
    });
  }

  open<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    return this.matDialog.open(componentOrTemplateRef, config);
  }
}
