import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { SentryErrorHandlerService } from '@core/index';
import { httpInterceptorProvider } from './http-interceptors';
import { MockModule } from './mock/mock.module';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { setInjector } from '@core/data/app-injector';
import { ANT_UI_DEFAULT_BUTTON_CONFIG, ButtonConfig } from '@antony/ng-ui';
import { AntNotificationModule } from '@antony/core';
import { DatePipe, LOCATION_INITIALIZED } from '@angular/common';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// https://github.com/ngx-translate/core/issues/517#issuecomment-299637956
export function loadTranslationsAppInitializerFactory(translate: TranslateService, injector: Injector, sentry: SentryErrorHandlerService) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve<unknown>(null));
    const lang = 'de';
    translate.setDefaultLang(lang);
    await translate.use(lang).toPromise().then(
      () => console.debug(`Successfully loaded language ${lang}`),
      (error) => sentry.handleError(
        new Error(`Failed to load language ${lang}`),
        (scope) => scope
          .setExtras({lang, error})
          .setTransactionName('loadTranslationsAppInitializerFactory'),
      ),
    );
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    CoreModule,
    MockModule,
    AppRoutingModule,

    AntNotificationModule.forRoot({
      lowPriorityConfig: {
        duration: 3500
      }
    })
  ],
  providers: [
    DatePipe,
    ...httpInterceptorProvider,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: AppModule.defaultDialogConfig
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: AppModule.defaultSnackbarConfig
    },
    {
      provide: ANT_UI_DEFAULT_BUTTON_CONFIG,
      useValue: AppModule.defaultButtonConfig
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadTranslationsAppInitializerFactory,
      deps: [TranslateService, Injector, SentryErrorHandlerService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private static defaultDialogConfig: MatDialogConfig = {
    role: 'dialog',
    restoreFocus: true,
    hasBackdrop: true,
    closeOnNavigation: true,
    maxWidth: '72rem',
    minWidth: '40vw',
    maxHeight: '100%'
  };
  private static defaultSnackbarConfig: MatSnackBarConfig = {
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
    duration: 3000
  };

  private static defaultButtonConfig: Partial<ButtonConfig> = {
    prefix: 'APP.ACTIONS.',
    labelSuffix: '.LABEL',
    iconSuffix: '.ICON'
  };

  constructor(private injector: Injector) {
    setInjector(injector);
  }
}
