import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, map, toArray } from 'rxjs/operators';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { GetDataApiService } from '@api/models';
import { InviteUserDto, RuiterUserDto, UsersApiService } from '@api/api.service';
import { LoadingParamsUtils } from '@api/utils';

@Injectable({
  providedIn: 'root'
})
export class UserService implements GetDataApiService<RuiterUserDto> {

  constructor(private userApi: UsersApiService) {
  }

  getData(params: Partial<AntSortLoadingParams<RuiterUserDto>>): Observable<AntDataResponse<RuiterUserDto>> {
    return this.userApi.getPagedUsers(
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params),
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

  get(id: number): Observable<RuiterUserDto> {
    return this.userApi.getUser(id).pipe(
      map(res => res.data as RuiterUserDto)
    );
  }

  invite(dto: InviteUserDto): Observable<RuiterUserDto> {
    return this.userApi.inviteUser(dto).pipe(
      map(res => res.data as RuiterUserDto)
    );
  }

  update(id: number, user: RuiterUserDto): Observable<RuiterUserDto> {
    return this.userApi.updateUser(id, user).pipe(
      map(res => res.data as RuiterUserDto)
    );
  }

  delete(id: number): Observable<boolean> {
    return this.userApi.deleteUser(id).pipe(
      map(res => res.success)
    );
  }

  deleteBulk(ids: number[]): Observable<boolean[]> {
    return from(ids).pipe(
      concatMap(id => this.delete(id)),
      toArray()
    );
  }
}
