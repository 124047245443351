import { Injectable } from '@angular/core';
import { ChangePasswordDto } from '@api';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormDialogComponent, FormDialogConfig } from '@shared/components/form-dialog/form-dialog.component';
import { ChangePasswordFormDialogAdapter } from '@core/authentication/services/change-password.form-dialog-adapter';
import { NotificationService } from '@antony/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private matDialog: MatDialog,
              private translate: TranslateService,
              private notificationService: NotificationService) {
  }

  requestPasswordChange(forceChange: boolean = false): void {
    const dialogRef = this.createDialog(forceChange);
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.notifyNoop();
        return;
      }
      this.notifySuccess();
    });
  }

  private createDialog(forceChange: boolean): MatDialogRef<FormDialogComponent, ChangePasswordDto> {
    return this.matDialog.open<FormDialogComponent, FormDialogConfig, ChangePasswordDto>(FormDialogComponent, {
      data: {
        title: 'AUTHENTICATION.CHANGE_PASSWORD.TITLE',
        submitPrefix: 'AUTHENTICATION.ACTIONS.',
        submitLabel: 'CHANGE_PASSWORD',
        injectionToken: ChangePasswordFormDialogAdapter
      },
      disableClose: forceChange
    });
  }

  private notifySuccess(): void {
    const message = 'AUTHENTICATION.CHANGE_PASSWORD.NOTIFICATIONS.SUCCESS.MESSAGE';
    this.notificationService.openLowPriority({
      message: this.translate.instant(message)
    });
  }

  private notifyNoop(): void {
    const message = 'AUTHENTICATION.CHANGE_PASSWORD.NOTIFICATIONS.NOOP.MESSAGE';
    this.notificationService.openLowPriority({
      message: this.translate.instant(message)
    });
  }
}
