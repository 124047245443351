import { ConstructionProjectOrdersApiService, OrderDto } from '@api/api.service';
import { AntCollectionSortLoadingParams, GetCollectionApiService } from '@api/models';
import { LoadingParamsUtils } from '@api/utils';
import { Injectable } from '@angular/core';
import { AntDataResponse } from '@antony/ng-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConstructionProjectOrderService implements GetCollectionApiService<OrderDto> {

  constructor(protected api: ConstructionProjectOrdersApiService) {
  }

  getData(params: AntCollectionSortLoadingParams<OrderDto>): Observable<AntDataResponse<OrderDto>> {
    return this.api.getAllOrders(
      LoadingParamsUtils.getIdFrom(params),
      ...LoadingParamsUtils.transformToArray(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }
}
