import { ApiResponseOfListOfOrderDto, ApiSortDirection, IConstructionProjectOrdersApiService, OrderDto } from '@api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudBase } from '@app/mock/models';
import { ORDERS } from '@app/mock/data/orders';

@Injectable()
export class ConstructionProjectOrderApiMockService extends CrudBase<OrderDto> implements IConstructionProjectOrdersApiService {

  constructor() {
    super(ORDERS);
  }

  getAllOrders(constructionProjectId: number, skip?: number, take?: number, filterName?: string[] | null, filterValue?: string | null,
               sortName?: string[] | null, sortDirection?: ApiSortDirection): Observable<ApiResponseOfListOfOrderDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

}
