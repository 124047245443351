import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AntBreadcrumbsModule, AntEnvironmentIndicatorModule } from '@antony/core';
import { SharedModule } from '@shared/shared.module';
import { PermissionsModule } from '@shared/permissions/permissions.module';

import { MenuComponent } from './menu/menu.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProfileComponent } from './profile/profile.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { PROFILE_VIEW_ACTIONS, LogoutViewAction, ChangePasswordViewAction } from './view-actions';
import { TitleService, BreadcrumbService, NavigationService } from './services';

@NgModule({
  declarations: [
    NavigationComponent,
    MenuComponent,
    ProfileComponent,
    BreadcrumbsComponent
  ],
  providers: [
    BreadcrumbService,
    TitleService,
    NavigationService,
    { provide: PROFILE_VIEW_ACTIONS, useClass: ChangePasswordViewAction, multi: true},
    { provide: PROFILE_VIEW_ACTIONS, useClass: LogoutViewAction, multi: true}
  ],
  imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        PermissionsModule,
        AntBreadcrumbsModule.forRoot({
            useNavigationListener: true
        }),
        AntEnvironmentIndicatorModule,
    ],
  exports: [
    NavigationComponent
  ]
})
export class NavigationModule {
}
