import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SharedModule } from '@shared/shared.module';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './services/authentication.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CurrentUserService } from '@core/authentication/services/current-user.service';
import { AuthenticationStorageService } from '@core/authentication/services/authentication-storage.service';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AuthenticationFooterComponent } from './authentication-footer/authentication-footer.component';
import { PasswordForgotService } from '@core/authentication/services/password-forgot.service';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordResetService } from '@core/authentication/services/password-reset.service';
import { ActivateComponent } from './activate/activate.component';
import { ActivateService } from '@core/authentication/services/activate.service';

@NgModule({
  declarations: [
    LoginComponent,
    ActivateComponent,
    PasswordForgotComponent,
    AuthenticationComponent,
    AuthenticationFooterComponent,
    PasswordResetComponent
  ],
  providers: [
    AuthenticationService,
    AuthenticationStorageService,
    ActivateService,
    PasswordForgotService,
    PasswordResetService,
    CurrentUserService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline'}
    }
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationRoutingModule,
    ReactiveFormsModule
  ]
})
export class AuthenticationModule {
}
