import { Component, OnInit } from '@angular/core';
import { NoPermissionError } from '@antony/ng-ui';

@Component({
  selector: 'app-no-permissions',
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.scss'],
})
export class NoPermissionsComponent {

  noPermissionsError = new NoPermissionError();
}
