import { CountryDto } from '@api';

export const COUNTRIES: CountryDto[] = [
  {
    id: 1,
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    name: 'Deutschland',
    created: (new Date()).toISOString(),
    lastChange: (new Date()).toISOString(),
  }
];
