import { DeliveryNoteDto } from '@api';
import { CONSTRUCTION_PROJECTS } from '@app/mock/data/construction-projects';
import { DELIVERY_NOTE_VERSIONS } from '@app/mock/data/delivery_note_versions';
import { ORDERS } from '@app/mock/data/orders';

const baseDeliveryNote: DeliveryNoteDto = {
  id: 0,
  transferredFromOrderId: 0,
  transferredFromOrder: null,
  constructionProjectId: 0,
  constructionProject: null,
  isReadOnly: false,
  latestVersion: null,
  number: null,
  createdBy: null,
  createdById: 0,
  created: null,
  lastChange: null,
  invoices: [],
  remoteAccessToken: 'asd',
  assignments: [],
};

export const DELIVERY_NOTES: DeliveryNoteDto[] = [
  {
    ...baseDeliveryNote,
    id: 1,
    number: 'LS-47-11',
    transferredFromOrder: ORDERS[0],
    transferredFromOrderId: ORDERS[0].id ?? 0,
    constructionProject: CONSTRUCTION_PROJECTS[0],
    constructionProjectId: CONSTRUCTION_PROJECTS[0].id ?? 0,
    latestVersion: DELIVERY_NOTE_VERSIONS[0]
  }
];
