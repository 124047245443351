import { PaymentConditionDto } from '@api';

export const PAYMENT_CONDITIONS: PaymentConditionDto[] = [
  {
    id: 1,
    created: '2019-01-11T17:47:50Z',
    lastChange: '2012-09-22T06:33:28Z',
    name: 'do sint officia',
    printText: 'do sint officia',
    discounts: null
  },
  {
    id: 2,
    created: '2005-03-03T13:24:35Z',
    lastChange: '2015-03-28T04:32:32Z',
    name: 'ut anim mollit',
    printText: 'ut anim mollit',
    discounts: null
  },
  {
    id: 3,
    created: '2006-07-30T22:55:40Z',
    lastChange: '2018-09-11T08:04:25Z',
    name: 'culpa non consectetur',
    printText: 'culpa non consectetur',
    discounts: null
  },
  {
    id: 4,
    created: '2001-09-15T14:55:14Z',
    lastChange: '2002-04-12T21:36:47Z',
    name: 'est ipsum exercitation',
    printText: 'est ipsum exercitation',
    discounts: null
  },
  {
    id: 5,
    created: '2004-04-29T22:49:23Z',
    lastChange: '2005-02-15T15:55:49Z',
    name: 'commodo aliquip ex',
    printText: 'commodo aliquip ex',
    discounts: null
  },
  {
    id: 6,
    created: '2015-06-27T04:59:23Z',
    lastChange: '2019-11-01T14:12:39Z',
    name: 'ullamco sunt cillum',
    printText: 'ullamco sunt cillum',
    discounts: null
  },
  {
    id: 7,
    created: '2009-02-02T21:00:01Z',
    lastChange: '2005-07-10T01:43:30Z',
    name: 'voluptate consequat magna',
    printText: 'voluptate consequat magna',
    discounts: null
  },
  {
    id: 8,
    created: '2019-10-27T06:39:11Z',
    lastChange: '2014-04-27T07:34:32Z',
    name: 'officia exercitation tempor',
    printText: 'officia exercitation tempor',
    discounts: null
  },
  {
    id: 9,
    created: '2008-09-23T21:31:32Z',
    lastChange: '2008-01-28T08:44:24Z',
    name: 'excepteur eu culpa',
    printText: 'excepteur eu culpa',
    discounts: null
  }
];
