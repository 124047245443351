<mat-card class="mat-elevation-z0" *ngIf="passwordForgotForm">
  <form [formGroup]="passwordForgotForm" (ngSubmit)="submitForm()">
    <mat-card-header>
      <mat-card-title>{{ 'AUTHENTICATION.PASSWORD_FORGOT.TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field appearance="outline" *ngIf="passwordForgotForm.controls.mailAddress">
        <mat-label>{{ 'AUTHENTICATION.PROPERTY.MAIL_ADDRESS.LABEL' | translate }}</mat-label>
        <input
          matInput
          type="email"
          formControlName="mailAddress"
          required
          email
          autocomplete="username"
        >
        <mat-icon matSuffix>{{ 'AUTHENTICATION.PROPERTY.MAIL_ADDRESS.ICON' | translate }}</mat-icon>
        <mat-error *ngIf="getErrors('mailAddress') as errors">
          <ng-container *ngIf="errors.required || errors.minLength">
            {{ 'AUTHENTICATION.PROPERTY.MAIL_ADDRESS.ERRORS.EMPTY' | translate }}
          </ng-container>
          <ng-container *ngIf="errors.email">
            {{ 'AUTHENTICATION.PROPERTY.MAIL_ADDRESS.ERRORS.EMAIL' | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <span class="spacer"></span>
      <ant-button
        [labelHideBreakpoints]="labelHideBreakpoints"
        routerLink="../login"
        mode="stroked"
        role="link"
        type="button"
        prefix="AUTHENTICATION.PASSWORD_FORGOT."
        actionName="LOGIN"
        [hideIcon]="true"
      ></ant-button>
      <ant-button
        [labelHideBreakpoints]="labelHideBreakpoints"
        color="primary"
        [disabled]="!passwordForgotForm.valid"
        [loading]="hasSubmitted()"
        (click)="submitForm()"
        mode="flat"
        role="button"
        type="submit"
        prefix="AUTHENTICATION.ACTIONS."
        actionName="SUBMIT"
        [hideIcon]="true"
      ></ant-button>
    </mat-card-actions>
  </form>
</mat-card>
