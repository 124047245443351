import { Injectable } from '@angular/core';
import { AntDataResponse } from '@antony/ng-ui';
import { Observable } from 'rxjs';
import { DeliveryNoteAssignmentApiService, DeliveryNoteAssignmentDto } from '@api/api.service';
import { ApiResponseUtils } from '@core/api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteAssignmentService {

  constructor(private readonly api: DeliveryNoteAssignmentApiService) {
  }

  getByVehicleAndDate(vehicleId: number, date: string): Observable<AntDataResponse<DeliveryNoteAssignmentDto>> {
    return this.api.getAllByVehicleAndDate(vehicleId, date).pipe(
      map(ApiResponseUtils.getAntDataResponseFromResponse)
    );
  }

  create(dto: DeliveryNoteAssignmentDto): Observable<DeliveryNoteAssignmentDto> {
    return this.api.createAssignment(dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  update(resourceId: number, dto: DeliveryNoteAssignmentDto): Observable<DeliveryNoteAssignmentDto> {
    return this.api.updateAssignment(resourceId, dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  delete(resourceId: number): Observable<boolean> {
    return this.api.deleteAssignment(resourceId).pipe(
      map(ApiResponseUtils.getSuccessFromResponse)
    );
  }

}
