import { Injectable } from '@angular/core';
import { DeliveryConditionApiService, DeliveryConditionDto } from '@api/api.service';
import { from, Observable } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';
import { ResourceApiService } from '@api/models';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { LoadingParamsUtils } from '@api/utils';

@Injectable({
  providedIn: 'root'
})
export class DeliveryConditionService implements ResourceApiService<DeliveryConditionDto> {

  constructor(private api: DeliveryConditionApiService) {
  }

  getData(params: Partial<AntSortLoadingParams<DeliveryConditionDto>>): Observable<AntDataResponse<DeliveryConditionDto>> {
    return this.api.getPagedDeliveryConditions(
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

  get(id: number): Observable<DeliveryConditionDto> {
    return this.api.getDeliveryCondition(id).pipe(
      map(x => x.data as DeliveryConditionDto)
    );
  }

  create(item: DeliveryConditionDto): Observable<DeliveryConditionDto> {
    return this.api.createDeliveryCondition(item).pipe(
      map(x => x.data as DeliveryConditionDto)
    );
  }

  update(id: number, item: DeliveryConditionDto): Observable<DeliveryConditionDto> {
    return this.api.updateDeliveryCondition(id, item).pipe(
      map(x => x.data as DeliveryConditionDto)
    );
  }

  delete(id: number): Observable<boolean> {
    return this.api.deleteDeliveryCondition(id).pipe(
      map(x => x.success)
    );
  }

  deleteBulk(ids: number[]): Observable<boolean[]> {
    return this.api.deleteBulkDeliveryConditions(ids).pipe(
      map(res => res.data || []),
      switchMap(responses => from(responses)),
      map(res => res.success),
      toArray()
    );
  }
}
