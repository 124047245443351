import { AddressDto } from '@api';
import { COUNTRIES } from '@app/mock/data/countries';

const baseAddress: AddressDto = {
  id: null,
  additionalAddressLine: null,
  street: null,
  houseNumber: null,
  zipCode: null,
  city: null,
  created: null,
  lastChange: null,
  countryId: null,
  country: null
};

export const ADDRESSES: AddressDto[] = [
  {
    ...baseAddress,
    id: 50,
    street: 'Fountain Avenue',
    houseNumber: '100',
    zipCode: '95997',
    city: 'Wright',
    created: '2019-02-28T02:15:41Z',
    lastChange: '2015-06-14T11:59:50Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 1,
    street: 'Bedell Lane',
    houseNumber: '16',
    zipCode: '41694',
    city: 'Breinigsville',
    created: '2014-11-08T08:27:41Z',
    lastChange: '2015-10-26T11:47:26Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 2,
    street: 'High Street',
    houseNumber: '58',
    zipCode: '96229',
    city: 'Orovada',
    created: '2018-05-14T09:36:52Z',
    lastChange: '2015-06-05T03:15:11Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 3,
    street: 'Aster Court',
    houseNumber: '39',
    zipCode: '41300',
    city: 'Roland',
    created: '2014-06-30T09:54:44Z',
    lastChange: '2019-01-04T05:23:21Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 4,
    street: 'Ash Street',
    houseNumber: '77',
    zipCode: '91453',
    city: 'Maybell',
    created: '2014-02-17T06:00:05Z',
    lastChange: '2018-04-22T12:08:16Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 5,
    street: 'Scott Avenue',
    houseNumber: '80',
    zipCode: '92732',
    city: 'Bartonsville',
    created: '2016-11-07T11:52:52Z',
    lastChange: '2015-06-17T12:32:35Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 6,
    street: 'Flatbush Avenue',
    houseNumber: '37',
    zipCode: '61705',
    city: 'Roeville',
    created: '2016-10-08T09:05:29Z',
    lastChange: '2016-11-04T10:14:09Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 7,
    street: 'Miller Avenue',
    houseNumber: '69',
    zipCode: '89696',
    city: 'Talpa',
    created: '2017-12-31T12:44:07Z',
    lastChange: '2014-04-19T01:41:04Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 8,
    street: 'Meserole Avenue',
    houseNumber: '92',
    zipCode: '64695',
    city: 'Springhill',
    created: '2017-07-18T10:16:32Z',
    lastChange: '2018-10-08T08:34:22Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 9,
    street: 'Suydam Street',
    houseNumber: '89',
    zipCode: '60032',
    city: 'Deputy',
    created: '2018-01-01T11:10:39Z',
    lastChange: '2014-07-30T03:32:25Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 10,
    street: 'Paerdegat Avenue',
    houseNumber: '56',
    zipCode: '18686',
    city: 'Boyd',
    created: '2019-09-05T12:17:24Z',
    lastChange: '2014-07-22T03:33:23Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 11,
    street: 'Hamilton Avenue',
    houseNumber: '61',
    zipCode: '86310',
    city: 'Wiscon',
    created: '2017-04-12T05:17:12Z',
    lastChange: '2019-03-15T02:21:35Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 12,
    street: 'Adams Street',
    houseNumber: '92',
    zipCode: '38229',
    city: 'Fannett',
    created: '2015-01-02T11:00:46Z',
    lastChange: '2017-07-04T06:31:21Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 13,
    street: 'Ocean Parkway',
    houseNumber: '17',
    zipCode: '93983',
    city: 'Westmoreland',
    created: '2019-03-05T11:31:12Z',
    lastChange: '2017-11-15T07:47:38Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 14,
    street: 'Schenck Place',
    houseNumber: '89',
    zipCode: '24458',
    city: 'Singer',
    created: '2015-07-05T12:49:12Z',
    lastChange: '2019-10-17T12:18:07Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 15,
    street: 'Polar Street',
    houseNumber: '22',
    zipCode: '42136',
    city: 'Dodge',
    created: '2014-12-26T04:47:39Z',
    lastChange: '2019-04-30T06:25:34Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 16,
    street: 'Osborn Street',
    houseNumber: '78',
    zipCode: '44493',
    city: 'Belvoir',
    created: '2017-02-16T09:05:18Z',
    lastChange: '2015-03-12T11:15:18Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 17,
    street: 'Lancaster Avenue',
    houseNumber: '59',
    zipCode: '94515',
    city: 'Wakarusa',
    created: '2017-04-24T08:49:55Z',
    lastChange: '2016-01-13T02:12:39Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 18,
    street: 'Thatford Avenue',
    houseNumber: '62',
    zipCode: '68310',
    city: 'Heil',
    created: '2014-05-25T05:46:47Z',
    lastChange: '2018-11-28T02:28:20Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 19,
    street: 'Jackson Street',
    houseNumber: '31',
    zipCode: '15086',
    city: 'Bascom',
    created: '2015-06-11T09:09:38Z',
    lastChange: '2014-05-27T10:04:59Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 20,
    street: 'Box Street',
    houseNumber: '23',
    zipCode: '81043',
    city: 'Hayes',
    created: '2015-03-11T11:12:52Z',
    lastChange: '2016-05-06T07:12:49Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 21,
    street: 'Stoddard Place',
    houseNumber: '18',
    zipCode: '47233',
    city: 'Hartsville/Hartley',
    created: '2019-07-07T03:03:45Z',
    lastChange: '2019-11-13T01:19:47Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 22,
    street: 'Tompkins Place',
    houseNumber: '51',
    zipCode: '23488',
    city: 'Homeland',
    created: '2016-05-29T11:31:14Z',
    lastChange: '2016-05-26T05:55:43Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 23,
    street: 'Tiffany Place',
    houseNumber: '38',
    zipCode: '35358',
    city: 'Hiko',
    created: '2019-03-13T11:42:20Z',
    lastChange: '2014-03-27T09:33:29Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 24,
    street: 'Windsor Place',
    houseNumber: '59',
    zipCode: '90806',
    city: 'Cedarville',
    created: '2018-04-23T08:45:02Z',
    lastChange: '2018-08-02T11:29:40Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 25,
    street: 'Sheffield Avenue',
    houseNumber: '96',
    zipCode: '98951',
    city: 'Sanford',
    created: '2015-11-01T12:58:06Z',
    lastChange: '2018-03-09T06:56:50Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 26,
    street: 'Rutledge Street',
    houseNumber: '6',
    zipCode: '15782',
    city: 'Maury',
    created: '2015-12-06T12:46:02Z',
    lastChange: '2019-01-29T02:26:56Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 27,
    street: 'Gotham Avenue',
    houseNumber: '55',
    zipCode: '12499',
    city: 'Darbydale',
    created: '2015-11-22T11:43:25Z',
    lastChange: '2014-09-26T02:26:07Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 28,
    street: 'Carroll Street',
    houseNumber: '1',
    zipCode: '14470',
    city: 'Cressey',
    created: '2014-05-09T03:15:49Z',
    lastChange: '2015-09-14T04:10:23Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 29,
    street: 'Nelson Street',
    houseNumber: '2',
    zipCode: '36928',
    city: 'Fedora',
    created: '2017-08-07T05:10:25Z',
    lastChange: '2016-06-25T01:28:21Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 30,
    street: 'Meserole Street',
    houseNumber: '82',
    zipCode: '95564',
    city: 'Mahtowa',
    created: '2017-12-18T01:08:55Z',
    lastChange: '2019-02-13T09:02:04Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 31,
    street: 'Lake Place',
    houseNumber: '86',
    zipCode: '51251',
    city: 'Harviell',
    created: '2018-07-31T12:07:39Z',
    lastChange: '2018-10-29T09:58:43Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 32,
    street: 'Gerry Street',
    houseNumber: '39',
    zipCode: '49648',
    city: 'Shelby',
    created: '2016-10-22T06:36:42Z',
    lastChange: '2015-05-14T12:57:56Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 33,
    street: 'Woodruff Avenue',
    houseNumber: '5',
    zipCode: '62923',
    city: 'Gardiner',
    created: '2014-12-31T09:58:27Z',
    lastChange: '2017-03-08T03:19:33Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 34,
    street: 'Sedgwick Place',
    houseNumber: '11',
    zipCode: '46029',
    city: 'Westwood',
    created: '2016-03-02T12:26:29Z',
    lastChange: '2019-10-09T01:10:32Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 35,
    street: 'Sullivan Street',
    houseNumber: '22',
    zipCode: '22490',
    city: 'Roderfield',
    created: '2016-11-11T10:54:46Z',
    lastChange: '2015-04-10T01:34:44Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 36,
    street: 'Hubbard Place',
    houseNumber: '55',
    zipCode: '54399',
    city: 'Bonanza',
    created: '2017-09-14T01:55:32Z',
    lastChange: '2014-05-27T08:09:44Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 37,
    street: 'Hopkins Street',
    houseNumber: '97',
    zipCode: '99713',
    city: 'Knowlton',
    created: '2015-11-23T06:04:18Z',
    lastChange: '2016-11-05T10:49:12Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 38,
    street: 'Ross Street',
    houseNumber: '18',
    zipCode: '58625',
    city: 'Crisman',
    created: '2019-03-26T03:56:24Z',
    lastChange: '2019-04-24T09:25:33Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 39,
    street: 'Campus Road',
    houseNumber: '82',
    zipCode: '44733',
    city: 'National',
    created: '2018-06-30T09:00:11Z',
    lastChange: '2014-02-12T02:00:24Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 40,
    street: 'Post Court',
    houseNumber: '4',
    zipCode: '66147',
    city: 'Winfred',
    created: '2019-07-22T10:52:53Z',
    lastChange: '2019-04-08T07:42:28Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 41,
    street: 'Williams Avenue',
    houseNumber: '18',
    zipCode: '80771',
    city: 'Fairhaven',
    created: '2019-11-02T10:28:00Z',
    lastChange: '2015-11-16T06:13:28Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 42,
    street: 'College Place',
    houseNumber: '61',
    zipCode: '96256',
    city: 'Leola',
    created: '2014-12-15T01:28:13Z',
    lastChange: '2019-03-28T11:55:28Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 43,
    street: 'Minna Street',
    houseNumber: '52',
    zipCode: '26424',
    city: 'Newkirk',
    created: '2017-01-25T06:02:24Z',
    lastChange: '2014-03-23T08:39:34Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 44,
    street: 'Montgomery Place',
    houseNumber: '80',
    zipCode: '98895',
    city: 'Orason',
    created: '2016-05-02T09:35:57Z',
    lastChange: '2018-04-19T08:26:56Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 45,
    street: 'Pleasant Place',
    houseNumber: '11',
    zipCode: '64366',
    city: 'Nettie',
    created: '2019-06-12T05:13:41Z',
    lastChange: '2016-12-05T10:32:55Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 46,
    street: 'National Drive',
    houseNumber: '64',
    zipCode: '30661',
    city: 'Hasty',
    created: '2018-01-16T09:11:10Z',
    lastChange: '2016-10-07T12:10:24Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 47,
    street: 'Hanson Place',
    houseNumber: '97',
    zipCode: '21549',
    city: 'Avalon',
    created: '2016-05-28T02:20:42Z',
    lastChange: '2016-02-17T06:21:10Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 48,
    street: 'Burnett Street',
    houseNumber: '21',
    zipCode: '51775',
    city: 'Gasquet',
    created: '2017-08-05T08:29:24Z',
    lastChange: '2018-12-21T08:41:11Z',
    countryId: 1,
    country: COUNTRIES[0]
  },
  {
    ...baseAddress,
    id: 49,
    street: 'Saratoga Avenue',
    houseNumber: '50',
    zipCode: '21096',
    city: 'Nipinnawasee',
    created: '2018-01-18T02:15:48Z',
    lastChange: '2019-05-22T07:56:37Z',
    countryId: 1,
    country: COUNTRIES[0]
  }
];
