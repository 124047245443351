<mat-toolbar color="primary">{{ 'APP.TITLE' | translate }}</mat-toolbar>
<mat-nav-list>
  <ng-container *ngFor="let route of mainMenuItems">
    <ng-container *ngIf="!route.data?.hideInMenu">
      <ng-container *appHasPermission="route.data?.permission">
        <mat-list-item
          [routerLink]="route.path"
          (click)="navigated.emit()"
        >
          <mat-icon matListIcon>{{ (route.data?.icon || null) | translate }}</mat-icon>
          <span>{{ (route.data?.title || null) | translate }}</span>
        </mat-list-item>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-nav-list>
