import { catchError } from 'rxjs/operators';
import { Observable, ObservableInput } from 'rxjs';
import { ApiException } from '../api/api.service';
import { HttpErrorResponse } from '@angular/common/http';

export declare type CatchErrorFn<T, O extends ObservableInput<any>> = (err: ApiException | HttpErrorResponse, caught: Observable<T>) => O;

export function catchStatusCode<T, O extends ObservableInput<any>>(statusCode: number, fn: CatchErrorFn<T, O>) {
  return catchError<T, O>((err: ApiException | HttpErrorResponse, caught: Observable<T>): O => {
    if ((err instanceof HttpErrorResponse || err instanceof ApiException) && err.status === statusCode) {
      return fn(err, caught);
    }

    throw err;
  });
}
