import { Injectable } from '@angular/core';
import { AntDataResponse } from '@antony/ng-ui';
import {
  AntCollectionSortLoadingParams,
  ApiResponseUtils,
  DeliveryNoteDto,
  GetCollectionApiService,
  LoadingParamsUtils,
  OrderTransferApiService,
} from '@api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderDeliveryNoteService implements GetCollectionApiService<DeliveryNoteDto> {

  constructor(protected readonly api: OrderTransferApiService) {
  }

  getData(params: AntCollectionSortLoadingParams<DeliveryNoteDto>): Observable<AntDataResponse<DeliveryNoteDto>> {
    return this.api.getTransferredDeliveryNotes(
      LoadingParamsUtils.getIdFrom(params),
      ...LoadingParamsUtils.transformToArray(params)
    ).pipe(
      map(ApiResponseUtils.getAntDataResponseFromResponse)
    );
  }
}

