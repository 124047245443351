import { Injectable } from '@angular/core';
import {
  ActivateUserDto,
  ApiResponse,
  ApiResponseOfIEnumerableOfPermissionDto,
  ApiResponseOfLoginResultDtoOfRuiterUserDto,
  ApiResponseOfTokenRenewalResultDtoOfRuiterUserDto,
  ChangePasswordDto,
  ForgotPasswordDto,
  IAuthApiService,
  ImpersonateDto,
  LoginDto,
  LogoutDto,
  ResetPasswordDto,
  TokenRenewalDto
} from '@api';
import { NEVER, Observable, of } from 'rxjs';
import { USERS } from '@app/mock/data/users';

@Injectable()
export class AuthApiMockService implements IAuthApiService {
  activateAccount(dto: ActivateUserDto): Observable<ApiResponse> {
    return NEVER;
  }

  forgotPassword(dto: ForgotPasswordDto): Observable<ApiResponse> {
    return NEVER;
  }

  getPermissions(): Observable<ApiResponseOfIEnumerableOfPermissionDto> {
    return of({
      success: true,
      message: null,
      data: [
        {
          key: 'global_admin',
          translations: {
            de: {
              title: 'Globaler Admin',
              description: ''
            }
          }
        }
      ],
      count: 1,
      maxCount: 1
    });
  }

  login(dto: LoginDto): Observable<ApiResponseOfLoginResultDtoOfRuiterUserDto> {
    return of({
      success: true,
      data: {
        tokenData: {
          token: 'ABCDEF',
          validUntil: '3030-12-01T23:59:69Z',
          refreshToken: 'ABCDEF',
          user: USERS[0]
        },
        isUserDisabled: false,
      },
      count: null,
      maxCount: null,
      message: null
    });
  }

  logout(dto: LogoutDto): Observable<ApiResponse> {
    return of({
      success: true,
      message: null,
      count: null,
      maxCount: null
    });
  }

  renewToken(dto: TokenRenewalDto): Observable<ApiResponseOfTokenRenewalResultDtoOfRuiterUserDto> {
    return of({
      success: true,
      data: {
        token: 'ABCDEF',
        validUntil: '3030-12-01T23:59:69Z',
        refreshToken: 'ABCDEF',
        user: USERS[0]
      },
      message: null,
      count: null,
      maxCount: null
    });
  }

  resetPassword(dto: ResetPasswordDto): Observable<ApiResponse> {
    return NEVER;
  }

  changePassword(dto: ChangePasswordDto): Observable<ApiResponse> {
    return NEVER;
  }

  impersonate(dto: ImpersonateDto): Observable<ApiResponseOfLoginResultDtoOfRuiterUserDto> {
    return NEVER;
  }
}
