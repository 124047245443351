import { ApiSortDirection, CustomerDto, ICustomerSubCustomersApiService } from '@api';
import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { NEVER, Observable } from 'rxjs';
import { CUSTOMERS } from '@app/mock/data/customers';

@Injectable()
export class CustomerSubCustomerApiMockService extends CrudBase<CustomerDto>
  implements ICustomerSubCustomersApiService {

  constructor() {
    super(CUSTOMERS);
  }

  getPagedSubCustomers(customerId: number, skip?: number, take?: number, filterName?: string[], filterValue?: string,
                       sortName?: string[], sortDirection?: ApiSortDirection)
    : Observable<GenericApiResponse<CustomerDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  createSubCustomer(customerId: number, SubCustomerId: number)
    : Observable<GenericApiResponse<CustomerDto>> {
    return NEVER;
  }

  deleteSubCustomer(customerId: number, SubCustomerId: number)
    : Observable<GenericApiResponse<CustomerDto>> {
    return NEVER;
  }

  deleteBulkSubCustomers(customerId: number, SubCustomerId?: number[])
    : Observable<GenericApiResponse<GenericApiResponse<CustomerDto>[]>> {
    return NEVER;
  }


}
