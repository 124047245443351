import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AntDataResponse } from '@antony/ng-ui';
import { AntCollectionSortLoadingParams, GetCollectionApiService } from '@api/models';
import { LoadingParamsUtils } from '@api/utils';
import { BaseReceiptVersionDto } from './base-receipt-version.dto';
import { ReceiptVersionApiService } from './receipt-version-api-service';

export abstract class ReceiptVersionService<V extends BaseReceiptVersionDto> implements GetCollectionApiService<V> {
  protected constructor(protected api: ReceiptVersionApiService<V>) {
  }

  getData(params: AntCollectionSortLoadingParams<V>): Observable<AntDataResponse<V>> {
    return this.api.getPagedReceiptVersions(
      LoadingParamsUtils.getIdFrom(params),
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }
}
