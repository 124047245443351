
<mat-card *ngIf="!!noPermissionsError" class="app-error">
  <mat-card-header>
    <div mat-card-avatar class="bg-warn">
      <mat-icon>error</mat-icon>
    </div>
    <mat-card-title>{{ noPermissionsError.key | translate }}</mat-card-title>
    <mat-card-subtitle>{{ noPermissionsError.message | translate }}</mat-card-subtitle>
  </mat-card-header>
</mat-card>
