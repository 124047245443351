import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  getMainMenuItems(): Routes {
    const items: Routes = [];
    try {
      this.router.config.forEach((item) => {
        if (item.data !== undefined && item.data !== null && item.data.title) {
          items.push(item);
        }
      });
    } catch (e) {
      console.error(e);
    }
    return items;
  }
}
