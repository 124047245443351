export class DownloadHelper {

  private static document: Document = document;

  public static downloadFromUrl(url: string, name?: string): void {
    const anchor = this.generateAnchorElement();
    this.setUrlToAnchorElement(anchor, url, name);
    this.appendAnchorToBody(anchor);
    this.startDownload(anchor);
    this.removeAnchorToBody(anchor);
  }

  private static generateAnchorElement(): HTMLAnchorElement {
    return this.document.createElement('a');
  }

  private static setUrlToAnchorElement(anchor: HTMLAnchorElement, url: string, name?: string): void {
    anchor.href = url;
    anchor.download = name || '';
  }

  private static appendAnchorToBody(anchor: HTMLAnchorElement): void {
    this.document.body.append(anchor);
  }

  private static startDownload(anchor: HTMLAnchorElement): void {
    anchor.click();
  }

  private static removeAnchorToBody(anchor: HTMLAnchorElement): void {
    anchor.remove();
  }
}
