import { ArticlePriceDto } from '@api';

export const ARTICLE_PRICES: ArticlePriceDto[] = [
  {
    id: 1,
    basePrice: 12,
    rentPrice: 12,
    rentRenewalPrice: 12,
    theftProtectionPrice: 12,
    cleaningPrice: 12,
    rollingPrice: 12,
    movePrice: 12,
    lastChange: null,
    created: null
  }
];
