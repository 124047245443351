import { Injectable } from '@angular/core';
import { TranslatableHighPrioNotificationOptions, AppNotificationService } from '@app/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerDto } from '@api';
import { DisplayNamePipe } from '@shared/pipes/display-name.pipe';

@Injectable({
  providedIn: 'root'
})
export class CheckInactiveCustomerService {

  constructor(
    private displayNamePipe: DisplayNamePipe,
    private notification: AppNotificationService) { }

  public checkInactiveCustomer(customer: CustomerDto | null): Observable<boolean> {
    if (!customer?.isInactive) {
      return of(true);
    }
    return this.openWarnDialog(
      this.getWarnDialogOptions('CUSTOMER', 'INACTIVE_WARNING', { customerName: this.displayNamePipe.transform(customer) })
    );
  }

  private openWarnDialog(options: TranslatableHighPrioNotificationOptions): Observable<boolean> {
    return this.notification.openHighPriority(options).afterFinished().pipe(
      map(result => !!result)
    );
  }

  private getWarnDialogOptions(prefix: string, suffix: string, messageData: any): TranslatableHighPrioNotificationOptions {
    const baseTranslatePath = `${ prefix }.${ suffix }`;
    return {
      title: `${ baseTranslatePath }.TITLE`,
      message: `${ baseTranslatePath }.MESSAGE`,
      submitActionLabel: `${ baseTranslatePath }.SUBMIT`,
      dismissActionLabel: `${ baseTranslatePath }.DISMISS`,
      messageData,
    };
  }
}
