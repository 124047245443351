
import { DEFAULT_CURRENCY_CODE, enableProdMode, LOCALE_ID, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env';
import { API_BASE_URL } from '@api';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function getApiUrl() {
  return location.origin;
}

const providers: StaticProvider[] = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: API_BASE_URL, useValue: 'http://localhost:5000' },
  { provide: LOCALE_ID, useValue: 'de-DE' },
  { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
  { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
];

if (environment.production) {
  enableProdMode();
  providers.push(
    { provide: API_BASE_URL, useFactory: getApiUrl, deps: [] }
  );
}


registerLocaleData(localeDe, 'de-DE', localeDeExtra);
platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
