import { Injectable } from '@angular/core';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import {
  ApiResponseOfIEnumerableOfRapDto,
  ApiResponseOfListOfApiResponseOfRapDto,
  ApiResponseOfRapDto,
  ApiSortDirection,
  GetDataApiService,
  IRapsApiService,
  RapDto
} from '@api';
import { ORDERS } from '@app/mock/data/orders';
import { NEVER, Observable, of } from 'rxjs';

const baseRapItem: RapDto = {
  id: 1,
  order: ORDERS[0],
  orderId: 1,
  sumAmount: Math.random() * 100000,
  comment: '',
  startDate: '2022-01-01T00:00:00Z',
  endDate: '2022-01-01T00:00:00Z',
  created: '2022-01-01T00:00:00Z',
  lastChange: '2022-01-01T00:00:00Z',
};

export const RAP_ITEMS: RapDto[] = [
  {
    ...baseRapItem,
    id: 1,
  },
  {
    ...baseRapItem,
    id: 2,
  },
  {
    ...baseRapItem,
    id: 3,
  },
  {
    ...baseRapItem,
    id: 4,
  }
];

@Injectable()
export class RapApiMockService implements IRapsApiService, GetDataApiService<RapDto> {

  constructor() {
  }

  getData(params: Partial<AntSortLoadingParams<RapDto>>): Observable<AntDataResponse<RapDto>> {
    return this.loadRapItems(params);
  }

  getOpenPagesRaps(loadOpenRapsOnly?: boolean | undefined,
                   skip?: number | undefined,
                   take?: number | undefined,
                   filterName?: string[] | null | undefined,
                   filterValue?: string | null | undefined,
                   sortName?: string[] | null | undefined,
                   sortDirection?: ApiSortDirection | undefined): Observable<ApiResponseOfIEnumerableOfRapDto> {
    return NEVER;
  }

  loadById(id: number): Observable<RapDto> {
    return of(RAP_ITEMS[id - 1]);
  }

  loadRapItems(params: Partial<AntSortLoadingParams<RapDto>>): Observable<AntDataResponse<RapDto>> {
    return of({
      data: RAP_ITEMS,
      maxCount: RAP_ITEMS.length
    });
  }

  createRap(dto: RapDto): Observable<ApiResponseOfRapDto> {
    return NEVER;
  }

  deleteBulkRaps(id?: number[] | null | undefined): Observable<ApiResponseOfListOfApiResponseOfRapDto> {
    return NEVER;
  }

  deleteRap(id: number): Observable<ApiResponseOfRapDto> {
    return NEVER;
  }

  getPagedRaps(
    skip?: number | undefined,
    take?: number | undefined,
    filterName?: string[] | null | undefined,
    filterValue?: string | null | undefined,
    sortName?: string[] | null | undefined,
    sortDirection?: ApiSortDirection | undefined): Observable<ApiResponseOfIEnumerableOfRapDto> {
    return NEVER;
  }

  getRap(id: number): Observable<ApiResponseOfRapDto> {
    return NEVER;
  }

  updateRap(id: number, dto: RapDto): Observable<ApiResponseOfRapDto> {
    return NEVER;
  }

}
