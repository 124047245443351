<mat-card class="mat-elevation-z0">
  <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <mat-card-header>
      <mat-card-title>{{ 'AUTHENTICATION.PASSWORD_RESET.TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p [innerHTML]="'AUTHENTICATION.PASSWORD_GUIDELINE' | translate"></p>
      <mat-form-field appearance="outline" *ngIf="resetForm.get('password') as control">
        <mat-label>{{ 'AUTHENTICATION.PROPERTY.PASSWORD.LABEL' | translate }}</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          autocomplete="new-password"
        >
        <mat-icon matSuffix (click)="showPassword = !showPassword">
          <ng-container *ngIf="!showPassword">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD.SUFFIX.HIDDEN' | translate }}
          </ng-container>
          <ng-container *ngIf="showPassword">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD.SUFFIX.SHOWN' | translate }}
          </ng-container>
        </mat-icon>
        <mat-error *ngIf="(control.dirty || control.touched) && control.errors as errors">
          <ng-container *ngFor="let error of errors | keyvalue | slice:0:1">
            <ng-container [ngSwitch]="error.key">
              <ng-container *ngSwitchCase="'required'">
                {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.EMPTY' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'minlength'">
                {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.MIN_LENGTH' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'maxlength'">
                {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.MAX_LENGTH' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'shouldContainerNumber'">
                {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.CONTAIN_NUMBER' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'shouldContainLowercaseLetter'">
                {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.CONTAIN_LOWERCASE_LETTER' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'shouldContainUppercaseLetter'">
                {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.CONTAIN_UPPERCASE_LETTER' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'shouldContainSpecialCharacter'">
                {{ 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.CONTAIN_SPECIAL_CHARACTER' | translate }}
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="resetForm.get('passwordRepeat') as control">
        <mat-label>{{ 'AUTHENTICATION.PROPERTY.PASSWORD_REPEAT.LABEL' | translate }}</mat-label>
        <input
          matInput
          [type]="showPasswordRepeat ? 'text' : 'password'"
          formControlName="passwordRepeat"
          autocomplete="new-password"
        >
        <mat-icon matSuffix (click)="showPasswordRepeat = !showPasswordRepeat">
          <ng-container *ngIf="!showPasswordRepeat">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD_REPEAT.SUFFIX.HIDDEN' | translate }}
          </ng-container>
          <ng-container *ngIf="showPasswordRepeat">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD_REPEAT.SUFFIX.SHOWN' | translate }}
          </ng-container>
        </mat-icon>
        <mat-error *ngIf="(control.dirty || control.touched) && control.errors as errors">
          <ng-container *ngIf="errors.required || errors.minLength">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD_REPEAT.ERRORS.EMPTY' | translate }}
          </ng-container>
          <ng-container *ngIf="errors.passwordsDifferent">
            {{ 'AUTHENTICATION.PROPERTY.PASSWORD_REPEAT.ERRORS.PASSWORDS_DIFFERENT' | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <ant-button
        [labelHideBreakpoints]="labelHideBreakpoints"
        color="primary"
        [disabled]="!resetForm.valid"
        [loading]="hasSubmitted()"
        (click)="onSubmit()"
        mode="flat"
        [hideIcon]="true"
        role="button"
        type="submit"
        prefix="AUTHENTICATION.ACTIONS."
        actionName="SUBMIT"
      ></ant-button>
    </mat-card-actions>
  </form>
</mat-card>
