import { GetDataApiService } from '@api/models';
import { ApiResponseUtils, LoadingParamsUtils } from '@api/utils';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { from, Observable } from 'rxjs';
import { filter, map, skip, switchMap, take, toArray } from 'rxjs/operators';
import { ReceiptKindApiService } from '@api/services/receipt/base/receipt-kind-api-service';
import { BaseReceiptDto, BaseReceiptPositionDto, BaseReceiptVersionDto } from '@core/api';

export abstract class ReceiptKindService<T extends BaseReceiptDto<V>,
  V extends BaseReceiptVersionDto<P>,
  P extends BaseReceiptPositionDto> implements GetDataApiService<string> {

  protected constructor(protected api: ReceiptKindApiService<T, V, P>) {
  }

  getData(params: Partial<AntSortLoadingParams<string>>): Observable<AntDataResponse<string>> {
    return this.loadDataFromApi().pipe(
      switchMap(res => this.applyLoadingParamsToDataResponse(res, params))
    );
  }

  protected loadDataFromApi(): Observable<AntDataResponse<string>> {
    return this.api.getKinds().pipe(
      map(res => ApiResponseUtils.getAntDataResponseFromResponse(res))
    );
  }

  protected applyLoadingParamsToDataResponse(dataResponse: AntDataResponse<string>,
                                             params: Partial<AntSortLoadingParams<string>>): Observable<AntDataResponse<string>> {
    return from(dataResponse.data).pipe(
      skip(LoadingParamsUtils.getSkipFrom(params)),
      take(LoadingParamsUtils.getTakeFrom(params)),
      filter(v => v.includes(LoadingParamsUtils.getFilterValueFrom(params))),
      toArray(),
      map(items => ({
        data: items,
        maxCount: dataResponse.maxCount
      }))
    );
  }
}
