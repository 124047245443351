import { CrudBase } from '@app/mock/models';
import { ApiResponseOfIEnumerableOfInvoiceVersionDto, IInvoiceVersionsApiService, InvoiceVersionDto } from '@api';
import { Observable } from 'rxjs';
import { INVOICE_VERSIONS } from '@app/mock/data/invoice_versions';
import { Injectable } from '@angular/core';

@Injectable()
export class InvoiceVersionsApiMockService extends CrudBase<InvoiceVersionDto> implements IInvoiceVersionsApiService {
  constructor() {
    super(INVOICE_VERSIONS);
  }

  getPagedReceiptVersions(invoiceId: number, skip?: number, take?: number): Observable<ApiResponseOfIEnumerableOfInvoiceVersionDto> {
    return this.getRange(skip, take);
  }
}
