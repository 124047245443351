import { Injectable } from '@angular/core';
import {
  DeliveryNoteApiService,
  DeliveryNoteDto,
  DeliveryNotePositionDto,
  DeliveryNoteVersionDto
} from '@api/api.service';
import { ReceiptService } from '../base';
import { ApiResponseUtils, DestructiveResourceApiService, SeekPaginationResponse } from '@core/api';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { BacklogFilterDto } from '@api/models';
import { AntDataResponse } from '@antony/ng-ui';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteService extends ReceiptService<DeliveryNoteDto, DeliveryNoteVersionDto, DeliveryNotePositionDto> implements DestructiveResourceApiService<DeliveryNoteDto> {
  constructor(protected readonly api: DeliveryNoteApiService) {
    super(api);
  }

  getBacklog(backlogFilter: BacklogFilterDto): Observable<SeekPaginationResponse<DeliveryNoteDto>> {
    return this.api.getBacklog(
      backlogFilter.customerIds,
      backlogFilter.constructionProjectId,
      backlogFilter.kind,
      backlogFilter.status?.id,
      backlogFilter.articleId,
      this.convertDate(backlogFilter.deliveryDateFrom),
      this.convertDate(backlogFilter.deliveryDateTo),
      backlogFilter.zipCodeFrom,
      backlogFilter.zipCodeTo,
      backlogFilter.articleCountFrom,
      backlogFilter.articleCountTo,
      backlogFilter.fromItemId,
      backlogFilter.take,
    );
  }

  delete(resourceId: number): Observable<boolean> {
    return this.api.deleteDeliveryNote(resourceId).pipe(
      map(ApiResponseUtils.getSuccessFromResponse),
      catchError(() => of(false))
    );
  }

  deleteBulk(resourceIds: number[]): Observable<boolean[]> {
    return from(resourceIds ?? []).pipe(
      mergeMap(id => this.delete(id), 3),
      toArray()
    );
  }

  private convertDate(date: string | null): string | null {
    if (!date) {
      return null;
    }
    return new Date(Date.parse(date)).toISOString();
  }
}
