import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { AddressDto } from '@api';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: AddressDto | null, args?: any): string {
    if (!value || (!value.street && !value.city)) {
      return '-';
    }

    // Street

    const streetParts: string[] = [
      ...[
        !!value.street ? value.street : '',
        value.houseNumber !== null && !!value.street ? value.houseNumber.toString() : ''
      ].filter(s => s !== '')
    ];
    const streetPart: string | null = streetParts.length > 0 ? streetParts.join(' ') : null;

    // City

    const cityParts: string[] = [
      ...[
        !!value.zipCode && !!value.city ? value.zipCode : '',
        !!value.city ? value.city : ''
      ].filter(s => s !== '')
    ];
    const cityPart: string | null = cityParts.length > 0 ? cityParts.join(' ') : null;

    const final = [
      ...[
        !!streetPart ? streetPart : '',
        !!cityPart ? cityPart : '',
        !!value.country ? value.country.name : ''
      ].filter(s => s !== '')
    ];

    return final.join(', ');
  }

}
