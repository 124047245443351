<h2 matDialogTitle>{{ data.title | translate }}</h2>
<mat-dialog-content>{{ data.message | translate }}</mat-dialog-content>
<mat-dialog-actions>
  <ant-button color="primary"
              tabindex="0"
              (click)="dismiss()"
              [actionName]="data.dismissActionName"
              [hideIcon]="true"
  ></ant-button>
  <ant-button color="warn"
              tabindex="1"
              (click)="submit()"
              [actionName]="data.submitActionName"
              [hideIcon]="true"
  ></ant-button>
</mat-dialog-actions>
