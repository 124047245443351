import { GenericApiResponse } from '@app/mock/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReceiptKindApiService } from '@api/services/receipt/base/receipt-kind-api-service';

export class BaseReceiptKindsApiMockService implements ReceiptKindApiService<any, any, any> {

  constructor(protected readonly kinds: string[]) { }

  getKinds(): Observable<GenericApiResponse<string[]>> {
    return of(this.kinds).pipe(
      map(kinds => ({
        data: kinds,
        maxCount: kinds.length,
        count: kinds.length,
        message: null,
        success: true
      }))
    );
  }

  getTransferKinds(): Observable<GenericApiResponse<string[]>> {
    return of(this.kinds).pipe(
      map(kinds => ({
        data: kinds,
        maxCount: kinds.length,
        count: kinds.length,
        message: null,
        success: true
      }))
    );
  }

}
