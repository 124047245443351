export const DEFAULT_ERROR_MESSAGES = {
  required: 'APP.ERRORS.REQUIRED',
  email: 'APP.ERRORS.MAIL',
  maxlength: 'APP.ERRORS.MAXLENGTH',
  minlength: 'APP.ERRORS.MINLENGTH',
  number: 'APP.ERRORS.NUMBER',
  max: 'APP.ERRORS.MAX',
  min: 'APP.ERRORS.MIN',
  lessThan: 'APP.ERRORS.LESS_THAN',
  greaterThan: 'APP.ERRORS.GREATER_THAN',
  integer: 'APP.ERRORS.INTEGER',
  matDatepickerMax: 'APP.ERRORS.MAT_DATEPICKER_MAX',
  matDatepickerMin: 'APP.ERRORS.MAT_DATEPICKER_MIN',
  notEqualValue: 'APP.ERRORS.NOT_EQUAL_VALUE',
  equalValue: 'APP.ERRORS.EQUAL_VALUE',
  serverError: 'APP.ERRORS.SERVER_ERROR',
  serverValidation: 'APP.ERRORS.SERVER_VALIDATION',
  rangeMax: 'APP.ERRORS.RANGE_MAX',
  rangeMin: 'APP.ERRORS.RANGE_MIN',
  rangeEqualMax: 'APP.ERRORS.RANGE_EQUAL_MAX',
  rangeEqualMin: 'APP.ERRORS.RANGE_EQUAL_MIN',
  requireOneOfEither: 'APP.ERRORS.REQUIRE_ONE_OF_EITHER',
  noMatches: 'APP.ERRORS.NO_MATCHES',
  userIsInactive: 'APP.ERRORS.USER_IS_INACTIVE',
  crossOtherDate: 'APP.ERRORS.DATE_RANGE_CROSS',
  transitionIsInvalid: 'APP.ERRORS.INVALID_TRANSITION',
  invalidStartDate: 'APP.ERRORS.INVALID_START_DATE',
  sameArticleSelected: 'APP.ERRORS.ARTICLES.MULTIPLE_SELECTED',
  notEnoughItems: 'APP.ERRORS.ARRAY.NOT_ENOUGH_ITEMS',
  requireAmountOfFields: 'APP.ERRORS.AMOUNT_OF_FIELDS_REQUIRED',
  noDuplicateVehicleType: 'VEHICLE_TYPE_PRICE.ERRORS.NO_DUPLICATE_VEHICLE_TYPE',
  unknownArticle: 'RECEIPT.ERRORS.UNKNOWN_ARTICLE',
  passwordsDifferent: 'USER.ERRORS.PASSWORD_DIFFERENT',
  phoneNumber: 'APP.ERRORS.PHONE_NUMBER',
  invalidPaymentInputs: 'APP.ERRORS.INVALID_PAYMENT_INPUT',
};
