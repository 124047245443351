import { Injectable } from '@angular/core';
import { RuiterUserDto, TokenRenewalResultDtoOfRuiterUserDto } from '@api';
import { environment } from '@env';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged } from 'rxjs/operators';

@Injectable()
export class AuthenticationStorageService {

  private readonly userSubject$ = new ReplaySubject<RuiterUserDto | null>(1);
  public readonly user$: Observable<RuiterUserDto | null> = this.userSubject$.asObservable();
  private readonly storage: Storage;

  constructor() {
    this.storage = localStorage;
    const user = this.getUser();
    this.userSubject$.next(user);
  }

  hasTokenData(): boolean {
    return this.getTokenData() !== null;
  }

  saveTokenData(token: TokenRenewalResultDtoOfRuiterUserDto): void {
    this.storage.setItem(
      environment.authentication.tokenName,
      JSON.stringify(token)
    );
    this.userSubject$.next(token.user);
  }

  removeTokenData() {
    this.userSubject$.next(null);
    this.storage.removeItem(environment.authentication.tokenName);
  }


  getApiToken(): string | null {
    if (!this.hasTokenData()) {
      return null;
    }
    const tokenData = this.getTokenData();
    return tokenData?.token || null;
  }

  getRenewToken(): string | null {
    if (!this.hasTokenData()) {
      return null;
    }
    const tokenData = this.getTokenData();
    return tokenData?.refreshToken || null;
  }

  getUsername(): string | null {
    if (!this.hasTokenData()) {
      return null;
    }
    const tokenData = this.getTokenData();
    const user = tokenData?.user || null;
    return user?.username || null;
  }

  getUser(): RuiterUserDto | null {
    if (!this.hasTokenData()) {
      return null;
    }
    const tokenData = this.getTokenData();
    return tokenData?.user || null;
  }

  updateUser(updatedUser: RuiterUserDto) {
    const token = this.getTokenData();
    if (!token) {
      return;
    }
    token.user = updatedUser;
    this.saveTokenData(token);
  }

  private getTokenData(): TokenRenewalResultDtoOfRuiterUserDto | null {
    const item = this.storage.getItem(environment.authentication.tokenName);
    if (item === null) {
      return null;
    } else {
      return JSON.parse(item) as TokenRenewalResultDtoOfRuiterUserDto;
    }
  }
}
