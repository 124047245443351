import { CrudBase, GenericApiResponse } from '@app/mock/models';
import {
  AddCleaningPositionDto,
  AddMovePositionDto,
  AddRollingPositionDto,
  AddTheftProtectionPositionDto,
  AddUnsuccessfullDeliveryPositionDto,
  AddWaitingTimePositionDto,
  ApiResponseOfCreditNoteDto,
  ApiResponseOfDownloadReportDto,
  ApiResponseOfIEnumerableOfCreditNoteDto,
  ApiResponseOfInvoiceDto,
  ApiResponseOfInvoicePositionDto,
  ApiResponseOfInvoiceVersionDto,
  ApiResponseOfListOfInvoiceVersionDto,
  ApiSortDirection,
  DefaultPositionCalculationDto,
  FileResponse,
  IInvoicesApiService,
  InvoiceDto,
  InvoiceStatusDto,
  InvoiceVersionDto,
  ReceiptStatusUpdateDto,
  TransferInvoiceDto
} from '@api';
import { INVOICES } from '@app/mock/data/invoices';
import { NEVER, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseReceiptSystemPropertiesDto } from '@api/models';

@Injectable()
export class InvoicesApiMockService extends CrudBase<InvoiceDto> implements IInvoicesApiService {
  constructor() {
    super(INVOICES);
  }

  updateStatus(updateDto: ReceiptStatusUpdateDto): Observable<ApiResponseOfListOfInvoiceVersionDto> {
    return NEVER;
  }

  getPagedReceipts(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                   sortName?: string[], sortDirection?: ApiSortDirection): Observable<GenericApiResponse<InvoiceDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getReceipt(id: number): Observable<GenericApiResponse<InvoiceDto>> {
    return this.get(id);
  }

  requestReportDownload(invoiceId: number): Observable<ApiResponseOfDownloadReportDto> {
    return NEVER;
  }

  downloadReport(token?: string | null | undefined): Observable<FileResponse> {
    return NEVER;
  }

  updateReceipt(invoiceId: number, dto: InvoiceVersionDto): Observable<ApiResponseOfInvoiceVersionDto> {
    return NEVER;
  }

  getTransferredCreditNotes(invoiceId: number, skip?: number, take?: number,
                            filterName?: string[] | null, filterValue?: string | null,
                            sortName?: string[] | null, sortDirection?: ApiSortDirection)
    : Observable<ApiResponseOfIEnumerableOfCreditNoteDto> {
    return NEVER;
  }

  transferReceipt(transferData: TransferInvoiceDto, invoiceId: number): Observable<ApiResponseOfCreditNoteDto> {
    return NEVER;
  }

  addUnsuccessfullDeliveryPositions(dto: AddUnsuccessfullDeliveryPositionDto,
                                    invoiceId: number): Observable<ApiResponseOfInvoiceVersionDto> {
    return NEVER;
  }

  addWaitingTimePosition(dto: AddWaitingTimePositionDto, invoiceId: number): Observable<ApiResponseOfInvoiceVersionDto> {
    return NEVER;
  }

  release(invoiceId: number): Observable<ApiResponseOfInvoiceVersionDto> {
    return NEVER;
  }

  addRollingPosition(dto: AddRollingPositionDto, invoiceId: number): Observable<ApiResponseOfInvoiceVersionDto> {
    return NEVER;
  }

  addCleaningPosition(dto: AddCleaningPositionDto, invoiceId: number): Observable<ApiResponseOfInvoiceVersionDto> {
    return NEVER;
  }

  updateReleasedReceipt(invoiceId: number, data: BaseReceiptSystemPropertiesDto<InvoiceStatusDto>):
    Observable<ApiResponseOfInvoiceVersionDto> {
    return NEVER;
  }

  addMovePosition(dto: AddMovePositionDto, invoiceId: number): Observable<ApiResponseOfInvoiceVersionDto> {
    return NEVER;
  }

  addTheftProtection(invoiceId: number, addTheftProtectionPositionDto: AddTheftProtectionPositionDto): Observable<ApiResponseOfInvoiceDto> {
    return NEVER;
  }

  calculatePositionDefaults(receiptId: number, dto: DefaultPositionCalculationDto): Observable<ApiResponseOfInvoicePositionDto> {
    return NEVER;
  }
}
