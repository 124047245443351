import { AddressPipe } from './address.pipe';
import { DisplayNamePipe } from './display-name.pipe';
import { ExtendedDatePipe } from './extended-date.pipe';
import { DisplayNameMultilinePipe } from './display-name-multiline.pipe';
import { ArticleNamePipe } from './article-name.pipe';

export const PIPES = [
  AddressPipe,
  DisplayNamePipe,
  ExtendedDatePipe,
  DisplayNameMultilinePipe,
  ArticleNamePipe
];
