import { CrudBase } from '@app/mock/models';
import { ApiResponseOfIEnumerableOfTimeUnitDto, ApiResponseOfTimeUnitDto, ApiSortDirection, TimeUnitDto } from '@api';
import { TIME_UNITS } from '@app/mock/data/time-units';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class TimeUnitApiMockService extends CrudBase<TimeUnitDto> {
  constructor() {
    super(TIME_UNITS);
  }

  getPagedTimeUnits(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                    sortName?: string[], sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfTimeUnitDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getTimeUnit(id: number): Observable<ApiResponseOfTimeUnitDto> {
    return this.get(id);
  }
}
