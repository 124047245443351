import { ContactPersonDto, Salutation } from '@api';

export const CONTACT_PERSONS: ContactPersonDto[] = [
  {
    id: 50,
    salutation: Salutation.Mr,
    firstname: 'Matthews',
    lastname: 'Jacobson',
    company: null,
    position: 'labore',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 1,
    salutation: Salutation.Mr,
    firstname: 'Duncan',
    lastname: 'Conner',
    company: null,
    position: 'adipisicing',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 2,
    salutation: Salutation.Mr,
    firstname: 'Mari',
    lastname: 'Odom',
    company: null,
    position: 'officia',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 3,
    salutation: Salutation.Mr,
    firstname: 'Delaney',
    lastname: 'Cain',
    company: null,
    position: 'magna',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 4,
    salutation: Salutation.Mr,
    firstname: 'Susie',
    lastname: 'Summers',
    company: null,
    position: 'cillum',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 5,
    salutation: Salutation.Mr,
    firstname: 'Sykes',
    lastname: 'Stout',
    company: null,
    position: 'ipsum',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 6,
    salutation: Salutation.Mr,
    firstname: 'Norris',
    lastname: 'Sloan',
    company: null,
    position: 'enim',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 7,
    salutation: Salutation.Mr,
    firstname: 'Lara',
    lastname: 'Cameron',
    company: null,
    position: 'anim',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 8,
    salutation: Salutation.Mr,
    firstname: 'Roach',
    lastname: 'Graves',
    company: null,
    position: 'excepteur',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 9,
    salutation: Salutation.Mr,
    firstname: 'Alyce',
    lastname: 'Franks',
    company: null,
    position: 'aliqua',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 10,
    salutation: Salutation.Mr,
    firstname: 'Valenzuela',
    lastname: 'Michael',
    company: null,
    position: 'elit',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 11,
    salutation: Salutation.Mr,
    firstname: 'Wilma',
    lastname: 'Shannon',
    company: null,
    position: 'quis',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 12,
    salutation: Salutation.Mr,
    firstname: 'Joy',
    lastname: 'Ayala',
    company: null,
    position: 'proident',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 13,
    salutation: Salutation.Mr,
    firstname: 'Sparks',
    lastname: 'York',
    company: null,
    position: 'proident',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 14,
    salutation: Salutation.Mr,
    firstname: 'Debra',
    lastname: 'Lopez',
    company: null,
    position: 'ad',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 15,
    salutation: Salutation.Mr,
    firstname: 'Becky',
    lastname: 'Collins',
    company: null,
    position: 'amet',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 16,
    salutation: Salutation.Mr,
    firstname: 'Roberts',
    lastname: 'Walter',
    company: null,
    position: 'sint',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 17,
    salutation: Salutation.Mr,
    firstname: 'Bonner',
    lastname: 'Cummings',
    company: null,
    position: 'id',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 18,
    salutation: Salutation.Mr,
    firstname: 'Jolene',
    lastname: 'Gray',
    company: null,
    position: 'aliquip',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 19,
    salutation: Salutation.Mr,
    firstname: 'Dina',
    lastname: 'Leblanc',
    company: null,
    position: 'dolore',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 20,
    salutation: Salutation.Mr,
    firstname: 'Mcclain',
    lastname: 'Miles',
    company: null,
    position: 'aliquip',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 21,
    salutation: Salutation.Mr,
    firstname: 'Gutierrez',
    lastname: 'Barrera',
    company: null,
    position: 'minim',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 22,
    salutation: Salutation.Mr,
    firstname: 'Meghan',
    lastname: 'Delgado',
    company: null,
    position: 'enim',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 23,
    salutation: Salutation.Mr,
    firstname: 'Stafford',
    lastname: 'Figueroa',
    company: null,
    position: 'adipisicing',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 24,
    salutation: Salutation.Mr,
    firstname: 'Whitehead',
    lastname: 'Le',
    company: null,
    position: 'sit',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 25,
    salutation: Salutation.Mr,
    firstname: 'Melisa',
    lastname: 'Walls',
    company: null,
    position: 'ipsum',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 26,
    salutation: Salutation.Mr,
    firstname: 'Schmidt',
    lastname: 'Mccormick',
    company: null,
    position: 'consequat',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 27,
    salutation: Salutation.Mr,
    firstname: 'Wyatt',
    lastname: 'Macdonald',
    company: null,
    position: 'anim',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 28,
    salutation: Salutation.Mr,
    firstname: 'Gwendolyn',
    lastname: 'Bush',
    company: null,
    position: 'cillum',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 29,
    salutation: Salutation.Mr,
    firstname: 'Nita',
    lastname: 'Stephens',
    company: null,
    position: 'nostrud',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 30,
    salutation: Salutation.Mr,
    firstname: 'Peck',
    lastname: 'Robinson',
    company: null,
    position: 'deserunt',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 31,
    salutation: Salutation.Mr,
    firstname: 'Jillian',
    lastname: 'Cooper',
    company: null,
    position: 'ex',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 32,
    salutation: Salutation.Mr,
    firstname: 'Alyssa',
    lastname: 'Burgess',
    company: null,
    position: 'ex',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 33,
    salutation: Salutation.Mr,
    firstname: 'Heidi',
    lastname: 'Myers',
    company: null,
    position: 'laboris',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 34,
    salutation: Salutation.Mr,
    firstname: 'Welch',
    lastname: 'Gaines',
    company: null,
    position: 'ullamco',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 35,
    salutation: Salutation.Mr,
    firstname: 'Shelly',
    lastname: 'Wallace',
    company: null,
    position: 'et',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 36,
    salutation: Salutation.Mr,
    firstname: 'Ethel',
    lastname: 'Velez',
    company: null,
    position: 'dolor',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 37,
    salutation: Salutation.Mr,
    firstname: 'Lloyd',
    lastname: 'Ryan',
    company: null,
    position: 'dolor',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 38,
    salutation: Salutation.Mr,
    firstname: 'Justine',
    lastname: 'Horn',
    company: null,
    position: 'non',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 39,
    salutation: Salutation.Mr,
    firstname: 'Blevins',
    lastname: 'Shepherd',
    company: null,
    position: 'voluptate',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 40,
    salutation: Salutation.Mr,
    firstname: 'Mcintosh',
    lastname: 'Fitzgerald',
    company: null,
    position: 'ullamco',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 41,
    salutation: Salutation.Mr,
    firstname: 'William',
    lastname: 'Heath',
    company: null,
    position: 'magna',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 42,
    salutation: Salutation.Mr,
    firstname: 'Berg',
    lastname: 'Crawford',
    company: null,
    position: 'incididunt',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 43,
    salutation: Salutation.Mr,
    firstname: 'Beasley',
    lastname: 'Mitchell',
    company: null,
    position: 'cillum',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 44,
    salutation: Salutation.Mr,
    firstname: 'Moran',
    lastname: 'Chase',
    company: null,
    position: 'aute',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 45,
    salutation: Salutation.Mr,
    firstname: 'Baxter',
    lastname: 'Lowery',
    company: null,
    position: 'et',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 46,
    salutation: Salutation.Mr,
    firstname: 'Farmer',
    lastname: 'Elliott',
    company: null,
    position: 'velit',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 47,
    salutation: Salutation.Mr,
    firstname: 'Holt',
    lastname: 'Madden',
    company: null,
    position: 'commodo',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 48,
    salutation: Salutation.Mr,
    firstname: 'Jewell',
    lastname: 'Mcfarland',
    company: null,
    position: 'ipsum',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  },
  {
    id: 49,
    salutation: Salutation.Mr,
    firstname: 'Mai',
    lastname: 'Jennings',
    company: null,
    position: 'tempor',
    comment: '',
    contactOptions: null,
    created: null,
    lastChange: null,
    constructionProjects: [],
    customers: [],
  }
];
