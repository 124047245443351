import { Component } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-root',
  template: `<app-navigation>
    <ant-notification-banner></ant-notification-banner>
    <router-outlet></router-outlet>
  </app-navigation>`,
  styles: [`
    ant-notification-banner {
      margin-bottom: 1rem;
    }
  `]
})
export class AppComponent {}
