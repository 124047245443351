import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/authentication/services/authentication.service';
import { environment } from '@env';
import { Observable, of } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';

@Injectable()
export class ServerVersionInterceptor implements HttpInterceptor {

  private readonly apiVersionHeaderName = 'Api-Assembly-Version';

  constructor(private authService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      exhaustMap((event: HttpEvent<any>): Observable<any> => {
        if (event instanceof HttpResponseBase) {
          return this.checkVersion(event);
        }
        return of(event);
      })
    );
  }

  private checkVersion(response: HttpResponseBase): Observable<any> {
    if (response.headers.has(this.apiVersionHeaderName)) {
      const apiVersion = response.headers.get(this.apiVersionHeaderName);
      if (environment.version !== apiVersion) {
        console.info(`Local version is outdated. (current: ${ environment.version}, latest: ${ apiVersion })`);
        return this.authService.logout(true);
      }
    }
    return of(response);
  }
}
