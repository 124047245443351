import { TimeUnitDto } from '@api';

export const TIME_UNITS: TimeUnitDto[] = [
  {
    id: 1,
    name: 'Minute(n)',
    symbol: 'min',
    created: null,
    lastChange: null
  }
];
