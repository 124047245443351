import { Provider } from '@angular/core';
import {
  ConstructionProjectContactPersonsApiService,
  ConstructionProjectInvoicesApiService,
  ConstructionProjectOffersApiService, ConstructionProjectOrdersApiService,
  ConstructionProjectsApiService
} from '@api';
import { ConstructionProjectsApiMockService } from './construction-projects-api-mock.service';
import { ConstructionProjectContactPersonApiMockService } from './construction-project-contact-person-api-mock.service';
import { ConstructionProjectOfferApiMockService } from './construction-project-offer.api-mock.service';
import { ConstructionProjectInvoiceApiMockService } from './construction-project-invoice.api-mock.service';
import { ConstructionProjectOrderApiMockService } from './construction-project-order.api-mock.service';

export const CONSTRUCTION_PROJECTS_PROVIDER: Provider[] = [
  { provide: ConstructionProjectsApiService, useClass: ConstructionProjectsApiMockService},
  { provide: ConstructionProjectContactPersonsApiService, useClass: ConstructionProjectContactPersonApiMockService},
  { provide: ConstructionProjectOffersApiService, useClass: ConstructionProjectOfferApiMockService },
  { provide: ConstructionProjectOrdersApiService, useClass: ConstructionProjectOrderApiMockService },
  { provide: ConstructionProjectInvoicesApiService, useClass: ConstructionProjectInvoiceApiMockService },
];
