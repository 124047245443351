import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { ApiSortDirection, ConstructionProjectDto, IConstructionProjectsApiService } from '@api';
import { CONSTRUCTION_PROJECTS } from '@app/mock/data/construction-projects';
import { Observable } from 'rxjs';

@Injectable()
export class ConstructionProjectsApiMockService extends CrudBase<ConstructionProjectDto> implements IConstructionProjectsApiService {
  constructor() {
    super(CONSTRUCTION_PROJECTS);
  }

  getPagedConstructionProjects(skip?: number, take?: number, filterName?: string[],
                               filterValue?: string, sortName?: string[], sortDirection?: ApiSortDirection):
    Observable<GenericApiResponse<ConstructionProjectDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getConstructionProject(id: number): Observable<GenericApiResponse<ConstructionProjectDto>> {
    return this.get(id);
  }

  createConstructionProject(dto: ConstructionProjectDto): Observable<GenericApiResponse<ConstructionProjectDto>> {
    return this.create(dto);
  }

  updateConstructionProject(id: number, dto: ConstructionProjectDto): Observable<GenericApiResponse<ConstructionProjectDto>> {
    return this.update(id, dto);
  }
}
