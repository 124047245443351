import { OrderPositionDto, OrderVersionDto } from '@api';
import { OFFER_POSITIONS } from '@app/mock/data/offer_versions';
import { ORDER_STATUS } from '@app/mock/data/order_status';
import { PAYMENT_CONDITIONS } from '@app/mock/data/paymen_conditions';

export const ORDER_POSITIONS: OrderPositionDto[] = [
  ...OFFER_POSITIONS
];

const baseOrderVersion: OrderVersionDto = {
  id: 0,
  receiptId: 0,
  status: ORDER_STATUS[0],
  statusId: ORDER_STATUS[0].id ?? 0,
  comment: null,
  deliveryCondition: null,
  deliveryConditionId: null,
  paymentCondition: null,
  paymentConditionId: null,
  deliveryDate: null,
  headerText: null,
  footerText: null,
  numberOfTransports: 0,
  rentalPeriodFrom: '2020-01-01T00:00:00.000Z',
  rentalPeriodTo: '2020-01-01T00:00:00.000Z',
  rentDurationDays: 0,
  billRecipientId: 0,
  billRecipient: null,
  kind: '',
  price: {
    vatAmount: 0,
    totalGrossPrice: 0,
    totalNetPrice: 0
  },
  positions: [],
  vat: 19,
  versionNumber: 1,
  createdBy: null,
  createdById: 0,
  created: null,
  lastChange: null
};

export const ORDER_VERSIONS: OrderVersionDto[] = [
  {
    ...baseOrderVersion,
    id: 1,
    positions: ORDER_POSITIONS,
    kind: 'Art der Arbeit',
    paymentCondition: PAYMENT_CONDITIONS[0],
    paymentConditionId: PAYMENT_CONDITIONS[0].id ?? 0
  }
];
