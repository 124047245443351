import { Injectable } from '@angular/core';
import {
  DeliveryNoteDownloadOptionsDto,
  DeliveryNoteDto,
  InvoiceDto,
  OrderDto,
  OrderPositionDto,
  OrdersApiService,
  OrderTransferApiService,
  OrderVersionDto,
  TransferOrderDto
} from '@api/api.service';
import { ReceiptService } from '../base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AntCollectionSortLoadingParams } from '../../../models';
import { ApiResponseUtils, LoadingParamsUtils } from '../../../utils';
import { AntDataResponse } from '@antony/ng-ui';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends ReceiptService<OrderDto, OrderVersionDto, OrderPositionDto> {
  constructor(protected api: OrdersApiService,
              protected transferApi: OrderTransferApiService) {
    super(api);
  }

  getTransferData(orderId: number): Observable<TransferOrderDto | null> {
    return this.api.getTransferData(orderId).pipe(
      map(res => res.data),
    );
  }

  transferToDeliveryNote(orderId: number, dto: DeliveryNoteDownloadOptionsDto): Observable<DeliveryNoteDto> {
    return this.transferApi.transferToDeliveryNote(orderId, dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  transferToInvoice(orderId: number, dto: TransferOrderDto): Observable<InvoiceDto> {
    return this.transferApi.transferToInvoice(orderId, dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  getTransferredDeliveryNotes(params: AntCollectionSortLoadingParams<DeliveryNoteDto>): Observable<AntDataResponse<DeliveryNoteDto>> {
    return this.transferApi.getTransferredDeliveryNotes(
      LoadingParamsUtils.getIdFrom(params),
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

  getTransferredInvoices(params: AntCollectionSortLoadingParams<InvoiceDto>): Observable<AntDataResponse<InvoiceDto>> {
    return this.transferApi.getTransferredInvoices(
      LoadingParamsUtils.getIdFrom(params),
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }
}
