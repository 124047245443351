import { Injectable } from '@angular/core';
import { GetDataApiService } from '@api/models';
import { InvoiceDto, InvoiceKindsApiService, InvoicePositionDto, InvoiceVersionDto } from '@api/api.service';
import { ReceiptKindService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class InvoiceKindService extends ReceiptKindService<InvoiceDto, InvoiceVersionDto, InvoicePositionDto>
  implements GetDataApiService<string> {

  constructor(protected api: InvoiceKindsApiService) {
    super(api);
  }

}
