import { CrudBase } from '@app/mock/models';
import { ApiResponseOfIEnumerableOfOrderVersionDto, IOrderVersionsApiService, OrderVersionDto } from '@api';
import { Observable } from 'rxjs';
import { ORDER_VERSIONS } from '@app/mock/data/order_versions';
import { Injectable } from '@angular/core';

@Injectable()
export class OrderVersionsApiMockService extends CrudBase<OrderVersionDto> implements IOrderVersionsApiService {
  constructor() {
    super(ORDER_VERSIONS);
  }

  getPagedReceiptVersions(orderId: number, skip?: number, take?: number): Observable<ApiResponseOfIEnumerableOfOrderVersionDto> {
    return this.getRange(skip, take);
  }
}
