import { interval, Observable, timer } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, share, shareReplay, tap } from 'rxjs/operators';
import { RuiterUserDto } from '@api';
import { AuthenticationStorageService } from '@core/authentication/services/authentication-storage.service';
import { MonitoringService } from '@core/services/monitoring.service';
import { Injectable } from '@angular/core';
import { RxjsUtils } from '@app/core';

@Injectable()
export class CurrentUserService {

  private currentUser$ = this.authStorage.user$.pipe(
    filter(RxjsUtils.isNotNullOrUndefined),
    distinctUntilKeyChanged('lastChange'),
    tap(user => this.currentUser = user),
    shareReplay(1),
  );
  private currentUser: RuiterUserDto | null = null;

  constructor(private authStorage: AuthenticationStorageService,
              private monitoring: MonitoringService) {
    this.currentUser$.subscribe(user => this.monitoring.monitorUser(user));
  }

  getCurrentUser(): Observable<RuiterUserDto | null> {
    return this.currentUser$;
  }

  getCurrentUserInstant(): RuiterUserDto | null {
    return this.currentUser;
  }

  updateUser(updatedUser: RuiterUserDto): void {
    this.authStorage.updateUser(updatedUser);
  }
}
