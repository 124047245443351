import { PasswordField, PasswordFieldOptions, PasswordValidators } from '@antony/ng-forms';
import { DEFAULT_ERROR_MESSAGES } from '@app/default-error-messages';
import { AuthConstants } from '@core/authentication/models/auth-constants';

export class AppPasswordField extends PasswordField {
  constructor(options: PasswordFieldOptions = { }) {
    super({
      icon: 'AUTHENTICATION.PROPERTY.PASSWORD.ICON',
      label: 'AUTHENTICATION.PROPERTY.PASSWORD.LABEL',
      required: true,
      minLength: AuthConstants.passwordMinLength,
      maxLength: AuthConstants.passwordMaxLength,
      ...options,
      validators: [
        PasswordValidators.shouldContainerNumber,
        PasswordValidators.shouldContainLowercaseLetter,
        PasswordValidators.shouldContainUppercaseLetter,
        PasswordValidators.shouldContainSpecialCharacter,
        ...(options.validators || [])
      ],
      errorMessages: {
        ...DEFAULT_ERROR_MESSAGES,
        minlength: 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.MIN_LENGTH',
        maxlength: 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.MAX_LENGTH',
        shouldContainerNumber: 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.CONTAIN_NUMBER',
        shouldContainLowercaseLetter: 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.CONTAIN_LOWERCASE_LETTER',
        shouldContainUppercaseLetter: 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.CONTAIN_UPPERCASE_LETTER',
        shouldContainSpecialCharacter: 'AUTHENTICATION.PROPERTY.PASSWORD.ERRORS.CONTAIN_SPECIAL_CHARACTER',
        ...options.errorMessages
      }
    });
  }

}
