import { ConstructionProjectOffersApiService, OfferDto } from '@api/api.service';
import { AntCollectionSortLoadingParams, GetCollectionApiService } from '@api/models';
import { LoadingParamsUtils } from '@api/utils';
import { Injectable } from '@angular/core';
import { AntDataResponse } from '@antony/ng-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConstructionProjectOfferService implements GetCollectionApiService<OfferDto> {

  constructor(protected api: ConstructionProjectOffersApiService) {
  }

  getData(params: AntCollectionSortLoadingParams<OfferDto>): Observable<AntDataResponse<OfferDto>> {
    return this.api.getAllOffers(
      LoadingParamsUtils.getIdFrom(params),
      ...LoadingParamsUtils.transformToArray(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }
}
