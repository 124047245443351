import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { BaseReceiptStatusDto, ReceiptStatusApiService } from '@api';
import { Observable } from 'rxjs';

export class BaseReceiptStatusApiMockService<T extends BaseReceiptStatusDto>
  extends CrudBase<T> implements ReceiptStatusApiService<T> {

  constructor(items: T[]) {
    super(items);
  }

  getAllStatus(): Observable<GenericApiResponse<T[]>> {
    return this.getRange(0, 1000);
  }
}
