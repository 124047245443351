import { Provider } from '@angular/core';
import { ContactPersonConstructionProjectsApiService, ContactPersonCustomersApiService, ContactPersonsApiService } from '@api';
import { ContactPersonsApiMockService } from './contact-persons-api-mock.service';
import { ContactPersonConstructionProjectApiMockService } from './contact-person-construction-project-api-mock.service';
import { ContactPersonCustomerApiMockService } from './contact-person-customer-api-mock.service';

export const CONTACT_PERSON_PROVIDER: Provider[] = [
  { provide: ContactPersonsApiService, useClass: ContactPersonsApiMockService},
  { provide: ContactPersonConstructionProjectsApiService, useClass: ContactPersonConstructionProjectApiMockService},
  { provide: ContactPersonCustomersApiService, useClass: ContactPersonCustomerApiMockService}
];
