import { ViewAction, ViewActionConfig } from '@antony/ng-ui';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/authentication/services/authentication.service';

@Injectable()
export class LogoutViewAction extends ViewAction {
  readonly actionName: string = 'LOGOUT';

  constructor(private authService: AuthenticationService) {
    super();
  }

  onClick(): void {
    this.authService.logout().subscribe();
  }

  getConfig(): ViewActionConfig {
    return {
      ...super.getConfig(),
      prefix: 'AUTHENTICATION.ACTIONS.'
    };
  }

}
