import { Injectable } from '@angular/core';
import { GetDataApiService } from '@api/models';
import { OrderDto, OrderKindsApiService, OrderPositionDto, OrderVersionDto } from '@api/api.service';
import { ReceiptKindService } from '../base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AntDataResponse } from '@antony/ng-ui';
import { ApiResponseUtils } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class OrderKindService extends ReceiptKindService<OrderDto, OrderVersionDto, OrderPositionDto>
  implements GetDataApiService<string> {

  constructor(protected api: OrderKindsApiService) {
    super(api);
  }

  getTransferKinds(orderId: number): Observable<AntDataResponse<string>> {
    return this.api.getTransferKinds(orderId).pipe(
      map(ApiResponseUtils.getAntDataResponseFromResponse)
    );
  }
}
