import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { DeliveryNoteVersionDto, IDeliveryNoteVersionApiService } from '@api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DELIVERY_NOTE_VERSIONS } from '@app/mock/data/delivery_note_versions';

@Injectable()
export class DeliveryNoteVersionsApiMockService extends CrudBase<DeliveryNoteVersionDto> implements IDeliveryNoteVersionApiService {
  constructor() {
    super(DELIVERY_NOTE_VERSIONS);
  }

  getPagedReceiptVersions(receiptId: number, skip?: number, take?: number): Observable<GenericApiResponse<DeliveryNoteVersionDto[]>> {
    return this.getRange(skip, take);
  }
}
