import {
  CustomersApiService,
  CustomerDto,
  GetDataApiService,
  ApiResponseOfIEnumerableOfCustomerDto,
  LoadingParamsUtils,
} from '@api';
import { Injectable } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { AntSortLoadingParams, AntDataResponse } from '@antony/ng-ui';
import { switchMap, shareReplay, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InactiveCustomerService implements GetDataApiService<CustomerDto> {

  private filterSubject = new Subject<void>();

  // NOTE: default filter shows only active customers
  private showInactiveCustomers = false;

  constructor(private api: CustomersApiService) { }

  getData(params: Partial<AntSortLoadingParams<CustomerDto>>, showInactiveCustomers?: boolean): Observable<AntDataResponse<CustomerDto>> {
    if (showInactiveCustomers !== undefined) {
      this.showInactiveCustomers = showInactiveCustomers;
    }
    return merge(
      this.loadPagedCustomersByInactiveState(params),
      this.reloadInactivePagedCustomers(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0,
      }))
    );
  }

  isShowInactiveCustomers(): boolean {
    return this.showInactiveCustomers;
  }

  toggleVisibility() {
    this.showInactiveCustomers = !this.showInactiveCustomers;
    this.filterSubject.next();
    return this.showInactiveCustomers;
  }

  reset() {
    this.filterSubject.complete();
    this.filterSubject = new Subject<void>();
    this.showInactiveCustomers = false;
  }

  private reloadInactivePagedCustomers(params: Partial<AntSortLoadingParams<CustomerDto>>): Observable<ApiResponseOfIEnumerableOfCustomerDto> {
    return this.filterSubject.pipe(
      switchMap(() => this.loadPagedCustomersByInactiveState(params)),
      shareReplay(1),
    );
  }

  private loadPagedCustomersByInactiveState(
    params: Partial<AntSortLoadingParams<CustomerDto>>): Observable<ApiResponseOfIEnumerableOfCustomerDto> {
    return this.api.getPagedCustomersByInactiveState(
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params),
      this.showInactiveCustomers,
    );
  }
}
