import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@core/authentication/services/authentication.service';
import { catchError, finalize, first, flatMap, shareReplay, tap } from 'rxjs/operators';
import { catchStatusCode } from '@core/services/catch-operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private currentlyRenewing = false;
  private renewRequest: Observable<boolean> | null = null;
  private urlsToExclude: string[] = [
    'auth/login',
    'auth/renew',
    'auth/logout',
    'auth/activate',
    'auth/forgotPassword',
    'auth/resetPassword'
  ];

  constructor(private authService: AuthenticationService) { }

  /**
   * This function intercepts all request,
   * if a request gets a 401-error from the server,
   * this interceptor tries to renew the api token,
   * and then resend the original request,
   * with a new authentication header.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.setHeader(req)).pipe(
      catchStatusCode(401, (error: any, caught: Observable<HttpEvent<any>>) => {
        if (!this.shouldHandleRequest(req)) {
          throw error;
        }
        this.initRenewRequestIfNecessary();
        if (!!this.renewRequest) {
          return this.renewRequest.pipe(
            flatMap((success: boolean): Observable<any> => {
              return next.handle(this.setHeader(req));
            })
          );
        }
        throw error;
      })
    );
  }

  /**
   * this function sets the authentication header to request,
   * that are sending to the api, and are not targeting the
   * Auth controller
   */
  private setHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (!this.shouldHandleRequest(req)) {
      return req;
    }
    const authToken = this.authService.getApiToken();
    return req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken)
    });
  }

  private shouldHandleRequest<T>(req: HttpRequest<T>): boolean {
    return !req.url.includes('/api') || !this.urlsToExclude.some(url => req.url.includes(url));
  }

  private initRenewRequestIfNecessary(): void {
    if (this.currentlyRenewing && !!this.renewRequest) {
      return;
    }

    this.currentlyRenewing = true;
    this.renewRequest = this.authService.renew().pipe(
      first(),
      shareReplay(),
      finalize(() => {
        this.currentlyRenewing = false;
        this.renewRequest = null;
      })
    );
  }
}
