import { ApiSortDirection, ConstructionProjectDto, ICustomerConstructionProjectsApiService } from '@api';
import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { NEVER, Observable } from 'rxjs';
import { CONSTRUCTION_PROJECTS } from '@app/mock/data/construction-projects';

@Injectable()
export class CustomerConstructionProjectApiMockService extends CrudBase<ConstructionProjectDto>
  implements ICustomerConstructionProjectsApiService {

  constructor() {
    super(CONSTRUCTION_PROJECTS);
  }

  getPagedConstructionProjects(customerId: number, skip?: number, take?: number, filterName?: string[], filterValue?: string,
                               sortName?: string[], sortDirection?: ApiSortDirection)
    : Observable<GenericApiResponse<ConstructionProjectDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  createConstructionProject(customerId: number, constructionProjectId: number)
    : Observable<GenericApiResponse<ConstructionProjectDto>> {
    return NEVER;
  }

  deleteConstructionProject(customerId: number, constructionProjectId: number)
    : Observable<GenericApiResponse<ConstructionProjectDto>> {
    return NEVER;
  }

  deleteBulkConstructionProjects(customerId: number, constructionProjectId?: number[])
    : Observable<GenericApiResponse<GenericApiResponse<ConstructionProjectDto>[]>> {
    return NEVER;
  }


}
