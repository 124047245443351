import { Injectable } from '@angular/core';
import { DeleteBulkApiService, InvoicePaymentsApiService, InvoicePaymentCreateDto, InvoicePaymentDto } from '@api';
import { from, Observable, ObservableInput } from 'rxjs';
import { concatMap, map, switchMap, tap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoicePaymentService {
  constructor(private api: InvoicePaymentsApiService) {
  }

  loadPaymentInputsByInvoiceId(invoiceId: number): Observable<InvoicePaymentDto[]> {
    return this.api.loadByInvoiceId(invoiceId).pipe(
      map(response => response.data)
    );
  }

  createPaymentInput(invoicePaymentDto: InvoicePaymentCreateDto): Observable<boolean> {
    return this.api.createPayment(invoicePaymentDto).pipe(
      map(response => response.success),
    );
  }

  createPaymentInputs(invoicePaymentDto: InvoicePaymentCreateDto[]): Observable<boolean> {
    return this.api.createPayments(invoicePaymentDto).pipe(
      map(response => response.success)
    );
  }

  deleteMultiplePaymentInputsById(numbers: number[]): Observable<boolean[]> {
    return from(numbers).pipe(
      concatMap(id => this.api.delete(id)),
      toArray(),
      map((results): boolean[] => results.map(x => x.success)),
    );
  }
}
