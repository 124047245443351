import { NgModule } from '@angular/core';
import { environment } from '@env';
import { API_PROVIDER } from '@app/mock/api';


@NgModule({
  providers: environment.mock ? API_PROVIDER : []
})
export class MockModule {
}
