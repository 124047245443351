import { CrudBase } from '@app/mock/models';
import { ApiResponseOfIEnumerableOfOfferVersionDto, IOfferVersionsApiService, OfferVersionDto } from '@api';
import { Observable } from 'rxjs';
import { OFFER_VERSIONS } from '@app/mock/data/offer_versions';
import { Injectable } from '@angular/core';

@Injectable()
export class OfferVersionsApiMockService extends CrudBase<OfferVersionDto> implements IOfferVersionsApiService {
  constructor() {
    super(OFFER_VERSIONS);
  }

  getPagedReceiptVersions(OfferId: number, skip?: number, take?: number): Observable<ApiResponseOfIEnumerableOfOfferVersionDto> {
    return this.getRange(skip, take);
  }
}
