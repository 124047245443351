import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { ResourceApiService } from '@api/models';
import { ApiResponseUtils, LoadingParamsUtils } from '@api/utils';
import { VehicleDto, VehiclesApiService } from '@api/api.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService implements ResourceApiService<VehicleDto> {
  constructor(protected api: VehiclesApiService) {
  }

  getData(params: Partial<AntSortLoadingParams<VehicleDto>>): Observable<AntDataResponse<VehicleDto>> {
    return this.api.getPagedVehicles(
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(ApiResponseUtils.getAntDataResponseFromResponse)
    );
  }

  get(resourceId: number): Observable<VehicleDto> {
    return this.api.getVehicle(resourceId).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  create(dto: VehicleDto): Observable<VehicleDto> {
    return this.api.createVehicle(dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  update(resourceId: number, dto: VehicleDto): Observable<VehicleDto> {
    return this.api.updateVehicle(resourceId, dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  delete(resourceId: number): Observable<boolean> {
    return this.api.deleteVehicle(resourceId).pipe(
      map(ApiResponseUtils.getSuccessFromResponse)
    );
  }

  deleteBulk(resourceIds: number[]): Observable<boolean[]> {
    return this.api.deleteBulkVehicles(resourceIds).pipe(
      map(ApiResponseUtils.getSuccessesFromResponseOfResponse)
    );
  }
}
