import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationModule } from './authentication/authentication.module';
import { UnsavedChangesGuard } from './unsaved-changes/unsaved-changes.guard';
import { UnsavedChangesDialogComponent } from './unsaved-changes/unsaved-changes-dialog.component';
import { NavigationModule } from './navigation/navigation.module';
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';
import { environment } from '@env';
import { AppNotificationService } from '@core/services/app-notification.service';

export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandlerService();
  }
  return new ErrorHandler();
}

@NgModule({
    declarations: [
        UnsavedChangesDialogComponent
    ],
    providers: [
        UnsavedChangesGuard,
        AppNotificationService,
        {
            provide: ErrorHandler,
            useFactory: getErrorHandler
        }
    ],
    exports: [
        NavigationModule
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        TranslateModule.forChild(),
        AuthenticationModule,
        NavigationModule
    ]
})
export class CoreModule {
}
