import { Injectable } from '@angular/core';
import { AntCollectionSortLoadingParams, GetCollectionApiService } from '@api/models';
import { Observable } from 'rxjs';
import { AntDataResponse } from '@antony/ng-ui';
import { map } from 'rxjs/operators';
import { ConstructionProjectDto, CustomerConstructionProjectsApiService } from '@api/api.service';
import { LoadingParamsUtils } from '@api/utils';


@Injectable({
  providedIn: 'root'
})
export class CustomerConstructionProjectService implements GetCollectionApiService<ConstructionProjectDto> {

  constructor(private api: CustomerConstructionProjectsApiService) {
  }

  getData(params: AntCollectionSortLoadingParams<ConstructionProjectDto>): Observable<AntDataResponse<ConstructionProjectDto>> {
    return this.api.getPagedConstructionProjects(
      LoadingParamsUtils.getIdFrom(params),
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

}
