import { Injectable } from '@angular/core';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetDataApiService } from '@api/models';
import { CountriesApiService, CountryDto } from '@api/api.service';
import { LoadingParamsUtils } from '@api/utils';

@Injectable({
  providedIn: 'root'
})
export class CountryService implements GetDataApiService<CountryDto> {

  constructor(private api: CountriesApiService) {
  }

  getData(params: Partial<AntSortLoadingParams<CountryDto>>): Observable<AntDataResponse<CountryDto>> {
    return this.api.getPagedCountries(
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }
}
