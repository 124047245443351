import { ApiResponseOfIEnumerableOfCountryDto, ApiSortDirection, CountryDto, ICountriesApiService } from '@api';
import { Injectable } from '@angular/core';
import { CrudBase } from '@app/mock/models';
import { COUNTRIES } from '@app/mock/data/countries';
import { Observable } from 'rxjs';

@Injectable()
export class CountriesApiMockService extends CrudBase<CountryDto> implements ICountriesApiService {

  constructor() {
    super(COUNTRIES);
  }

  getPagedCountries(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                    sortName?: string[], sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfCountryDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

}
