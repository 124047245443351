import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { ApiSortDirection, DeliveryNoteAssignmentDto, IDeliveryNoteAssignmentApiService } from '@api';
import { from, Observable } from 'rxjs';
import { DELIVERY_NOTE_ASSIGNMENTS } from '@app/mock/data/delivery-note-assignments';
import { filter, map, switchMap, toArray } from 'rxjs/operators';

@Injectable()
export class DeliveryNoteAssignmentsApiMockService extends CrudBase<DeliveryNoteAssignmentDto> implements IDeliveryNoteAssignmentApiService {

  constructor() {
    super(DELIVERY_NOTE_ASSIGNMENTS);
  }

  getPagedDeliveryNoteAssignments(skip?: number, take?: number, filterName?: string[], filterValue?: string, sortName?: string[], sortDirection?: ApiSortDirection): Observable<GenericApiResponse<DeliveryNoteAssignmentDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getDeliveryNoteAssignment(id: number): Observable<GenericApiResponse<DeliveryNoteAssignmentDto>> {
    return this.get(id);
  }

  getAllByVehicleAndDate(vehicleId?: number | undefined, date?: string | undefined): Observable<GenericApiResponse<DeliveryNoteAssignmentDto[]>> {
    return this.getRange(0, 10000).pipe(
      map(res => res.data ?? []),
      switchMap(items => from(items)),
      filter(item => item.vehicleId === vehicleId),
      filter(item => item.dispositionDate === date),
      toArray(),
      map(items => ({
        success: true,
        message: null,
        data: items,
        count: items.length,
        maxCount: items.length
      }))
    );
  }

  createAssignment(dto: DeliveryNoteAssignmentDto): Observable<GenericApiResponse<DeliveryNoteAssignmentDto>> {
    return this.create(dto);
  }

  updateAssignment(id: number, dto: DeliveryNoteAssignmentDto): Observable<GenericApiResponse<DeliveryNoteAssignmentDto>> {
    return this.update(id, dto);
  }

  deleteAssignment(id: number): Observable<GenericApiResponse<DeliveryNoteAssignmentDto>> {
    return this.delete(id);
  }

  deleteBulkAssignments(id: number[]): Observable<GenericApiResponse<GenericApiResponse<DeliveryNoteAssignmentDto>[]>> {
    return this.deleteBulk(id ?? []);
  }

}
