import { Injectable } from '@angular/core';
import { BreadcrumbService } from './breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TitleService {

  private defaultTitle = 'APP.TITLE';
  private lastTitle = this.defaultTitle;

  constructor(private breadcrumbs: BreadcrumbService,
              private translate: TranslateService,
              private metaTitle: Title) {

  }

  setDefaultTitle(defaultTitle: string): void {
    this.defaultTitle = defaultTitle;
  }

  getTitleWithUpdate(): string {
    const title = this.getTitle();
    if (title !== this.lastTitle) {
      this.lastTitle = title;
      this.updateMetaTitle(title);
    }
    return title;
  }

  getTitle(): string {
    const topBreadcrumb = this.breadcrumbs.getTopBreadcrumb();
    if (!topBreadcrumb) {
      return this.defaultTitle;
    }
    return topBreadcrumb.label;
  }

  private updateMetaTitle(title: string): void {
    const translatedTitle = this.translate.instant(title);
    if (typeof translatedTitle === 'string') {
      this.metaTitle.setTitle(translatedTitle);
    }
  }
}
