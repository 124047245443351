import { FormDialogService } from '@shared/components/form-dialog/form-dialog.component';
import { AuthApiService, ChangePasswordDto } from '@api';
import { ChangePasswordForm } from '@core/authentication/models/change-password.form';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordFormDialogAdapter implements FormDialogService<ChangePasswordDto> {

  constructor(private api: AuthApiService) {
  }

  getForm(value?: ChangePasswordDto): Observable<ChangePasswordForm> {
    const form = new ChangePasswordForm();
    if (value) {
      form.patchValue(value);
    }
    return of(form);
  }

  submit(form: ChangePasswordForm): Observable<ChangePasswordDto | null> {
    const formData = form.value;
    if (!formData) {
      return of(null);
    }
    const response$ = this.sendDataToApi(formData);
    return response$.pipe(
      catchError(err => this.handleErrors(err)),
      mapTo(formData)
    );
  }

  private sendDataToApi(value: ChangePasswordDto): Observable<boolean> {
    return this.api.changePassword({
      newPassword: value.newPassword,
      oldPassword: value.oldPassword
    }).pipe(
      map(res => res.success)
    );
  }

  private handleErrors(err: any): Observable<boolean> {
    if (!err?.data) {
      throw err;
    }
    const fieldKeys = this.getFieldKeysFromErrorData(err.data);
    const fieldErrors: any = { };
    for (const fieldKey of fieldKeys) {
      fieldErrors[fieldKey] = this.getErrorByFieldKey(fieldKey, err.data[fieldKey]);
    }
    throw fieldErrors;
  }

  private getFieldKeysFromErrorData(data: any): (keyof ChangePasswordDto)[] {
    const originalKeys = Object.keys(data);
    const lowercaseKeys = originalKeys.map(this.transformUpperCamelCaseToCamelCase);
    return lowercaseKeys as (keyof ChangePasswordDto)[];
  }

  private transformUpperCamelCaseToCamelCase(target: string): string {
    const firstLetter = target.slice(0, 1).toLowerCase();
    return firstLetter + target.slice(1);
  }

  private getErrorByFieldKey(key: keyof ChangePasswordDto, data: any): any {
    switch (key) {
      case 'oldPassword':
        return {
          wrongPassword: true
        };
      case 'newPassword':
        return {
          notEqualValue: true
        };
      default:
        return null;
    }
  }
}
