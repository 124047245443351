import { Provider } from '@angular/core';
import {
  CustomerConstructionProjectsApiService,
  CustomerContactPersonsApiService,
  CustomersApiService,
  CustomerSubCustomersApiService
} from '@api';
import { CustomersApiMockService } from './customers-api-mock.service';
import { CustomerConstructionProjectApiMockService } from './customer-construction-project-api-mock.service';
import { CustomerContactPersonApiMockService } from './customer-contact-person-api-mock.service';
import { CustomerSubCustomerApiMockService } from './customer-sub-customer-api-mock.service';

export const CUSTOMER_PROVIDER: Provider[] = [
  { provide: CustomersApiService, useClass: CustomersApiMockService},
  { provide: CustomerConstructionProjectsApiService, useClass: CustomerConstructionProjectApiMockService},
  { provide: CustomerContactPersonsApiService, useClass: CustomerContactPersonApiMockService},
  { provide: CustomerSubCustomersApiService, useClass: CustomerSubCustomerApiMockService}
];
