import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import {
  ANT_UI_CONFIG,
  AntUiConfig,
  AntUiModule,
  AntViewActionModule
} from '@antony/ng-ui';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AntFormsModule } from '@antony/ng-forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PermissionsModule } from '@shared/permissions/permissions.module';
import { MarkdownModule } from 'ngx-markdown';
import { ErrorComponent } from './components/error/error.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MarkdownDialogComponent } from './components/markdown-dialog/markdown-dialog.component';
import { SelectDialogComponent } from './components/select-dialog/select-dialog.component';
import { MaterialModule } from './material.module';
import { PIPES } from './pipes';
import { ExportWidgetComponent } from './widgets/export-widget/export-widget.component';
import { NoPermissionsComponent } from './components/no-permissions/no-permissions.component';


export function getAntUiConfig(ts: TranslateService): AntUiConfig {
  return {
    actions: {
      filter: {
        label: ts.instant('APP.ACTIONS.FILTER.LABEL'),
        icon: ts.instant('APP.ACTIONS.FILTER.ICON')
      },
      showLess: {
        label: ts.instant('APP.ACTIONS.SHOW_LESS.LABEL'),
        icon: ts.instant('APP.ACTIONS.SHOW_LESS.ICON')
      },
      showMore: {
        label: ts.instant('APP.ACTIONS.SHOW_MORE.LABEL'),
        icon: ts.instant('APP.ACTIONS.SHOW_MORE.ICON')
      },
      detailViewActions: {
        maxCount: 2
      },
      tableViewActions: {
        maxCount: 2
      }
    },
    labels: {
      displayedColumns: ts.instant('APP.LIST.DISPLAYED_COLUMNS'),
      noResults: ts.instant('APP.LIST.NO_RESULTS'),
      selectColumn: ts.instant('APP.LIST.SELECT')
    },
    defaultTableConfig: {
      viewActions: {
        maxViewActions: 3,
        minMenuViewActions: 2
      }
    },
    defaultDetailViewActionConfig: {
      maxViewActions: 3,
      minMenuViewActions: 2
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild(),
    AntFormsModule,
    AntUiModule,
    PermissionsModule,
    AntViewActionModule,
    MarkdownModule.forRoot(),
  ],
  declarations: [
    NotFoundComponent,
    NoPermissionsComponent,
    FormDialogComponent,
    SimpleDialogComponent,
    ...PIPES,
    SelectDialogComponent,
    LoadingComponent,
    ErrorComponent,
    ExportWidgetComponent,
    MarkdownDialogComponent,
  ],
  providers: [
    { provide: ANT_UI_CONFIG, useFactory: getAntUiConfig, deps: [TranslateService] },
    ...PIPES
  ],
  exports: [
    MaterialModule,
    TranslateModule,
    HttpClientModule,
    ReactiveFormsModule,
    AntUiModule,
    AntFormsModule,
    NotFoundComponent,
    ...PIPES,
    LoadingComponent,
    ErrorComponent,
    ExportWidgetComponent
  ]
})
export class SharedModule {
}
