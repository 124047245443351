import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { Salutation } from '@api';
import { Person } from '@shared/models/person';
import { ContactPersonConstants } from '@app/contact-person/models/contact-person-constants';
import { PhoneNumberUtils } from '@shared/utils';

@Pipe({
  name: 'displayName'
})
export class DisplayNamePipe implements PipeTransform {

  public static getFirstAndLastname(person: Person): string {
    const firstname = person.firstname || '';
    const lastname = person.lastname || '';
    if (!firstname && !lastname) {
      return '';
    }

    const salutation = person.salutation ?? Salutation.None;
    return !!firstname ? (firstname + ' ' + lastname).trim() : (DisplayNamePipe.getSalutation(salutation) + ' ' + lastname)?.trim();
  }

  public static getCompanyName(person: Person): string {
    const company = person.company || '';
    return company.trim();
  }

  public static getName(person: Person): string {
    switch (person.salutation) {
      case Salutation.Company:
        return DisplayNamePipe.getCompanyName(person);
      case Salutation.None:
      case Salutation.Mr:
      case Salutation.Mrs:
      default:
        return DisplayNamePipe.getFirstAndLastname(person);
    }
  }

  public static getSalutation(salutation: Salutation): string {
    switch (salutation)
    {
      case Salutation.None:
        return ContactPersonConstants.translationSalutationNone;
      case Salutation.Mr:
        return ContactPersonConstants.translationSalutationMr;
      case Salutation.Mrs:
        return ContactPersonConstants.translationSalutationMrs;
      case Salutation.Company:
        return ContactPersonConstants.translationSalutationCompany;
      default:
        return ContactPersonConstants.translationSalutationNone;
    }
  }

  public static getPersonWithSubPersons(person: Person, subPersons: Person[]): string {
    const entries = [];
    entries.push(person.id);
    entries.push(DisplayNamePipe.getName(person));
    if (person.contactOptions?.phone) {
      entries.push(person.contactOptions?.phone);
    }
    if (subPersons.length > 0) {
      entries.push(`(${ subPersons.map(subPerson => DisplayNamePipe.getName(subPerson)).join(', ') })`);
    }
    return entries.join(', ');
  }

  transform(value: Person | null | undefined, args?: any): string | null {
    if (!value) {
      return null;
    }
    return DisplayNamePipe.getName(value);
  }
}
