import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettingsDto, ApplicationSettingsService } from '@api';
import { SettingsProvider } from './settings.provider';

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsProvider extends SettingsProvider<ApplicationSettingsDto> {

  constructor(protected readonly api: ApplicationSettingsService) {
    super();
  }

  protected loadSettings(): Observable<ApplicationSettingsDto> {
    return this.api.getSettings();
  }

  protected updateSettings(settings: ApplicationSettingsDto): Observable<ApplicationSettingsDto> {
    return this.api.updateSettings(settings);
  }
}
