import { Observable, Subject } from 'rxjs';
import { finalize, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

export abstract class SettingsProvider<T> {

  private readonly reload$ = new Subject();
  private readonly settings$: Observable<T>;
  private settings: T | null = null;

  protected abstract loadSettings(): Observable<T>;
  protected abstract updateSettings(settings: T): Observable<T>;

  protected constructor() {
    this.settings$ = this.createSettingsObservable();
  }

  private createSettingsObservable(): Observable<T> {
    return this.reload$.pipe(
      startWith(null),
      switchMap(() => this.loadSettings()),
      tap(settings => this.settings = settings),
      shareReplay(1)
    );
  }

  get(): Observable<T> {
    return this.settings$;
  }

  getInstant(): T | null {
    return this.settings;
  }

  update(value: T): Observable<T> {
    return this.updateSettings(value).pipe(
      finalize(() => this.reload())
    );
  }

  reload(): void {
    this.reload$.next();
  }
}
