import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { HasPermissionService } from '@shared/permissions/services/has-permission.service';
import {
  combineLatest,
  Observable,
  Subject
} from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
  private hasView = false;

  private readonly currentPermissions$ = new Subject<(string | null | undefined)[]>();
  private readonly hasPermission$: Observable<boolean>;

  constructor(private permissionsService: HasPermissionService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
    this.viewContainer.clear();
    this.hasPermission$ = this.currentPermissions$.pipe(
      switchMap((permissions: (string | null | undefined)[]): Observable<boolean> => {
        if (Array.isArray(permissions)) {
          const permissionObservables = permissions.map(nextPermission => this.permissionsService.hasPermission(nextPermission));
          return combineLatest(permissionObservables).pipe(
            map(results => results.every(result => result))
          );
        }
        return this.permissionsService.hasPermission(permissions);
      }),
      startWith(false),
      distinctUntilChanged(),
      shareReplay()
    );

    this.toggleView();
  }

  @Input()
  set appHasPermission(permissions: string | null | undefined | string[]) {
    const allPermissions = [];
    if (!Array.isArray(permissions)) {
      allPermissions.push(permissions);
    }
    if (Array.isArray(permissions)) {
      allPermissions.push(...permissions);
    }
    this.currentPermissions$.next(allPermissions);
  }

  private toggleView() {
    this.hasPermission$.subscribe(hasPerm => {
      if (hasPerm && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!hasPerm && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }
}
