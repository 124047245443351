import { CrudBase, GenericApiResponse } from '@app/mock/models';
import {
  ApiResponseOfDownloadReportDto,
  ApiResponseOfIEnumerableOfInvoiceDto,
  ApiResponseOfInvoiceDto,
  ApiResponseOfListOfOrderVersionDto,
  ApiResponseOfOrderPositionDto,
  ApiResponseOfTransferOrderDto,
  ApiSortDirection,
  DefaultPositionCalculationDto,
  FileResponse,
  IOrdersApiService,
  OfferVersionDto,
  OrderDto,
  OrderVersionDto,
  ReceiptStatusUpdateDto,
  TransferOrderDto
} from '@api';
import { ORDERS } from '@app/mock/data/orders';
import { NEVER, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class OrdersApiMockService extends CrudBase<OrderDto> implements IOrdersApiService {
  constructor() {
    super(ORDERS);
  }

  updateStatus(updateDto: ReceiptStatusUpdateDto): Observable<ApiResponseOfListOfOrderVersionDto> {
    return NEVER;
  }

  getPagedReceipts(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                   sortName?: string[], sortDirection?: ApiSortDirection): Observable<GenericApiResponse<OrderDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getReceipt(id: number): Observable<GenericApiResponse<OrderDto>> {
    return this.get(id);
  }

  updateReceipt(offerId: number, dto: OfferVersionDto): Observable<GenericApiResponse<OrderVersionDto>> {
    return NEVER;
  }

  downloadDeliveryNote(token?: string): Observable<FileResponse> {
    return NEVER;
  }

  requestReportDownload(orderId: number): Observable<ApiResponseOfDownloadReportDto> {
    return NEVER;
  }

  downloadReport(token?: string | null | undefined): Observable<FileResponse> {
    return NEVER;
  }

  getTransferredInvoices(orderId: number, skip?: number, take?: number, filterName?: string[], filterValue?: string,
                         sortName?: string[], sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfInvoiceDto> {
    return NEVER;
  }

  requestDeliveryNoteDownload(invoiceId: number): Observable<ApiResponseOfDownloadReportDto> {
    return NEVER;
  }

  transferReceipt(orderId: number): Observable<ApiResponseOfInvoiceDto> {
    return NEVER;
  }

  getTransferData(orderId: number): Observable<ApiResponseOfTransferOrderDto> {
    return this.get(orderId).pipe(
      map(res => res.data),
      map((order: OrderDto): TransferOrderDto => ({
        kind: order.latestVersion?.kind ?? '',
        deliveryNoteIds: [],
        rentalData: null,
        pickupData: null,
        invoiceDate: (new Date()).toISOString(),
      })),
      map(d => this.createApiResponse(d))
    );
  }

  calculatePositionDefaults(receiptId: number, dto: DefaultPositionCalculationDto): Observable<ApiResponseOfOrderPositionDto> {
    return NEVER;
  }
}
