import { HighPriorityNotification, LowPriorityNotification, MediumPriorityNotification, NotificationService } from '@antony/core';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  TranslatableHighPrioNotificationOptions,
  TranslatableLowPrioNotificationOptions,
  TranslatableMidPrioNotificationOptions
} from '@app/core';

@Injectable()
export class AppNotificationService {
  constructor(protected parent: NotificationService,
              protected translateService: TranslateService) {
  }

  openLowPriority(options: TranslatableLowPrioNotificationOptions): LowPriorityNotification {
    return this.parent.openLowPriority({
      ...options,
      message: this.translate(options.message, options.messageData),
      submitActionLabel: !!options.submitActionLabel ?
        this.translate(options.submitActionLabel, options.submitActionLabelData) : undefined
    });
  }

  openMediumPriority(options: TranslatableMidPrioNotificationOptions): MediumPriorityNotification {
    return this.parent.openMediumPriority({
      ...options,
      icon: !!options.icon ?
        this.translate(options.icon, options.iconData) : undefined,
      message: this.translate(options.message, options.messageData),
      submitActionLabel: this.translate(options.submitActionLabel, options.submitActionLabelData),
      dismissActionLabel: !!options.dismissActionLabel ?
        this.translate(options.dismissActionLabel, options.dismissActionLabelData) : undefined
    });
  }

  openHighPriority(options: TranslatableHighPrioNotificationOptions): HighPriorityNotification {
    return this.parent.openHighPriority({
      ...options,
      title: this.translate(options.title, options.titleData),
      message: this.translate(options.message, options.messageData),
      submitActionLabel: this.translate(options.submitActionLabel, options.submitActionLabelData),
      dismissActionLabel: this.translate(options.dismissActionLabel, options.dismissActionLabelData)
    });
  }

  protected translate(target: string, data?: any): string {
    if (!target) {
      return target;
    }
    return this.translateService.instant(target, data);
  }
}
