import {
  ApiSortDirection,
  ConstructionProjectContactPersonDto,
  ConstructionProjectDto,
  IContactPersonConstructionProjectsApiService
} from '@api';
import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { NEVER, Observable } from 'rxjs';
import { CONSTRUCTION_PROJECTS } from '@app/mock/data/construction-projects';

@Injectable()
export class ContactPersonConstructionProjectApiMockService extends CrudBase<ConstructionProjectDto>
  implements IContactPersonConstructionProjectsApiService {

  constructor() {
    super(CONSTRUCTION_PROJECTS);
  }

  getPagedConstructionProjects(contactPersonId: number, skip?: number, take?: number, filterName?: string[], filterValue?: string,
                               sortName?: string[], sortDirection?: ApiSortDirection)
    : Observable<GenericApiResponse<ConstructionProjectDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  createConstructionProjects(contactPersonId: number, constructionProjectId: number)
    : Observable<GenericApiResponse<ConstructionProjectContactPersonDto>> {
    return NEVER;
  }

  deleteConstructionProject(contactPersonId: number, constructionProjectId: number)
    : Observable<GenericApiResponse<ConstructionProjectContactPersonDto>> {
    return NEVER;
  }

  deleteBulkConstructionProjects(contactPersonId: number, constructionProjectId?: number[])
    : Observable<GenericApiResponse<GenericApiResponse<ConstructionProjectContactPersonDto>[]>> {
    return NEVER;
  }


}
