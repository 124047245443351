<h2 matDialogTitle>{{ (config?.title || '') | translate }}</h2>
<mat-dialog-content *ngIf="!form">
  <mat-progress-spinner></mat-progress-spinner>
</mat-dialog-content>
<ng-container *ngIf="!!form">
  <mat-dialog-content>
    <ant-form #formComp [form]="form" (submitted)="submit()"></ant-form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ant-button (click)="abort()" actionName="ABORT"></ant-button>
    <ant-button mode="raised"
                color="primary"
                (click)="formComp?.submit()"
                [disabled]="form.invalid"
                actionName="SELECT"
    ></ant-button>
  </mat-dialog-actions>
</ng-container>
