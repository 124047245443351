import {
  ApiResponseOfCustomerDto,
  ApiResponseOfIEnumerableOfCustomerDto,
  ApiResponseOfListOfApiResponseOfCustomerDto,
  ApiSortDirection,
  CustomerDto,
  ICustomersApiService,
} from '@api';
import { Injectable } from '@angular/core';
import { CrudBase } from '@app/mock/models';
import { CUSTOMERS } from '@app/mock/data/customers';
import { Observable } from 'rxjs';

@Injectable()
export class CustomersApiMockService extends CrudBase<CustomerDto> implements ICustomersApiService {

  constructor() {
    super(CUSTOMERS);
  }

  getPagedCustomers(skip?: number, take?: number, filterName?: string[], filterValue?: string,
    sortName?: string[], sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfCustomerDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getPagedCustomersByInactiveState(skip?: number,
    take?: number,
    filterName?: string[] | null,
    filterValue?: string | null,
    sortName?: string[] | null,
    sortDirection?: ApiSortDirection,
    isInActive?: boolean | null): Observable<ApiResponseOfIEnumerableOfCustomerDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getCustomer(id: number): Observable<ApiResponseOfCustomerDto> {
    return this.get(id);
  }

  createCustomer(dto: CustomerDto): Observable<ApiResponseOfCustomerDto> {
    return this.create(dto);
  }

  updateCustomer(id: number, dto: CustomerDto): Observable<ApiResponseOfCustomerDto> {
    return this.update(id, dto);
  }

  deleteCustomer(id: number): Observable<ApiResponseOfCustomerDto> {
    return this.delete(id);
  }

  deleteBulkCustomers(ids?: number[]): Observable<ApiResponseOfListOfApiResponseOfCustomerDto> {
    return this.deleteBulk(ids || []);
  }

}
