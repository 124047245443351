import { CrudBase } from '@app/mock/models';
import {
  ApiResponseOfIEnumerableOfVehicleDto,
  ApiResponseOfListOfApiResponseOfVehicleDto,
  ApiResponseOfVehicleDto,
  ApiSortDirection,
  IVehiclesApiService,
  VehicleDto
} from '@api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VEHICLES } from '@app/mock/data/vehicles';

@Injectable()
export class VehicleApiMockService extends CrudBase<VehicleDto> implements IVehiclesApiService {
  constructor() {
    super(VEHICLES);
  }

  getPagedVehicles(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                   sortName?: string[], sortDirection?: ApiSortDirection)
    : Observable<ApiResponseOfIEnumerableOfVehicleDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getVehicle(id: number): Observable<ApiResponseOfVehicleDto> {
    return this.get(id);
  }

  createVehicle(dto: VehicleDto): Observable<ApiResponseOfVehicleDto> {
    return this.create(dto);
  }

  updateVehicle(id: number, dto: VehicleDto): Observable<ApiResponseOfVehicleDto> {
    return this.update(id, dto);
  }

  deleteVehicle(id: number): Observable<ApiResponseOfVehicleDto> {
    return this.delete(id);
  }

  deleteBulkVehicles(id?: number[]): Observable<ApiResponseOfListOfApiResponseOfVehicleDto> {
    return this.deleteBulk(id ?? []);
  }


}
