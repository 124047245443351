<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav mat-elevation-z2" mode="over">
    <app-menu (navigated)="drawer.close()"></app-menu>
  </mat-sidenav>
  <mat-sidenav-content id="content">
    <header class="header">
      <ant-environment-indicator
        *ngIf="environment.name !== 'Production'"
        [environmentName]="environment.name | titlecase"
        [message]="'APP.ENVIRONMENT.NO_CUSTOMER_DATA' | translate"
      ></ant-environment-indicator>
      <ng-container *ngIf="isAuthenticated">
        <mat-toolbar color="primary">
          <mat-icon (click)="drawer.toggle()">menu</mat-icon>
          <h1 class="title">{{ getTitle() | translate }}</h1>
          <span class="spacer"></span>
          <ant-button [hideLabel]="true" (click)="showProfile = !showProfile" actionName="SHOW_PROFILE"></ant-button>
        </mat-toolbar>
        <app-breadcrumbs></app-breadcrumbs>


        <div class="overlay" *ngIf="showProfile" (click)="showProfile = false"></div>
        <app-profile *ngIf="showProfile" (closed)="showProfile = false"></app-profile>
      </ng-container>
    </header>
    <div class="content">
      <div class="content-inner">
        <ng-content></ng-content>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
