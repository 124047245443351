import { ArticleDto, Material } from '@api';
import { ARTICLE_PRICES } from '@app/mock/data/article-prices';
import { VEHICLE_TYPES } from './vehicle-types';
import { AppConstants } from '@app/app-constants';

const baseArticle: ArticleDto = {
  id: 0,
  position: null,
  name: '',
  abbreviation: '',
  created: '2019-12-17T01:22:04Z',
  lastChange: '2016-12-31T09:18:33Z',
  description: '',
  length: 0,
  width: 0,
  strength: 0,
  ean: '',
  graduatedPrices: [],
  antiTheftPrice: {
    deductibleRatePercent: 0,
    minimumDeductible: 0,
  },
  revenueAccount: {
    cleaningAccount: AppConstants.defaultRevenueAccount,
    deliveryAccount: AppConstants.defaultRevenueAccount,
    departureAccount: AppConstants.defaultRevenueAccount,
    moveAccount: AppConstants.defaultRevenueAccount,
    otherAccount: AppConstants.defaultRevenueAccount,
    rentAccount: AppConstants.defaultRevenueAccount,
    rollingAccount: AppConstants.defaultRevenueAccount,
    theftProtectionAccount: AppConstants.defaultRevenueAccount
  },
  price: ARTICLE_PRICES[0],
  material: Material.Steel,
  deliveryVehicleType: VEHICLE_TYPES[0],
  deliveryVehicleTypeId: VEHICLE_TYPES[0].id || 0,
  loadingVehicleType: VEHICLE_TYPES[0],
  loadingVehicleTypeId: VEHICLE_TYPES[0].id || 0
};

// tslint:disable:max-line-length
export const ARTICLES: ArticleDto[] = [
  {
    ...baseArticle,
    id: 50,
    created: '2019-12-17T01:22:04Z',
    lastChange: '2016-12-31T09:18:33Z',
    description: 'Et nostrud irure ipsum aute nostrud.',
    name: 'mollit sit',
    length: 29.0585,
    width: 9.7767,
    strength: 73.0397,
    ean: '7330469215259'
  },
  {
    ...baseArticle,
    id: 1,
    created: '2018-08-01T07:44:57Z',
    lastChange: '2011-01-30T09:33:10Z',
    description: 'Incididunt incididunt tempor voluptate in dolore Lorem commodo commodo.',
    name: 'enim qui',
    length: 93.036,
    width: 0.8403,
    strength: 85.6193,
    ean: '2029102462274'
  },
  {
    ...baseArticle,
    id: 2,
    created: '2000-11-29T06:22:27Z',
    lastChange: '2014-09-09T13:05:58Z',
    description: 'Incididunt et incididunt consequat eu labore ut amet.',
    name: 'nulla id',
    length: 49.4212,
    width: 52.4343,
    strength: 97.3136,
    ean: '5998744619564'
  },
  {
    ...baseArticle,
    id: 3,
    created: '2004-07-10T17:04:39Z',
    lastChange: '2001-02-03T20:51:57Z',
    description: 'Sunt magna labore pariatur sint ex velit minim aliquip aute exercitation esse fugiat commodo elit.',
    name: 'ad aute',
    length: 11.4918,
    width: 21.0332,
    strength: 46.5398,
    ean: '7454913059345'
  },
  {
    ...baseArticle,
    id: 4,
    created: '2011-04-09T07:11:09Z',
    lastChange: '2008-11-27T06:18:27Z',
    description: 'Fugiat et laboris laboris culpa dolore nostrud in anim officia minim pariatur incididunt.',
    name: 'in cillum',
    length: 27.4035,
    width: 10.5741,
    strength: 73.7828,
    ean: '6746200591792'
  },
  {
    ...baseArticle,
    id: 5,
    created: '2001-12-14T07:09:17Z',
    lastChange: '2008-07-11T09:28:43Z',
    description: 'Sint deserunt officia qui adipisicing excepteur ut voluptate pariatur sint cupidatat cillum.',
    name: 'amet occaecat',
    length: 16.2791,
    width: 38.5364,
    strength: 18.4361,
    ean: '1884992056920'
  },
  {
    ...baseArticle,
    id: 6,
    created: '2001-07-31T13:16:42Z',
    lastChange: '2003-09-17T16:24:09Z',
    description: 'Duis sint nulla anim laborum eu duis.',
    name: 'quis consectetur',
    length: 88.8867,
    width: 49.3161,
    strength: 45.8807,
    ean: '8996709394156'
  },
  {
    ...baseArticle,
    id: 7,
    created: '2019-01-21T17:03:59Z',
    lastChange: '2014-03-03T07:12:22Z',
    description: 'Enim non sunt eu tempor eiusmod eu sint.',
    name: 'nostrud esse',
    length: 14.4157,
    width: 81.5268,
    strength: 55.2663,
    ean: '9127279924608'
  },
  {
    ...baseArticle,
    id: 8,
    created: '2001-08-25T06:07:22Z',
    lastChange: '2017-11-04T18:27:48Z',
    description: 'Et elit ea incididunt do dolor commodo sunt nisi laborum est aute elit ut.',
    name: 'irure in',
    length: 20.1296,
    width: 5.9547,
    strength: 33.8316,
    ean: '7542689261607'
  },
  {
    ...baseArticle,
    id: 9,
    created: '2003-12-14T06:45:00Z',
    lastChange: '2005-02-18T06:01:11Z',
    description: 'Quis laborum non ut nulla.',
    name: 'proident ad',
    length: 7.8081,
    width: 93.0137,
    strength: 30.2982,
    ean: '7331890679690'
  },
  {
    ...baseArticle,
    id: 10,
    created: '2019-04-13T16:43:10Z',
    lastChange: '2001-07-14T21:24:14Z',
    description: 'Adipisicing minim dolor cillum velit anim eu elit duis voluptate consectetur.',
    name: 'sint ex',
    length: 44.8934,
    width: 35.913,
    strength: 30.0136,
    ean: '7422031160156'
  },
  {
    ...baseArticle,
    id: 11,
    created: '2001-12-11T23:21:19Z',
    lastChange: '2004-07-15T05:16:19Z',
    description: 'Elit qui eu ad nisi ullamco.',
    name: 'id eu',
    length: 88.9505,
    width: 87.7089,
    strength: 70.2574,
    ean: '1537790189747'
  },
  {
    ...baseArticle,
    id: 12,
    created: '2019-09-11T20:45:53Z',
    lastChange: '2009-03-06T20:18:13Z',
    description: 'Nostrud ut quis aliqua magna occaecat tempor veniam amet.',
    name: 'cillum voluptate',
    length: 1.9929,
    width: 0.0352,
    strength: 44.515,
    ean: '8173541119538'
  },
  {
    ...baseArticle,
    id: 13,
    created: '2015-10-06T09:06:17Z',
    lastChange: '2011-06-03T20:56:37Z',
    description: 'Nisi minim commodo laborum eiusmod consequat irure eu deserunt non culpa aute esse incididunt.',
    name: 'adipisicing ex',
    length: 47.325,
    width: 29.1124,
    strength: 27.2915,
    ean: '1010459279553'
  },
  {
    ...baseArticle,
    id: 14,
    created: '2004-05-29T09:18:31Z',
    lastChange: '2018-04-15T15:38:53Z',
    description: 'Est excepteur voluptate do laborum mollit laboris.',
    name: 'quis dolore',
    length: 70.612,
    width: 6.3595,
    strength: 59.6628,
    ean: '1259398271933'
  },
  {
    ...baseArticle,
    id: 15,
    created: '2010-01-31T00:55:54Z',
    lastChange: '2019-11-11T21:19:14Z',
    description: 'Consectetur consequat pariatur incididunt ullamco fugiat sit anim sit excepteur adipisicing veniam ipsum.',
    name: 'sint laboris',
    length: 91.5185,
    width: 50.8875,
    strength: 94.71,
    ean: '4582182600494'
  },
  {
    ...baseArticle,
    id: 16,
    created: '2004-09-09T01:35:03Z',
    lastChange: '2007-11-07T04:15:05Z',
    description: 'Dolor fugiat velit sit do magna elit eu commodo esse sint dolore.',
    name: 'irure id',
    length: 23.6184,
    width: 54.9942,
    strength: 78.8299,
    ean: '3701713489737'
  },
  {
    ...baseArticle,
    id: 17,
    created: '2007-01-28T07:40:02Z',
    lastChange: '2009-01-08T22:28:44Z',
    description: 'Labore voluptate fugiat nulla in.',
    name: 'anim amet',
    length: 8.4672,
    width: 16.9091,
    strength: 16.1723,
    ean: '6671700364154'
  },
  {
    ...baseArticle,
    id: 18,
    created: '2013-01-20T15:36:02Z',
    lastChange: '2011-08-23T05:55:32Z',
    description: 'Duis id consectetur labore excepteur.',
    name: 'commodo magna',
    length: 4.5729,
    width: 98.0334,
    strength: 17.6341,
    ean: '3954977004617'
  },
  {
    ...baseArticle,
    id: 19,
    created: '2006-11-23T10:05:43Z',
    lastChange: '2017-05-19T04:22:57Z',
    description: 'Commodo labore commodo culpa Lorem velit.',
    name: 'sit incididunt',
    length: 73.6284,
    width: 16.7393,
    strength: 34.9058,
    ean: '8241192978996'
  },
  {
    ...baseArticle,
    id: 20,
    created: '2001-09-24T17:03:50Z',
    lastChange: '2016-02-10T03:30:25Z',
    description: 'Nisi commodo eu do laborum reprehenderit velit reprehenderit aute do qui irure laboris.',
    name: 'minim consequat',
    length: 79.8355,
    width: 20.7704,
    strength: 30.2732,
    ean: '5525069520260'
  },
  {
    ...baseArticle,
    id: 21,
    created: '2008-04-22T09:42:06Z',
    lastChange: '2000-02-21T00:41:30Z',
    description: 'Consectetur consectetur ullamco non officia duis aute id et nulla fugiat consectetur.',
    name: 'qui voluptate',
    length: 9.5988,
    width: 31.3587,
    strength: 89.7121,
    ean: '7106826765664'
  },
  {
    ...baseArticle,
    id: 22,
    created: '2009-06-12T14:44:25Z',
    lastChange: '2012-03-16T17:18:44Z',
    description: 'Minim velit nisi elit ipsum nostrud officia ullamco.',
    name: 'ad nisi',
    length: 79.8088,
    width: 53.6651,
    strength: 70.6706,
    ean: '2736723054335'
  },
  {
    ...baseArticle,
    id: 23,
    created: '2012-12-15T11:46:33Z',
    lastChange: '2013-07-14T16:12:52Z',
    description: 'Enim consectetur adipisicing laboris et esse fugiat Lorem dolore ullamco nostrud non nulla.',
    name: 'velit esse',
    length: 78.325,
    width: 34.3456,
    strength: 21.9214,
    ean: '2524014068389'
  },
  {
    ...baseArticle,
    id: 24,
    created: '2013-03-04T14:41:13Z',
    lastChange: '2016-06-24T14:05:41Z',
    description: 'Sunt tempor ullamco ullamco labore veniam ipsum non laboris excepteur.',
    name: 'sunt in',
    length: 71.8621,
    width: 49.5164,
    strength: 68.672,
    ean: '6966288467036'
  },
  {
    ...baseArticle,
    id: 25,
    created: '2007-05-12T18:28:38Z',
    lastChange: '2008-02-13T17:23:59Z',
    description: 'Nulla aute laboris excepteur non culpa enim.',
    name: 'magna tempor',
    length: 24.8034,
    width: 26.7639,
    strength: 31.2732,
    ean: '4936904609636'
  },
  {
    ...baseArticle,
    id: 26,
    created: '2011-08-10T10:45:02Z',
    lastChange: '2009-03-06T03:39:40Z',
    description: 'Culpa id officia minim ea sit commodo Lorem eu magna deserunt reprehenderit aliqua minim.',
    name: 'et qui',
    length: 60.9062,
    width: 73.471,
    strength: 4.2343,
    ean: '9699896302796'
  },
  {
    ...baseArticle,
    id: 27,
    created: '2000-07-08T01:57:22Z',
    lastChange: '2019-09-22T09:21:38Z',
    description: 'Aute velit ipsum aliqua adipisicing irure cillum est non magna nostrud ea non occaecat elit.',
    name: 'consequat et',
    length: 94.1502,
    width: 6.8339,
    strength: 3.4903,
    ean: '8221935841627'
  },
  {
    ...baseArticle,
    id: 28,
    created: '2001-04-12T16:19:12Z',
    lastChange: '2011-07-31T02:36:49Z',
    description: 'Officia ipsum consectetur nisi reprehenderit.',
    name: 'cillum veniam',
    length: 32.1693,
    width: 38.845,
    strength: 68.605,
    ean: '2972085624797'
  },
  {
    ...baseArticle,
    id: 29,
    created: '2019-05-13T21:39:55Z',
    lastChange: '2003-10-17T01:59:32Z',
    description: 'Adipisicing cillum non ex enim pariatur irure ipsum laboris reprehenderit cillum occaecat eu laboris velit.',
    name: 'sit ex',
    length: 14.749,
    width: 52.8508,
    strength: 9.6161,
    ean: '2931444953974'
  },
  {
    ...baseArticle,
    id: 30,
    created: '2001-10-19T20:57:51Z',
    lastChange: '2018-06-12T04:00:30Z',
    description: 'Commodo aliquip mollit eiusmod voluptate reprehenderit sint occaecat officia commodo esse.',
    name: 'anim anim',
    length: 16.0594,
    width: 79.6959,
    strength: 71.7872,
    ean: '3237535238358'
  },
  {
    ...baseArticle,
    id: 31,
    created: '2011-11-16T05:37:50Z',
    lastChange: '2018-04-15T07:43:11Z',
    description: 'Labore magna ullamco magna Lorem culpa est voluptate esse do excepteur sit fugiat adipisicing.',
    name: 'sit exercitation',
    length: 35.3972,
    width: 37.1817,
    strength: 79.7979,
    ean: '1981118811417'
  },
  {
    ...baseArticle,
    id: 32,
    created: '2015-10-07T14:15:36Z',
    lastChange: '2013-08-29T01:16:28Z',
    description: 'Ex labore tempor eiusmod ut et sit qui nulla.',
    name: 'consequat cillum',
    length: 78.3504,
    width: 75.4289,
    strength: 95.8929,
    ean: '1027696986972'
  },
  {
    ...baseArticle,
    id: 33,
    created: '2001-07-05T03:31:48Z',
    lastChange: '2017-06-29T14:02:03Z',
    description: 'Irure magna eu duis reprehenderit mollit nulla id veniam fugiat Lorem adipisicing elit.',
    name: 'ipsum cupidatat',
    length: 75.7961,
    width: 18.6629,
    strength: 40.4581,
    ean: '5233430785089'
  },
  {
    ...baseArticle,
    id: 34,
    created: '2019-09-09T23:58:04Z',
    lastChange: '2013-04-23T20:04:12Z',
    description: 'In laboris sint nulla voluptate aute ipsum officia consequat commodo id labore.',
    name: 'aliqua commodo',
    length: 46.256,
    width: 88.6645,
    strength: 63.3327,
    ean: '3748575663727'
  },
  {
    ...baseArticle,
    id: 35,
    created: '2008-09-28T19:31:49Z',
    lastChange: '2014-01-13T23:02:12Z',
    description: 'Amet est dolore occaecat ex labore in anim pariatur laboris.',
    name: 'qui cupidatat',
    length: 61.2738,
    width: 59.4341,
    strength: 90.0734,
    ean: '8211377831276'
  },
  {
    ...baseArticle,
    id: 36,
    created: '2018-12-03T06:10:58Z',
    lastChange: '2012-01-04T22:39:30Z',
    description: 'Deserunt velit aliquip nulla adipisicing consectetur adipisicing.',
    name: 'laborum mollit',
    length: 47.5428,
    width: 74.525,
    strength: 94.7031,
    ean: '8972412598900'
  },
  {
    ...baseArticle,
    id: 37,
    created: '2005-04-27T05:49:05Z',
    lastChange: '2013-05-15T00:07:54Z',
    description: 'Nisi ad laboris fugiat ullamco reprehenderit consequat aute tempor ex amet sint elit adipisicing.',
    name: 'laboris quis',
    length: 82.9003,
    width: 55.8701,
    strength: 73.5669,
    ean: '4318631037266'
  },
  {
    ...baseArticle,
    id: 38,
    created: '2004-06-12T00:18:02Z',
    lastChange: '2009-07-21T12:47:25Z',
    description: 'Voluptate tempor culpa sunt velit sint nostrud nostrud incididunt eiusmod consectetur reprehenderit magna et aliquip.',
    name: 'duis quis',
    length: 29.7111,
    width: 65.0388,
    strength: 85.871,
    ean: '3122927379405'
  },
  {
    ...baseArticle,
    id: 39,
    created: '2000-03-21T16:45:14Z',
    lastChange: '2011-09-11T22:56:05Z',
    description: 'Voluptate do anim eiusmod in adipisicing nostrud voluptate proident excepteur cillum tempor irure adipisicing.',
    name: 'ut anim',
    length: 48.7557,
    width: 14.3279,
    strength: 20.581,
    ean: '8075066457373'
  },
  {
    ...baseArticle,
    id: 40,
    created: '2011-05-30T23:35:51Z',
    lastChange: '2006-05-12T22:45:20Z',
    description: 'Incididunt do nisi enim quis irure.',
    name: 'deserunt ipsum',
    length: 30.2695,
    width: 71.7541,
    strength: 31.6582,
    ean: '2705113962995'
  },
  {
    ...baseArticle,
    id: 41,
    created: '2017-05-16T10:11:08Z',
    lastChange: '2011-01-10T04:03:32Z',
    description: 'Eiusmod in laboris commodo est aute velit cupidatat magna exercitation cupidatat.',
    name: 'occaecat ipsum',
    length: 32.8815,
    width: 17.13,
    strength: 59.1377,
    ean: '2051580503498'
  },
  {
    ...baseArticle,
    id: 42,
    created: '2003-03-25T05:08:05Z',
    lastChange: '2013-11-26T20:46:59Z',
    description: 'Reprehenderit dolore fugiat aute commodo minim proident velit aute cupidatat anim laborum quis.',
    name: 'tempor laborum',
    length: 90.2754,
    width: 73.2148,
    strength: 76.4078,
    ean: '8440999137893'
  },
  {
    ...baseArticle,
    id: 43,
    created: '2011-03-26T18:15:11Z',
    lastChange: '2001-03-18T19:46:30Z',
    description: 'Pariatur ullamco nisi ex deserunt officia duis exercitation commodo quis ullamco cupidatat et duis adipisicing.',
    name: 'anim aliqua',
    length: 46.1326,
    width: 80.4884,
    strength: 28.6688,
    ean: '9968661837678'
  },
  {
    ...baseArticle,
    id: 44,
    created: '2016-09-16T19:21:02Z',
    lastChange: '2004-01-29T19:50:36Z',
    description: 'Esse tempor aliqua aute do nulla do aliqua qui laboris qui nisi elit sit.',
    name: 'ad proident',
    length: 6.4644,
    width: 4.0488,
    strength: 59.5251,
    ean: '2289774517594'
  },
  {
    ...baseArticle,
    id: 45,
    created: '2007-03-04T03:32:21Z',
    lastChange: '2015-07-28T16:07:52Z',
    description: 'Qui laboris do nisi voluptate non nisi est pariatur.',
    name: 'ut occaecat',
    length: 95.3102,
    width: 17.9042,
    strength: 21.3028,
    ean: '2889407236608'
  },
  {
    ...baseArticle,
    id: 46,
    created: '2014-12-16T05:14:37Z',
    lastChange: '2006-09-13T12:37:50Z',
    description: 'Pariatur eiusmod aliqua aliquip labore elit proident.',
    name: 'magna minim',
    length: 25.2156,
    width: 38.0577,
    strength: 14.5917,
    ean: '5249309902732'
  },
  {
    ...baseArticle,
    id: 47,
    created: '2006-04-06T19:08:05Z',
    lastChange: '2012-09-28T14:12:36Z',
    description: 'Ipsum aliquip consectetur commodo laboris.',
    name: 'aute laboris',
    length: 58.7821,
    width: 71.454,
    strength: 60.0889,
    ean: '1544005161461'
  },
  {
    ...baseArticle,
    id: 48,
    created: '2012-09-23T07:13:58Z',
    lastChange: '2008-08-21T05:41:41Z',
    description: 'Occaecat ipsum anim enim reprehenderit qui pariatur ex ex ut amet mollit adipisicing magna fugiat.',
    name: 'culpa eiusmod',
    length: 73.0854,
    width: 87.9296,
    strength: 11.9756,
    ean: '9753519945726'
  },
  {
    ...baseArticle,
    id: 49,
    created: '2012-02-28T23:36:33Z',
    lastChange: '2006-03-06T04:29:54Z',
    description: 'Do minim est aliqua occaecat.',
    name: 'officia id',
    length: 37.2879,
    width: 22.0232,
    strength: 42.9913,
    ean: '9526195007495'
  }
];
