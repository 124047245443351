
export class DateConverter {
  static convertToDateOnly(value: Date | string): string;
  static convertToDateOnly(value: Date | string | null): string | null {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      value = new Date(Date.parse(value));
    }

    value.setHours(12, 0, 0);

    const parts =  value.toISOString().split('T');
    return parts.length > 0 ? parts[0] : null;
  }
}
