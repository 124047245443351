import { Injectable } from '@angular/core';
import {
  ApiResponseOfListOfReceiptExportFieldSettingsDto,
  IReceiptExportFieldSettingsApiService, ReceiptExportFieldSettingsDto,
  ReceiptExportType
} from '@api';
import { NEVER, Observable, of } from 'rxjs';

@Injectable()
export class ReceiptExportFieldSettingsApiMockService implements IReceiptExportFieldSettingsApiService {
  getAllByType(receiptType?: ReceiptExportType | undefined): Observable<ApiResponseOfListOfReceiptExportFieldSettingsDto> {
    const data: ReceiptExportFieldSettingsDto[] = [
      {
        id: 1,
        created: null,
        lastChange: null,
        receiptType: ReceiptExportType.Offer,
        fieldName: 'ReceiptNumber',
        fieldIndex: 0,
      },
      {
        id: 2,
        created: null,
        lastChange: null,
        receiptType: ReceiptExportType.Offer,
        fieldName: 'ConstructionProjectNr',
        fieldIndex: 0,
      },
      {
        id: 3,
        created: null,
        lastChange: null,
        receiptType: ReceiptExportType.Offer,
        fieldName: 'ConstructionProjectName',
        fieldIndex: 0,
      },
    ];
    return of({
      data,
      count: 1,
      maxCount: 1,
      success: true,
      message: null
    });
  }

  updateSettings(receiptType: ReceiptExportType, newSettings: { [p: string]: number }): Observable<ApiResponseOfListOfReceiptExportFieldSettingsDto> {
    return NEVER;
  }
}
