import { Injectable } from '@angular/core';
import { ContactPersonDto, ContactPersonsApiService } from '@api/api.service';
import { LoadingParamsUtils } from '@api/utils';
import { from, Observable } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';
import { ResourceApiService } from '@api/models';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';

@Injectable({
  providedIn: 'root'
})
export class ContactPersonService implements ResourceApiService<ContactPersonDto> {

  constructor(private api: ContactPersonsApiService) {
  }

  getData(params: Partial<AntSortLoadingParams<ContactPersonDto>>): Observable<AntDataResponse<ContactPersonDto>> {
    return this.api.getPagedContactPersons(
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params),
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

  get(id: number): Observable<ContactPersonDto> {
    return this.api.getContactPerson(id).pipe(
      map(x => x.data as ContactPersonDto)
    );
  }

  create(item: ContactPersonDto): Observable<ContactPersonDto> {
    return this.api.createContactPerson(item).pipe(
      map(x => x.data as ContactPersonDto)
    );
  }

  update(id: number, item: ContactPersonDto): Observable<ContactPersonDto> {
    return this.api.updateContactPerson(id, item).pipe(
      map(x => x.data as ContactPersonDto)
    );
  }

  delete(id: number): Observable<boolean> {
    return this.api.deleteContactPerson(id).pipe(
      map(x => x.success)
    );
  }

  deleteBulk(ids: number[]): Observable<boolean[]> {
    return this.api.deleteBulkContactPersons(ids).pipe(
      map(res => res.data || []),
      switchMap(responses => from(responses)),
      map(res => res.success),
      toArray()
    );
  }
}
