import { Injectable } from '@angular/core';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import {
  BaseReceiptStatusDto,
  CreditNoteStatusService,
  GetDataApiService,
  InvoiceStatusService,
  OfferStatusService,
  OrderStatusService,
  ReceiptType, DeliveryNoteStatusService,
} from '@core/api';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReceiptStatusResolver
  implements GetDataApiService<BaseReceiptStatusDto>{
  private readonly updateApi$: ReplaySubject<ReceiptType>;
  private currentReceiptType: ReceiptType | null = null;

  constructor(private offerStatusApi: OfferStatusService,
              private orderStatusApi: OrderStatusService,
              private invoiceStatusApi: InvoiceStatusService,
              private creditNoteStatusApi: CreditNoteStatusService,
              private deliveryNoteStatusApi: DeliveryNoteStatusService) {
    this.updateApi$ = new ReplaySubject<ReceiptType>();
  }

  getData(params: Partial<AntSortLoadingParams<BaseReceiptStatusDto>>): Observable<AntDataResponse<BaseReceiptStatusDto>> {
    return this.updateApi$.pipe(
      map((receiptType: ReceiptType): GetDataApiService<any> => {
        switch (receiptType) {
          case ReceiptType.Offer:
            return this.offerStatusApi;
          case ReceiptType.Order:
            return this.orderStatusApi;
          case ReceiptType.Invoice:
            return this.invoiceStatusApi;
          case ReceiptType.CreditNote:
            return this.creditNoteStatusApi;
          case ReceiptType.DeliveryNote:
            return this.deliveryNoteStatusApi;
        }
      }),
      switchMap((statusService: GetDataApiService<any>) => statusService.getData(params)),
    );
  }

  changeReceiptType(receiptType: ReceiptType): void {
    if (this.currentReceiptType === receiptType) {
      return;
    }

    this.updateApi$.next(receiptType);
    this.currentReceiptType = receiptType;
  }
}
