import { Injectable } from '@angular/core';
import { InvoiceVersionDto, InvoiceVersionsApiService } from '@api/api.service';
import { ReceiptVersionService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class InvoiceVersionService extends ReceiptVersionService<InvoiceVersionDto> {
  constructor(protected api: InvoiceVersionsApiService) {
    super(api);
  }
}
