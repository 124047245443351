import { Injectable } from '@angular/core';
import { ReceiptService } from '../base';
import {
  CreditNoteDto,
  CreditNotePositionDto,
  CreditNotesApiService,
  CreditNoteStatusDto,
  CreditNoteVersionDto
} from '@api/api.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ApiResponseUtils,
  BaseReceiptSystemPropertiesDto
} from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteService extends ReceiptService<CreditNoteDto, CreditNoteVersionDto, CreditNotePositionDto> {
  constructor(protected api: CreditNotesApiService) {
    super(api);
  }

  release(creditNoteId: number): Observable<CreditNoteVersionDto | null> {
    return this.api.release(creditNoteId)
      .pipe(
        catchError(() => of(null)),
        map((responseData): CreditNoteVersionDto | null => {
          if (!responseData) {
            return null;
          } else {
            return responseData.data as CreditNoteVersionDto;
          }
        })
      );
  }

  updateReleasedReceipt(creditNoteId: number, data: BaseReceiptSystemPropertiesDto<CreditNoteStatusDto>): Observable<CreditNoteVersionDto> {
    return this.api.updateReleasedReceipt(creditNoteId, data).pipe(
      map(res => ApiResponseUtils.getDataFromResponse(res))
    );
  }
}
