import { OrderDto } from '@api';
import { CONSTRUCTION_PROJECTS } from '@app/mock/data/construction-projects';
import { ORDER_VERSIONS } from '@app/mock/data/order_versions';
import { OFFERS } from '@app/mock/data/offers';

const baseOrder: OrderDto = {
  costCenter: 'Cost center',
  transferredFromOffer: OFFERS[0],
  transferredFromOfferId: OFFERS[0].id ?? 0,
  id: 0,
  constructionProjectId: 0,
  constructionProject: null,
  isReadOnly: false,
  latestVersion: null,
  number: null,
  createdBy: null,
  createdById: 0,
  created: null,
  lastChange: null
};

export const ORDERS: OrderDto[] = [
  {
    ...baseOrder,
    id: 1,
    number: 'AUF-123-1',
    constructionProject: CONSTRUCTION_PROJECTS[0],
    constructionProjectId: CONSTRUCTION_PROJECTS[0].id ?? 0,
    latestVersion: ORDER_VERSIONS[0]
  }
];
