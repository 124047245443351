import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { ApiSortDirection, DeliveryConditionDto, IDeliveryConditionApiService } from '@api';
import { NEVER, Observable } from 'rxjs';

@Injectable()
export class DeliveryConditionsApiMockService extends CrudBase<DeliveryConditionDto> implements IDeliveryConditionApiService {
  constructor() {
    super([]);
  }

  getPagedDeliveryConditions(skip?: number, take?: number, filterName?: string[], filterValue?: string, sortName?: string[],
                             sortDirection?: ApiSortDirection): Observable<GenericApiResponse<DeliveryConditionDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getDeliveryCondition(id: number): Observable<GenericApiResponse<DeliveryConditionDto>> {
    return this.get(id);
  }

  createDeliveryCondition(dto: DeliveryConditionDto): Observable<GenericApiResponse<DeliveryConditionDto>> {
    return this.create(dto);
  }

  updateDeliveryCondition(id: number, dto: DeliveryConditionDto): Observable<GenericApiResponse<DeliveryConditionDto>> {
    return this.update(id, dto);
  }

  deleteDeliveryCondition(id: number): Observable<GenericApiResponse<DeliveryConditionDto>> {
    return this.delete(id);
  }

  deleteBulkDeliveryConditions(ids?: number[]): Observable<GenericApiResponse<GenericApiResponse<DeliveryConditionDto>[]>> {
    return NEVER;
  }
}
