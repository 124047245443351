import { Injectable } from '@angular/core';
import { CustomerDto, CustomersApiService } from '@api/api.service';
import { LoadingParamsUtils } from '@api/utils';
import { from, Observable } from 'rxjs';
import { map, switchMap, toArray, shareReplay } from 'rxjs/operators';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { ResourceApiService } from '@core/api';

@Injectable({
  providedIn: 'root'
})
export class CustomerService implements ResourceApiService<CustomerDto> {

  constructor(private api: CustomersApiService) {
  }

  getData(params: Partial<AntSortLoadingParams<CustomerDto>>): Observable<AntDataResponse<CustomerDto>> {
    return this.api.getPagedCustomers(
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      })),
      shareReplay(1),
    );
  }

  get(id: number): Observable<CustomerDto> {
    return this.api.getCustomer(id).pipe(
      map(x => x.data as CustomerDto)
    );
  }

  create(customer: CustomerDto): Observable<CustomerDto> {
    return this.api.createCustomer(customer).pipe(
      map(x => x.data as CustomerDto)
    );
  }

  update(id: number, customer: CustomerDto): Observable<CustomerDto> {
    return this.api.updateCustomer(id, customer).pipe(
      map(x => x.data as CustomerDto)
    );
  }

  delete(id: number): Observable<boolean> {
    return this.api.deleteCustomer(id).pipe(
      map(x => x.success)
    );
  }

  deleteBulk(ids: number[]): Observable<boolean[]> {
    return this.api.deleteBulkCustomers(ids).pipe(
      map(res => res.data || []),
      switchMap(responses => from(responses)),
      map(res => res.success),
      toArray()
    );
  }
}
