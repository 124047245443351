import { ViewAction, ViewActionConfig } from '@antony/ng-ui';
import { Injectable } from '@angular/core';
import { ChangePasswordService } from '@core/authentication/services/change-password.service';

@Injectable()
export class ChangePasswordViewAction extends ViewAction {
  readonly actionName: string = 'CHANGE_PASSWORD';

  constructor(private service: ChangePasswordService) {
    super();
  }

  onClick(): void {
    this.service.requestPasswordChange();
  }

  getConfig(): ViewActionConfig {
    return {
      ...super.getConfig(),
      prefix: 'AUTHENTICATION.ACTIONS.'
    };
  }
}
