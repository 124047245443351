import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { APP_INJECTOR } from '@core/data/app-injector';

export class StaticRouter {
  static navigate(commands: string[], extras?: NavigationExtras): Promise<boolean> {
    const router = StaticRouter.getRouter();
    const route = StaticRouter.getRealActivatedRoute();
    return router.navigate(commands, {
      relativeTo: route,
      ...extras
    });
  }

  private static getRouter(): Router {
    return APP_INJECTOR.get<Router>(Router);
  }

  private static getRealActivatedRoute(): ActivatedRoute {
    let route = APP_INJECTOR.get<ActivatedRoute>(ActivatedRoute);
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
