import { ExtendedFormGroup, ExtendedFormGroupOptions, PasswordField } from '@antony/ng-forms';
import { ChangePasswordDto } from '@api';
import { AppPasswordField } from '@core/authentication/models/app-password.field';
import { AppValidators } from '@core/models/form/app-validators';

export interface ChangePasswordFormOptions extends ExtendedFormGroupOptions<ChangePasswordDto> {
}

export class ChangePasswordForm extends ExtendedFormGroup<ChangePasswordDto> {
  constructor(options: ChangePasswordFormOptions = { }) {
    super({
      ...options,
      controls: {
        oldPassword: new PasswordField({
          icon: 'AUTHENTICATION.PROPERTY.PASSWORD.ICON',
          label: 'AUTHENTICATION.CHANGE_PASSWORD.OLD_PASSWORD.LABEL',
          required: true,
          maxLength: 1024,
          errorMessages: {
            wrongPassword: 'AUTHENTICATION.CHANGE_PASSWORD.OLD_PASSWORD.ERRORS.WRONG_PASSWORD'
          }
        }),
        newPassword: new AppPasswordField({
          label: 'AUTHENTICATION.CHANGE_PASSWORD.NEW_PASSWORD.LABEL',
          hint: 'AUTHENTICATION.CHANGE_PASSWORD.NEW_PASSWORD.HINT',
          errorMessages: {
            notEqualValue: 'AUTHENTICATION.CHANGE_PASSWORD.NEW_PASSWORD.ERRORS.EQUALS_OLD_PASSWORD'
          }
        }),
        newPasswordRepeat: new AppPasswordField({
          label: 'AUTHENTICATION.CHANGE_PASSWORD.NEW_PASSWORD_REPEAT.LABEL',
          hint: 'AUTHENTICATION.CHANGE_PASSWORD.NEW_PASSWORD_REPEAT.HINT',
          errorMessages: {
            equalValue: 'AUTHENTICATION.CHANGE_PASSWORD.NEW_PASSWORD_REPEAT.ERRORS.EQUAL_VALUE'
          }
        }),
        ...options.controls
      },
      validators: [
        AppValidators.notEqualValue('oldPassword', 'newPassword'),
        AppValidators.equalValue('newPassword', 'newPasswordRepeat')
      ]
    });
  }
}

