import { BaseReceiptStatusDto } from '@api';


const baseCreditNoteStatusDto: BaseReceiptStatusDto = {
  id: 0,
  status: '',
  created: '2021-01-01Z00:00:000Z',
  lastChange: '2021-01-01Z00:00:000Z'
};

export const DELIVERY_NOTE_STATUS: BaseReceiptStatusDto[] = [
  {
    ...baseCreditNoteStatusDto,
    id: 1,
    status: 'Status A'
  },
  {
    ...baseCreditNoteStatusDto,
    id: 2,
    status: 'Status B'
  },
  {
    ...baseCreditNoteStatusDto,
    id: 3,
    status: 'Status C'
  },
  {
    ...baseCreditNoteStatusDto,
    id: 4,
    status: 'Status D'
  }
];
