import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { TitleService } from '@core/navigation/services/title.service';
import { environment } from '@env';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  showProfile = false;
  environment = environment;

  constructor(protected authService: AuthenticationService,
              protected titleService: TitleService) {
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  ngOnInit(): void { }

  getTitle(): string {
    return this.titleService.getTitleWithUpdate();
  }
}
