import { AmountUnitDto } from '@api';

export const AMOUNT_UNITS: AmountUnitDto[] = [
  {
    id: 1,
    name: 'Quadratmeter',
    symbol: 'qm',
    created: null,
    lastChange: null
  }
];
