import { Injectable } from '@angular/core';
import { ActivateUserDto, AuthApiService } from '@api';
import { NEVER, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationService } from '@antony/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class ActivateService {
  constructor(private api: AuthApiService,
              private notification: NotificationService,
              private translate: TranslateService,
              private router: Router) {
  }

  activateAccount(dto: ActivateUserDto): Observable<boolean> {
    const success$ = this.sendDataToApi(dto);
    return success$.pipe(
      catchError(err => this.handleError(err)),
      tap(
        () => this.sendSuccessNotification(),
        () => this.sendFailureNotification()
      )
    );
  }

  private sendDataToApi(dto: ActivateUserDto): Observable<boolean> {
    return this.api.activateAccount(dto).pipe(
      map(res => res.success)
    );
  }

  private handleError(err: any): Observable<boolean> {
    if (!!err.message) {
      if (err.message === 'Invalid token') {
        this.sendInvalidTokenNotification();
        return NEVER;
      }
    }
    throw err;
  }

  private sendSuccessNotification(): void {
    this.notification.openLowPriority({
      message: this.translate.instant('AUTHENTICATION.ACTIVATE.NOTIFICATIONS.SUCCESS.MESSAGE')
    });
  }

  private sendFailureNotification() {
    this.notification.openMediumPriority({
      message: this.translate.instant('AUTHENTICATION.ACTIVATE.NOTIFICATIONS.FAILURE.MESSAGE'),
      submitActionLabel: this.translate.instant('AUTHENTICATION.ACTIVATE.NOTIFICATIONS.FAILURE.OK')
    });
  }

  private sendInvalidTokenNotification() {
    this.notification.openMediumPriority({
      message: this.translate.instant('AUTHENTICATION.ACTIVATE.NOTIFICATIONS.INVALID_TOKEN.MESSAGE'),
      submitActionLabel: this.translate.instant('AUTHENTICATION.ACTIVATE.NOTIFICATIONS.INVALID_TOKEN.OK')
    });
    this.router.navigate(['auth', 'login']);
  }
}
