import { BaseDto, LoadingParamsUtils, ResourceApiService } from '@api';
import { AntDataResponse, AntSortLoadingParams, ResourceProvider } from '@antony/ng-ui';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, skip, switchMap, take, tap, toArray } from 'rxjs/operators';
import { ArrayUtils } from './array-utils';
import { RxjsUtils } from './rxjs-utils';

export abstract class ApiAdapter<T extends BaseDto, C extends BaseDto> implements ResourceApiService<T> {

  protected constructor(protected api: ResourceApiService<C>,
                        protected provider: ResourceProvider<C>) {
  }

  getData(params: Partial<AntSortLoadingParams<T>>): Observable<AntDataResponse<T>> {
    let maxCount: number;
    return this.getResource().pipe(
      map(res => this.getItemsFromResource(res) || []),
      tap(items => maxCount = items.length),
      switchMap(items => this.applyParamsToArray(items, params)),
      map(items => ({
        data: items,
        maxCount
      }))
    );
  }

  get(itemId: number): Observable<T> {
    return this.getResource().pipe(
      map(resource => {
        const items = this.getItemsFromResource(resource);
        return ArrayUtils.getItemById(items, itemId);
      }),
      // TODO: throw exception if item is null
      map(item => item as T)
    );
  }

  create(dto: T): Observable<T> {
    return this.getResource().pipe(
      take(1),
      map(resource => {
        let items = this.getItemsFromResource(resource);
        items = ArrayUtils.addItem(items, dto);
        return this.updateItemsInResource(resource, items);
      }),
      // TODO: throw exception if item id is null
      switchMap(updatedResource => this.api.update(updatedResource.id as number, updatedResource)),
      tap((res) => this.provider.setResourceId(res.id as number)),
      mapTo(dto)
    );
  }

  update(itemId: number, dto: T): Observable<T> {
    return this.getResource().pipe(
      take(1),
      map(resource => {
        let items = this.getItemsFromResource(resource);
        items = ArrayUtils.replaceItem(items, dto);
        return this.updateItemsInResource(resource, items);
      }),
      // TODO: throw exception if item id is null
      switchMap(updatedResource => this.api.update(updatedResource.id as number, updatedResource)),
      tap((res) => this.provider.setResourceId(res.id as number)),
      mapTo(dto)
    );
  }

  delete(itemId: number): Observable<boolean> {
    return this.getResource().pipe(
      take(1),
      map(resource => {
        let items = this.getItemsFromResource(resource);
        items = ArrayUtils.deleteItemById(items, itemId);
        return this.updateItemsInResource(resource, items);
      }),
      // TODO: throw exception if item id is null
      switchMap(updatedResource => this.api.update(updatedResource.id as number, updatedResource)),
      tap((res) => this.provider.setResourceId(res.id as number)),
      map(res => !!res),
      catchError(err => of(false))
    );
  }

  deleteBulk(itemIds: number[]): Observable<boolean[]> {
    return this.getResource().pipe(
      take(1),
      map(resource => {
        let items = this.getItemsFromResource(resource);
        items = ArrayUtils.deleteItemsByIds(items, itemIds);
        return this.updateItemsInResource(resource, items);
      }),
      // TODO: throw exception if item id is null
      switchMap(updatedResource => this.api.update(updatedResource.id as number, updatedResource)),
      tap((res) => this.provider.setResourceId(res.id as number)),
      map(res => itemIds.map(() => !!res)),
      catchError(err => of(itemIds.map(() => false)))
    );
  }

  protected abstract getItemsFromResource(resource: C): T[];

  protected abstract updateItemsInResource(resource: C, items: T[]): C;

  protected compare(a: T, b: T, sortName: string): number {
    if (a.hasOwnProperty(sortName) && b.hasOwnProperty(sortName)) {
      const aString = JSON.stringify(a[sortName as keyof T]);
      const bString = JSON.stringify(b[sortName as keyof T]);
      return aString.localeCompare(bString);
    }
    return 0;
  }

  private getResource(): Observable<C> {
    return this.provider.getResource().pipe(
      filter(RxjsUtils.isNotNullOrUndefined)
    );
  }

  private applyParamsToArray(array: T[], params: Partial<AntSortLoadingParams<T>>): Observable<T[]> {
    const sortedArray = this.sort(array, params);
    return from(sortedArray).pipe(
      skip(LoadingParamsUtils.getSkipFrom(params)),
      take(LoadingParamsUtils.getTakeFrom(params)),
      toArray()
    );
  }

  private sort(array: T[], params: Partial<AntSortLoadingParams<T>>): T[] {
    const sortNames = LoadingParamsUtils.getSortNamesFrom(params) || ['id'];
    array.sort((a, b) => this.compare(a, b, sortNames[0]));
    return array;
  }
}


