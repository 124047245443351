import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { ApiSortDirection, ArticleDto, IArticlesApiService } from '@api';
import { ARTICLES } from '@app/mock/data/articles';
import { NEVER, Observable } from 'rxjs';

@Injectable()
export class ArticlesApiMockService extends CrudBase<ArticleDto> implements IArticlesApiService {

  constructor() {
    super(ARTICLES);
  }

  getPagedArticles(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                   sortName?: string[], sortDirection?: ApiSortDirection): Observable<GenericApiResponse<ArticleDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getArticle(id: number): Observable<GenericApiResponse<ArticleDto>> {
    return this.get(id);
  }

  createArticle(dto: ArticleDto): Observable<GenericApiResponse<ArticleDto>> {
    return this.create(dto);
  }

  updateArticle(id: number, dto: ArticleDto): Observable<GenericApiResponse<ArticleDto>> {
    return this.update(id, dto);
  }

  deleteArticle(id: number): Observable<GenericApiResponse<ArticleDto>> {
    return this.delete(id);
  }

  deleteBulkArticles(id?: number[]): Observable<GenericApiResponse<GenericApiResponse<ArticleDto>[]>> {
    return NEVER;
  }
}
