import { AppError } from '@core/models';

export class FormError<T> extends AppError {

  readonly value: T | null;

  constructor(key: string, message: string, value?: T) {
    super(key, message);
    this.value = value || null;
  }
}
