import { Injectable } from '@angular/core';
import { OrderVersionDto, OrderVersionsApiService } from '@api/api.service';
import { ReceiptVersionService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class OrderVersionService extends ReceiptVersionService<OrderVersionDto> {
  constructor(protected api: OrderVersionsApiService) {
    super(api);
  }
}
