import { RuiterUserDto, UserStatus } from '@api';

export const USERS: RuiterUserDto[] = [
  {
    id: 1,
    isEnabled: true,
    isPasswordChangeRequired: false,
    username: 'Admin',
    firstname: 'Admin',
    lastname: 'istrator',
    phoneNumber: '+49 1234 567890',
    permissions: ['global_admin'],
    status: UserStatus.Joined,
    created: null,
    lastChange: null
  },
  {
    id: 2,
    isEnabled: true,
    isPasswordChangeRequired: false,
    username: 'test@ruiter.de',
    firstname: 'Test',
    lastname: 'Test',
    phoneNumber: '+49 1234 567890',
    permissions: ['global_admin'],
    status: UserStatus.Joined,
    created: null,
    lastChange: null
  }
];
