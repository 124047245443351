import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Routes } from '@angular/router';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  mainMenuItems: Routes = [];

  @Output()
  navigated = new EventEmitter();

  constructor(private navService: NavigationService) {
  }

  ngOnInit() {
    this.mainMenuItems = this.navService.getMainMenuItems();
  }

}
