import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { ResourceApiService } from '@api/models';
import { ConstructionProjectDto, ConstructionProjectsApiService } from '@api/api.service';
import { LoadingParamsUtils } from '@api/utils';

@Injectable({
  providedIn: 'root'
})
export class ConstructionProjectService implements ResourceApiService<ConstructionProjectDto> {

  constructor(private api: ConstructionProjectsApiService) {
  }

  getData(params: Partial<AntSortLoadingParams<ConstructionProjectDto>>): Observable<AntDataResponse<ConstructionProjectDto>> {
    return this.api.getPagedConstructionProjects(
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

  get(id: number): Observable<ConstructionProjectDto> {
    return this.api.getConstructionProject(id).pipe(
      map(x => x.data as ConstructionProjectDto)
    );
  }

  create(dto: ConstructionProjectDto): Observable<ConstructionProjectDto> {
    return this.api.createConstructionProject(dto).pipe(
      map(x => x.data as ConstructionProjectDto)
    );
  }

  update(id: number, dto: ConstructionProjectDto): Observable<ConstructionProjectDto> {
    return this.api.updateConstructionProject(id, dto).pipe(
      map(x => x.data as ConstructionProjectDto)
    );
  }

  delete(resourceId: number): Observable<boolean> {
    return throwError('Illegal action: try to delete construction project');
  }

  deleteBulk(resourceIds: number[]): Observable<boolean[]> {
    return throwError('Illegal action: try to delete construction projects');
  }
}
