import { Component, OnInit } from '@angular/core';
import { PasswordForgotForm } from '@core/authentication/models/password-forgot.form';
import { AuthPageState } from '@core/authentication/models/auth-page-state';
import { PasswordForgotService } from '@core/authentication/services/password-forgot.service';
import { ForgotPasswordDto } from '@api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {

  readonly passwordForgotForm = new PasswordForgotForm();
  labelHideBreakpoints: string[] = [];
  private status: AuthPageState = AuthPageState.Initializing;

  constructor(private api: PasswordForgotService,
              private router: Router) { }

  ngOnInit(): void {
    this.status = AuthPageState.Pending;
  }

  submitForm(): void {
    const dto: ForgotPasswordDto | null = this.passwordForgotForm.value;
    if (this.passwordForgotForm.invalid || this.hasSubmitted() || !dto) {
      return;
    }
    this.status = AuthPageState.Submitted;
    this.api.passwordForgot(dto).subscribe(
      () => this.router.navigate(['auth', 'login']),
      (err) => {
        this.passwordForgotForm.enable();
        this.status = AuthPageState.Error;
      }
    );
  }

  hasSubmitted(): boolean {
    return this.status === AuthPageState.Submitted;
  }

  getErrors(fieldKey: string): any {
    if (this.passwordForgotForm.valid) {
      return;
    }

    return this.passwordForgotForm.get(fieldKey)?.errors;
  }
}
