import { BaseDto } from '@api';

export class ArrayUtils {

  public static addItem<T>(items: T[], itemToAdd: T): T[] {
    items = items || [];
    items.push(itemToAdd);
    return items;
  }

  public static getItemById<T extends BaseDto>(array: T[], id: number): T | null {
    return array.find(item => item.id === id) || null;
  }

  public static replaceItem<T extends BaseDto>(array: T[], itemToReplace: T): T[] {
    const idx = array.findIndex(item => item.id === itemToReplace.id);
    if (idx >= 0) {
      array[idx] = itemToReplace;
    }
    return array;
  }

  public static deleteItemById<T extends BaseDto>(array: T[], id: number): T[] {
    const idx = array.findIndex(item => item.id === id);
    if (idx >= 0) {
      array.splice(idx, 1);
    }
    return array;
  }

  public static deleteItemsByIds<T extends BaseDto>(array: T[], ids: number[]): T[] {
    ids.forEach(id => {
      const idx = array.findIndex(item => item.id === id);
      if (idx >= 0) {
        array.splice(idx, 1);
      }
    });
    return array;
  }

  static moveBack<T>(set: T[], targets: T[]): T[] {
    if (!set || set.length < 1 || !targets || targets.length < 1) {
      return [];
    }
    let canMoveBack = true;
    set
      .sort((targetA, targetB) => set.indexOf(targetA) - set.indexOf(targetB))
      .forEach((setItem, idx) => {
        if (!targets.includes(setItem)) {
          canMoveBack = true;
          return;
        }

        if (idx === 0) {
          canMoveBack = false;
        }

        if (canMoveBack) {
          set[idx] = set[idx - 1];
          set[idx - 1] = setItem;
        }
      })
    ;
    return set;
  }

  static moveForward<T>(set: T[], targets: T[]): T[] {
    const reversedSet = set.reverse();
    const result = ArrayUtils.moveBack(reversedSet, targets);
    return result.reverse();
  }
}
