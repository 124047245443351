import { Component } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-authentication-footer',
  templateUrl: './authentication-footer.component.html',
  styleUrls: ['./authentication-footer.component.scss']
})
export class AuthenticationFooterComponent {
  VERSION = environment.version;
}
