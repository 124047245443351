<h2 matDialogTitle>{{ data.title | translate }}</h2>
<mat-dialog-content markdown>
    {{ data.message | translate:data.messageData }}
</mat-dialog-content>
<mat-dialog-actions>
    <ant-button color="warn"
                tabindex="1"
                (click)="submit()"
                [actionName]="data.submitActionName | translate"
                [hideIcon]="true"
    ></ant-button>
    <ant-button color="primary"
                tabindex="0"
                (click)="dismiss()"
                [actionName]="data.dismissActionName | translate"
                [hideIcon]="true"
    ></ant-button>
</mat-dialog-actions>
