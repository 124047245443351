import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SimpleDialogConfig {
  title: string;
  message: string;
  submitActionName?: string;
  dismissActionName?: string;
}

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss']
})
export class SimpleDialogComponent {

  public readonly data: Required<SimpleDialogConfig>;

  constructor(private matDialogRef: MatDialogRef<SimpleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: SimpleDialogConfig) {
    this.data = {
      dismissActionName: 'NO',
      submitActionName: 'YES',
      ...data
    };
  }

  dismiss() {
    this.matDialogRef.close(false);
  }

  submit() {
    this.matDialogRef.close(true);
  }
}
