import { DeliveryNoteVersionDto } from '@api';
import { DELIVERY_NOTE_STATUS } from '@app/mock/data/delivery_note_status';

const baseDeliveryNoteVersion: DeliveryNoteVersionDto = {
  id: 0,
  receiptId: 0,
  status: DELIVERY_NOTE_STATUS[0],
  statusId: DELIVERY_NOTE_STATUS[0].id ?? 0,
  comment: null,
  deliveryCondition: null,
  deliveryConditionId: null,
  paymentCondition: null,
  paymentConditionId: null,
  deliveryDate: null,
  headerText: null,
  footerText: null,
  numberOfTransports: 0,
  rentalPeriodFrom: '2020-01-01T00:00:00.000Z',
  rentalPeriodTo: '2020-01-01T00:00:00.000Z',
  rentDurationDays: 0,
  billRecipientId: 0,
  billRecipient: null,
  kind: '',
  price: {
    vatAmount: 0,
    totalGrossPrice: 0,
    totalNetPrice: 0
  },
  positions: [],
  articlePlateCounts: [
    {
      articleId: 1,
      totalPlateCount: 10,
      articleAbbrevation: 'asd',
      isDeparture: false,
    }
  ],
  totalPlateCount: 10,
  vat: 19,
  versionNumber: 1,
  createdBy: null,
  createdById: 0,
  created: null,
  lastChange: null,
  supportsMultipleAssignments: false,
};

export const DELIVERY_NOTE_VERSIONS: DeliveryNoteVersionDto[] = [
  {
    ...baseDeliveryNoteVersion,
    id: 1,
    positions: [],
    kind: 'Art der Arbeit'
  }
];
