import { OfferPositionDto, OfferVersionDto, PositionType } from '@api';
import { OFFER_STATUS } from '@app/mock/data/offer_status';

export const OFFER_POSITIONS: OfferPositionDto[] = [
  {
    id: 1,
    index: 1,
    name: 'Example',
    printText: 'Example',
    price: 12.34,
    amount: 15,
    amountUnit: 'qm',
    timeAmount: 10,
    timeUnit: 'Tage',
    totalPrice: 10 * 15 * 12.34,
    revenueAccount: '1234',
    article: null,
    articleId: null,
    created: null,
    lastChange: null,
    type: PositionType.Undefined
  }
];

export const OFFER_VERSIONS: OfferVersionDto[] = [
  {
    id: 1,
    receiptId: 1,
    status: OFFER_STATUS[0],
    statusId: 1,
    comment: 'Lorem ipsum',
    deliveryCondition: null,
    deliveryConditionId: null,
    paymentCondition: null,
    paymentConditionId: null,
    deliveryDate: '2020-01-01T00:00:00.000Z',
    headerText: 'HEADER_TEXT',
    footerText: 'FOOTER_TEXT',
    billRecipientId: 1,
    billRecipient: null,
    kind: '',
    price: {
      vatAmount: 1.02,
      totalGrossPrice: 2.00,
      totalNetPrice: 0.98
    },
    positions: OFFER_POSITIONS,
    vat: 16,
    versionNumber: 1,
    createdBy: null,
    createdById: 1,
    created: null,
    lastChange: null,
    numberOfTransports: 1,
    rentalPeriodFrom: '2020-01-01T00:00:00.000Z',
    rentalPeriodTo: '2020-01-01T00:00:00.000Z',
    rentDurationDays: 1
  }
];
