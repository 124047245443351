import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { HandleUnsavedChanges } from './handle-unsaved-changes';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<HandleUnsavedChanges> {

  constructor(public dialog: MatDialog) {
  }

  canDeactivate(
    component: HandleUnsavedChanges,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    switch (component.handleUnsavedChanges()) {
      case 'leave':
        return true;
      case 'stay':
        return false;
      case 'ask':
        return this.dialog.open(UnsavedChangesDialogComponent, { })
          .afterClosed().pipe(map(x => x as boolean));
    }
  }

}
