import { ResourceApiService } from '@api/models';
import { ApiResponseUtils, LoadingParamsUtils } from '@api/utils';
import { VehicleTypeDto, VehicleTypesApiService } from '@api/api.service';
import { Observable } from 'rxjs';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VehicleTypeService implements ResourceApiService<VehicleTypeDto> {
  constructor(protected api: VehicleTypesApiService) {
  }

  getData(params: Partial<AntSortLoadingParams<VehicleTypeDto>>): Observable<AntDataResponse<VehicleTypeDto>> {
    return this.api.getPagedVehicleTypes(
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(ApiResponseUtils.getAntDataResponseFromResponse)
    );
  }

  get(resourceId: number): Observable<VehicleTypeDto> {
    return this.api.getVehicleType(resourceId).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  create(dto: VehicleTypeDto): Observable<VehicleTypeDto> {
    return this.api.createVehicleType(dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  update(resourceId: number, dto: VehicleTypeDto): Observable<VehicleTypeDto> {
    return this.api.updateVehicleType(resourceId, dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  delete(resourceId: number): Observable<boolean> {
    return this.api.deleteVehicleType(resourceId).pipe(
      map(ApiResponseUtils.getSuccessFromResponse)
    );
  }

  deleteBulk(resourceIds: number[]): Observable<boolean[]> {
    return this.api.deleteBulkVehicleTypes(resourceIds).pipe(
      map(ApiResponseUtils.getSuccessesFromResponseOfResponse)
    );
  }
}
