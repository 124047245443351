import { ReceiptStatusApiService } from './receipt-status.api-service';
import { BaseReceiptStatusDto, GetDataApiService } from '@core/api';
import { AntDataResponse, AntSortLoadingParams } from '@antony/ng-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class ReceiptStatusService<T extends BaseReceiptStatusDto = BaseReceiptStatusDto> implements GetDataApiService<T> {

  constructor(private readonly api: ReceiptStatusApiService<T>) { }

  getData(params: Partial<AntSortLoadingParams<T>>): Observable<AntDataResponse<T>> {
    return this.api.getAllStatus().pipe(
      map((res): AntDataResponse<T> => ({
        data: res.data ?? [],
        maxCount: res.maxCount ?? 0
      }))
    );
  }

}
