import {
  ApiResponseOfConstructionProjectContactPersonsDto,
  ApiSortDirection,
  ConstructionProjectContactPersonDto,
  ConstructionProjectContactPersonsDto,
  ContactPersonDto,
  IConstructionProjectContactPersonsApiService
} from '@api';
import { Injectable } from '@angular/core';
import {
  CrudBase,
  GenericApiResponse
} from '@app/mock/models';
import {
  NEVER,
  Observable
} from 'rxjs';
import { CONTACT_PERSONS } from '@app/mock/data/contact-persons';

@Injectable()
export class ConstructionProjectContactPersonApiMockService extends CrudBase<ContactPersonDto>
  implements IConstructionProjectContactPersonsApiService {

  constructor() {
    super(CONTACT_PERSONS);
  }

  getAllContactPersons(customerId: number, skip?: number, take?: number, filterName?: string[], filterValue?: string,
                       sortName?: string[], sortDirection?: ApiSortDirection)
    : Observable<GenericApiResponse<ContactPersonDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  createContactPersons(customerId: number, contactPersonId: number)
    : Observable<GenericApiResponse<ConstructionProjectContactPersonDto>> {
    return NEVER;
  }

  deleteContactPerson(customerId: number, contactPersonId: number)
    : Observable<GenericApiResponse<ConstructionProjectContactPersonDto>> {
    return NEVER;
  }

  deleteBulkContactPersons(customerId: number, contactPersonId?: number[])
    : Observable<GenericApiResponse<GenericApiResponse<ConstructionProjectContactPersonDto>[]>> {
    return NEVER;
  }

  setContactPersons(constructionProjectId: number, newContactPersons: ConstructionProjectContactPersonsDto)
    : Observable<ApiResponseOfConstructionProjectContactPersonsDto> {
    return NEVER;
  }

}
