import { Injectable } from '@angular/core';
import { ApplicationSettingsApiService, ApplicationSettingsDto } from '@api/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MalformedServerResponseError } from '@core/models/errors';

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {
  constructor(private api: ApplicationSettingsApiService) {
  }

  getSettings(): Observable<ApplicationSettingsDto> {
    return this.api.getApplicationSettings().pipe(
      map(res => {
        if (!res || !res.data) {
          throw new MalformedServerResponseError();
        }
        return res.data;
      })
    );
  }

  updateSettings(dto: ApplicationSettingsDto): Observable<ApplicationSettingsDto> {
    return this.api.updateApplicationSettings(dto).pipe(
      map(res => {
        if (!res || !res.data) {
          throw new MalformedServerResponseError();
        }
        return res.data;
      })
    );
  }
}
