import { Injectable } from '@angular/core';
import { OfferVersionDto, OfferVersionsApiService } from '@api/api.service';
import { ReceiptVersionService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class OfferVersionService extends ReceiptVersionService<OfferVersionDto> {
  constructor(protected api: OfferVersionsApiService) {
    super(api);
  }
}
