import { ExtendedFormGroup, ExtendedFormGroupOptions, HiddenField, TextField } from '@antony/ng-forms';
import { ActivateUserDto } from '@api';
import { UserValidator } from '@app/user/models/userValidator';
import { PASSWORD_REPEAT_FIELDS } from '@core/authentication/models/password-repeat.fields';
import { UserConstants } from '@app/user/models/user-constants';
import { DEFAULT_ERROR_MESSAGES } from '@app/default-error-messages';

export interface ActivateFormOptions extends ExtendedFormGroupOptions<ActivateUserDto> {
}

export class ActivateForm extends ExtendedFormGroup<ActivateUserDto> {
  constructor(options: ActivateFormOptions = { }) {
    super({
      ...options,
      controls: {
        activationToken: new HiddenField(),
        firstname: new TextField({
          required: true,
          maxLength: UserConstants.firstnameMaxLength
        }),
        lastname: new TextField({
          required: true,
          maxLength: UserConstants.lastnameMaxLength
        }),
        ...PASSWORD_REPEAT_FIELDS,
        ...options.controls
      },
      validators: [
        UserValidator.requirePasswordRepeated(),
        ...(options.validators || [])
      ],
      errorMessages: DEFAULT_ERROR_MESSAGES
    });
  }
}
