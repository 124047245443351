import { Injectable } from '@angular/core';
import {
  AddMovePositionDto,
  CreateOfferConstructionProjectDto,
  CreateOfferCustomerDto,
  OfferCustomCostsDto,
  OfferDto,
  OfferPositionDto,
  OffersApiService,
  OfferVersionDto,
  OrderDto,
  RecalculateOfferDto,
  TransferOfferDto
} from '@api/api.service';
import { ReceiptService } from '../base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AntCollectionSortLoadingParams } from '../../../models';
import {
  ApiResponseUtils,
  LoadingParamsUtils
} from '../../../utils';
import { AntDataResponse } from '@antony/ng-ui';

@Injectable({
  providedIn: 'root'
})
export class OfferService extends ReceiptService<OfferDto, OfferVersionDto, OfferPositionDto> {
  constructor(protected api: OffersApiService) {
    super(api);
  }

  createFromCustomer(dto: CreateOfferCustomerDto): Observable<OfferDto> {
    return this.api.createFromCustomer(dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  createFromConstructionProject(dto: CreateOfferConstructionProjectDto): Observable<OfferDto> {
    return this.api.createFromConstructionProject(dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  transfer(offerId: number, dto: TransferOfferDto): Observable<OrderDto> {
    return this.api.transferReceipt(offerId, dto).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  addMovePosition(offerId: number, dto: AddMovePositionDto): Observable<OfferVersionDto> {
    return this.api.addMovePosition(dto, offerId).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  getTransferredOrders(params: AntCollectionSortLoadingParams<OrderDto>): Observable<AntDataResponse<OrderDto>> {
    return this.api.getTransferredOrders(
      LoadingParamsUtils.getIdFrom(params),
      LoadingParamsUtils.getSkipFrom(params),
      LoadingParamsUtils.getTakeFrom(params),
      LoadingParamsUtils.getFilterNamesFrom(params),
      LoadingParamsUtils.getFilterValueFrom(params),
      LoadingParamsUtils.getSortNamesFrom(params),
      LoadingParamsUtils.getApiSortDirection(params)
    ).pipe(
      map(ApiResponseUtils.getAntDataResponseFromResponse)
    );
  }

  addCustomCosts(offerId: number, dto: OfferCustomCostsDto): Observable<OfferVersionDto> {
    return this.api.addCustomCosts(dto, offerId).pipe(
      map(res => res.data as OfferVersionDto)
    );
  }

  public recalculateOffer(offerId: number, recalculateData: RecalculateOfferDto): Observable<OfferDto | null> {
    return this.api.recalculateOffer(offerId, recalculateData).pipe(
      map(data => data.data)
    );
  }
}
