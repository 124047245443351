import { Component, OnInit } from '@angular/core';
import { PasswordResetForm } from '@core/authentication/models/password-reset.form';
import { PasswordResetService } from '@core/authentication/services/password-reset.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  private static readonly TOKEN_PARAMETER_NAME = 't';

  labelHideBreakpoints: string[] = [];

  resetForm = new PasswordResetForm();
  showPassword = false;
  showPasswordRepeat = false;
  private submitted = false;

  constructor(private api: PasswordResetService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const formValue = this.resetForm.value;
    if (this.resetForm.invalid || this.hasSubmitted() || !formValue) {
      return;
    }

    this.submitted = true;
    formValue.token = this.getTokenFromUrl();
    this.api.resetPassword(formValue).pipe(
      finalize(() => this.submitted = false)
    ).subscribe();
  }

  hasSubmitted(): boolean {
    return this.submitted;
  }

  private getTokenFromUrl(): string | null {
    const snapshot = this.activatedRoute.snapshot;
    const paramMap = snapshot.queryParamMap;
    if (!paramMap.has(PasswordResetComponent.TOKEN_PARAMETER_NAME)) {
      return null;
    }
    return paramMap.get(PasswordResetComponent.TOKEN_PARAMETER_NAME);
  }
}
