import { Injectable } from '@angular/core';
import { DeliveryNoteVersionApiService, DeliveryNoteVersionDto } from '@api/api.service';
import { ReceiptVersionService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteVersionService extends ReceiptVersionService<DeliveryNoteVersionDto> {
  constructor(protected api: DeliveryNoteVersionApiService) {
    super(api);
  }
}
