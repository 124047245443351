import { OfferDto } from '@api';
import { CONSTRUCTION_PROJECTS } from '@app/mock/data/construction-projects';
import { OFFER_VERSIONS } from '@app/mock/data/offer_versions';

export const OFFERS: OfferDto[] = [
  {
    id: 1,
    constructionProjectId: 1,
    constructionProject: CONSTRUCTION_PROJECTS[1],
    isReadOnly: false,
    latestVersion: OFFER_VERSIONS[0],
    number: 'ANG-123-1',
    createdBy: null,
    createdById: 1,
    created: null,
    lastChange: null
  }
];
