import { AbstractControl, ValidatorFn } from '@angular/forms';
import {
  HasPermissionPipe,
  HasPermissionService
} from '@shared/permissions';
import { APP_INJECTOR } from '@core/data/app-injector';

export class AppValidators {
  /**
   * @description checks if both fields have the same value. if not fieldB gets an error
   */

  private permissionService: HasPermissionService = APP_INJECTOR.get(HasPermissionService);

  public static equalValue(pathA: string, pathB: string): ValidatorFn {
    return (ctrl: AbstractControl) => {
      const fieldA = ctrl.get(pathA);
      const fieldB = ctrl.get(pathB);

      if (!fieldA || !fieldB) {
        return null;
      }

      if (fieldA.value !== fieldB.value) {
        fieldB.setErrors({
          equalValue: fieldA.value
        });
      }

      return null;
    };
  }

  static notEqualValue(pathA: string, pathB: string): ValidatorFn {
    return (ctrl: AbstractControl) => {
      const fieldA = ctrl.get(pathA);
      const fieldB = ctrl.get(pathB);

      if (!fieldA || !fieldB) {
        return null;
      }

      if (fieldA.value === fieldB.value) {
        fieldB.setErrors({
          notEqualValue: fieldA.value
        });
      }

      return null;
    };
  }

  public static disableIfHasKey(key: string = 'id'): ValidatorFn {
    return (ctrl: AbstractControl) => {
      const idField = ctrl.parent?.get(key);
      if (!!idField?.value) {
        ctrl.disable({
          onlySelf: true,
          emitEvent: false
        });
      }
      return null;
    };
  }

  public static disableIfHasId(): ValidatorFn {
    return this.disableIfHasKey('id');
  }


  static requirePermission(permission: string): ValidatorFn {
    return (ctrl: AbstractControl) => {
      const permissionService: HasPermissionService = APP_INJECTOR.get(HasPermissionService);
      if (!permission || !ctrl) {
        return null;
      }

      if (!permissionService.hasPermissionInstant(permission)) {
        ctrl.disable({
          onlySelf: true,
          emitEvent: false
        });
      }

      return null;
    };
  }
}
