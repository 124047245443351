import { Injectable } from '@angular/core';
import { AuthApiService, PermissionDto, PermissionTranslationDto } from '@api';
import { EMPTY, from, Observable, Subject } from 'rxjs';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private permissions$: Observable<PermissionDto[]> = EMPTY;
  private permissions: PermissionDto[] = [];

  constructor(private api: AuthApiService) {
    this.initRequest();
  }

  static getPermissionTitle(permission: PermissionDto): string {
    const translation = this.getPermissionTranslation(permission);
    return translation?.title;
  }

  static getPermissionDescription(permission: PermissionDto): string {
    const translation = this.getPermissionTranslation(permission);
    return translation?.description;
  }

  private static getPermissionTranslation(permission: PermissionDto): PermissionTranslationDto {
    const language = 'de-DE';
    return permission.translations[language];
  }

  getAll(): Observable<PermissionDto[]> {
    return this.permissions$;
  }

  getAllInstant(): PermissionDto[] {
    return this.permissions;
  }

  getSingle(targetPermission: string | null): Observable<PermissionDto | null> {
    return this.permissions$.pipe(
      switchMap(permissions => from(permissions)),
      first(permission => permission.key === targetPermission, null)
    );
  }

  getSingleInstant(targetPermission: string | null): PermissionDto | null {
    const all = this.getAllInstant();
    return all.find(permission => permission.key === targetPermission) || null;
  }

  private initRequest(): void {
    const apiResult$ = this.api.getPermissions();
    this.permissions$ = apiResult$.pipe(
      map(res => res.data || []),
      tap(permissions => this.permissions = permissions),
      shareReplay()
    );
  }
}
