import { ApiResponseOfListOfOfferDto, ApiSortDirection, IConstructionProjectOffersApiService, OfferDto } from '@api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudBase } from '@app/mock/models';
import { OFFERS } from '@app/mock/data/offers';

@Injectable()
export class ConstructionProjectOfferApiMockService extends CrudBase<OfferDto> implements IConstructionProjectOffersApiService {

  constructor() {
    super(OFFERS);
  }

  getAllOffers(constructionProjectId: number, skip?: number, take?: number, filterName?: string[] | null, filterValue?: string | null,
               sortName?: string[] | null, sortDirection?: ApiSortDirection): Observable<ApiResponseOfListOfOfferDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

}
