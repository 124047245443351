import { Injectable } from '@angular/core';
import { AntDataResponse } from '@antony/ng-ui';
import { ApiResponseUtils, DeliveryNoteApiService, DeliveryNoteDto } from '@core/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface GetUnprocessedDeliveryNotesService {
  getData(constructionProjectId?: number, ignoredInvoiceId?: number): Observable<AntDataResponse<DeliveryNoteDto>>;
}

@Injectable({
  providedIn: 'root',
})
export class OrderUnprocessedDeliveryNotesService implements GetUnprocessedDeliveryNotesService {

  constructor(protected readonly api: DeliveryNoteApiService) {
  }

  getData(constructionProjectId?: number, ignoredInvoiceId?: number): Observable<AntDataResponse<DeliveryNoteDto>> {
    return this.api.loadUnprocessed(constructionProjectId, ignoredInvoiceId).pipe(
      map(ApiResponseUtils.getAntDataResponseFromResponse),
    );
  }

}
