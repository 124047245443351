import { Injectable } from '@angular/core';
import { AuthApiService, ForgotPasswordDto } from '@api';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationService } from '@antony/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PasswordForgotService {

  constructor(private api: AuthApiService,
              private notification: NotificationService,
              private translate: TranslateService) {
  }

  passwordForgot(dto: ForgotPasswordDto): Observable<boolean> {
    return this.api.forgotPassword(dto).pipe(
      map(res => res.success),
      tap(() => this.notifySuccess(), () => this.notifyFailure())
    );
  }

  private notifySuccess(): void {
    const message = 'AUTHENTICATION.PASSWORD_FORGOT.NOTIFICATIONS.SUCCESS.MESSAGE';
    this.notification.openLowPriority({
      message: this.translate.instant(message)
    });
  }

  private notifyFailure(): void {
    const message = 'AUTHENTICATION.PASSWORD_FORGOT.NOTIFICATIONS.FAILURE.MESSAGE';
    const submitLabel = 'AUTHENTICATION.PASSWORD_FORGOT.NOTIFICATIONS.FAILURE.OK';
    this.notification.openMediumPriority({
      message: this.translate.instant(message),
      submitActionLabel: this.translate.instant(submitLabel)
    });
  }
}
