import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxjsUtils } from '@app/core';
import { from, NEVER, of, Subscription } from 'rxjs';
import { AuthenticationService, LoginResponseType } from '../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, exhaustMap, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  loginForm: FormGroup | null = null;
  submitted = false;
  submitting = false;
  showPassword = false;
  labelHideBreakpoints: string[] = [];

  constructor(private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthenticationService) {
  }

  get controls() {
    return this.loginForm?.controls;
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['dashboard']);
    }

    this.checkForceRefresh();

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  toggleVisibilityOfPassword() {
    this.showPassword = !this.showPassword;
  }

  submitForm(): void {
    if (!this.loginForm) {
      return;
    }

    this.submitted = true;
    this.submitting = true;
    const username = this.loginForm.get('username')?.value;
    const password = this.loginForm.get('password')?.value;

    this.authService.authenticate(username, password)
        .pipe(
          take(1),
          finalize(() => {
            this.checkForceRefresh();
          }),
        ).subscribe((loginResult: LoginResponseType) => {
            this.submitting = false;
            let err = { };

            if (this.loginForm) {
              this.loginForm.enable();
            }

            switch (loginResult) {
              case 'success':
                this.router.navigate(['dashboard']);
                break;
              case 'invalid':
                err = { wrongCredentials: true };
                break;
              case 'error':
                break;
              case 'timeout':
                break;
              case 'disabled':
                err = { disabled: true };
                break;
              default:
                break;
            }
            this.controls?.password.setErrors(err);
        });
  }

  private checkForceRefresh(): void {
    this.activatedRoute.queryParams.pipe(
      take(1),
      map((params): string => params['forceRefresh']),
      filter(RxjsUtils.isNotNullOrUndefined),
      tap(async forceRefresh => {
        // NOTE: queryParams are key-value pairs of strings
        if (forceRefresh === 'true') {
          await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {},
          });
          window.location.reload();
        }
      }),
    ).subscribe();
  }
}
