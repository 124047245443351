import { VehicleDto } from '@api';

const baseVehicle: VehicleDto = {
  id: 0,
  created: null,
  lastChange: null,
  name: '',
  licensePlate: '',
  driverName: '',
  driverMail: '',
  driverPhone: '',
  comment: null,
  isExternal: false,
  externalMailAddress: null,
};

export const VEHICLES: VehicleDto[] = [
  {
    ...baseVehicle,
    id: 1,
    name: 'Vehicle A',
    licensePlate: 'BOH-VA-123',
    driverName: 'Max Mustermann',
    driverMail: 'max@example.com',
    driverPhone: '+49 1234 567890'
  },
  {
    ...baseVehicle,
    id: 2,
    name: 'Vehicle B',
    licensePlate: 'BOH-VB-123',
    driverName: 'Mia Mustermann',
    driverMail: 'mia@example.com',
    driverPhone: '+49 1234 567899'
  }
];
