import { ApiSortDirection, ContactPersonDto, CustomerContactPersonDto, ICustomerContactPersonsApiService } from '@api';
import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { NEVER, Observable } from 'rxjs';
import { CONTACT_PERSONS } from '@app/mock/data/contact-persons';

@Injectable()
export class CustomerContactPersonApiMockService extends CrudBase<ContactPersonDto>
  implements ICustomerContactPersonsApiService {

  constructor() {
    super(CONTACT_PERSONS);
  }

  getPagedContactPersons(customerId: number, skip?: number, take?: number, filterName?: string[], filterValue?: string,
                         sortName?: string[], sortDirection?: ApiSortDirection)
    : Observable<GenericApiResponse<ContactPersonDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  createContactPerson(customerId: number, contactPersonId: number)
    : Observable<GenericApiResponse<CustomerContactPersonDto>> {
    return NEVER;
  }

  deleteContactPerson(customerId: number, contactPersonId: number)
    : Observable<GenericApiResponse<CustomerContactPersonDto>> {
    return NEVER;
  }

  deleteBulkContactPersons(customerId: number, contactPersonId?: number[])
    : Observable<GenericApiResponse<GenericApiResponse<CustomerContactPersonDto>[]>> {
    return NEVER;
  }

}
