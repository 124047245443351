import { Injectable } from '@angular/core';
import { BreadcrumbService as AntBreadcrumbService } from '@antony/core';
import { Breadcrumb } from '@core/models';

@Injectable()
export class BreadcrumbService {
    constructor(private storage: AntBreadcrumbService) { }

    getAll(): readonly Breadcrumb[] {
      return this.storage.getBreadcrumbs();
    }

    getTopBreadcrumb(): Breadcrumb | null {
      const breadcrumbs = this.getAll();
      if (breadcrumbs.length < 1) {
        return null;
      }
      return breadcrumbs[breadcrumbs.length - 1];
    }
}
