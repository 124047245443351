export class AppConstants {
  public static readonly integerMax: number = Math.pow(2, 31) - 1;
  public static readonly timeSpanMax: number = 50280;
  public static readonly numbersOnlyRegex: string = '^\\d*$';

  public static readonly defaultRevenueAccount: string = '8400';

  public static readonly maximumPageSize = 100;
  public static readonly defaultPageSize = 10;
}
