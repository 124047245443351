import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { ApiSortDirection, IPaymentConditionsApiService, PaymentConditionDto } from '@api';
import { PAYMENT_CONDITIONS } from '@app/mock/data/paymen_conditions';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class PaymentConditionMockService extends CrudBase<PaymentConditionDto> implements IPaymentConditionsApiService {
  constructor() {
    super(PAYMENT_CONDITIONS);
  }

  getPagedPaymentConditions(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                            sortName?: string[], sortDirection?: ApiSortDirection): Observable<GenericApiResponse<PaymentConditionDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getPaymentCondition(id: number): Observable<GenericApiResponse<PaymentConditionDto>> {
    return this.get(id);
  }

  createPaymentCondition(dto: PaymentConditionDto): Observable<GenericApiResponse<PaymentConditionDto>> {
    return this.create(dto);
  }

  updatePaymentCondition(id: number, dto: PaymentConditionDto): Observable<GenericApiResponse<PaymentConditionDto>> {
    return this.update(id, dto);
  }

  deletePaymentCondition(id: number): Observable<GenericApiResponse<PaymentConditionDto>> {
    return this.delete(id);
  }

  deleteBulkPaymentConditions(ids?: number[]): Observable<GenericApiResponse<GenericApiResponse<PaymentConditionDto>[]>> {
    return this.deleteBulk(ids || []);
  }
}
