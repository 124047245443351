import { CrudBase, GenericApiResponse } from '@app/mock/models';
import {
  AddMovePositionDto,
  ApiResponseOfDownloadReportDto,
  ApiResponseOfIEnumerableOfOfferDto,
  ApiResponseOfIEnumerableOfOrderDto,
  ApiResponseOfListOfOfferVersionDto,
  ApiResponseOfOfferDto,
  ApiResponseOfOfferPositionDto,
  ApiResponseOfOfferVersionDto,
  ApiResponseOfOrderDto,
  ApiSortDirection,
  CreateOfferConstructionProjectDto,
  CreateOfferCustomerDto,
  DefaultPositionCalculationDto,
  FileResponse,
  IOffersApiService,
  OfferCustomCostsDto,
  OfferDto,
  OfferVersionDto,
  RecalculateOfferDto,
  ReceiptStatusUpdateDto
} from '@api';
import { OFFERS } from '@app/mock/data/offers';
import { NEVER, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class OffersApiMockService extends CrudBase<OfferDto> implements IOffersApiService {
  constructor() {
    super(OFFERS);
  }

  updateStatus(updateDto: ReceiptStatusUpdateDto): Observable<ApiResponseOfListOfOfferVersionDto> {
    return NEVER;
  }

  addCustomCosts(dto: OfferCustomCostsDto, offerId: number): Observable<ApiResponseOfOfferVersionDto> {
    return NEVER;
  }

  getPagedReceipts(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                   sortName?: string[], sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfOfferDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getReceipt(id: number): Observable<GenericApiResponse<OfferDto>> {
    return this.get(id);
  }

  updateReceipt(offerId: number, dto: OfferVersionDto): Observable<GenericApiResponse<OfferVersionDto>> {
    return NEVER;
  }

  requestReportDownload(offerId: number): Observable<ApiResponseOfDownloadReportDto> {
    return NEVER;
  }

  downloadReport(token?: string | null | undefined): Observable<FileResponse> {
    return NEVER;
  }

  getTransferredOrders(offerId: number, skip?: number, take?: number, filterName?: string[], filterValue?: string,
                       sortName?: string[], sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfOrderDto> {
    return NEVER;
  }

  transferReceipt(offerId: number): Observable<ApiResponseOfOrderDto> {
    return NEVER;
  }

  createFromConstructionProject(dto: CreateOfferConstructionProjectDto): Observable<ApiResponseOfOfferDto> {
    return NEVER;
  }

  createFromCustomer(dto: CreateOfferCustomerDto): Observable<ApiResponseOfOfferDto> {
    return NEVER;
  }

  addMovePosition(dto: AddMovePositionDto, offerId: number): Observable<ApiResponseOfOfferVersionDto> {
    return NEVER;
  }

  recalculateOffer(offerId: number, recalculateDto: RecalculateOfferDto): Observable<ApiResponseOfOrderDto> {
    return NEVER;
  }

  calculatePositionDefaults(receiptId: number, dto: DefaultPositionCalculationDto): Observable<ApiResponseOfOfferPositionDto> {
    return NEVER;
  }
}
