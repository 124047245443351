import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UserForm } from '@app/user/models/user.form';

export class UserValidator {
  public static requireChange(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const password = form.get('password') as FormControl;
      const passwordChangeRequired = form.get('isPasswordChangeRequired') as FormControl;

      if (passwordChangeRequired.value && (password.pristine || !password.value)) {
        password.setErrors({ required: true});
      }
      return null;
    };
  }

  public static shouldUpdatePasswordIfChanged(): ValidatorFn {
    return (form: AbstractControl): null => {
      const password = form.get('password') as FormControl;
      const updatePassword = form.get('updatePassword') as FormControl;

      if (!password.pristine && password.value) {
        updatePassword.setValue(true, {
          emitEvent: false,
          emitModelToViewChange: false,
          emitViewToModelChange: false,
          onlySelf: true
        });
      }
      return null;
    };
  }

  static requirePasswordRepeated(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const password = form.get('password') as FormControl;
      const repeatPassword = form.get('passwordRepeat') as FormControl;

      if (!password || !repeatPassword) {
        return null;
      }

      if (password.value && password.value !== repeatPassword.value) {
        repeatPassword.setErrors({
          passwordsDifferent: true
        });
      } else if (repeatPassword && repeatPassword.errors && repeatPassword.errors.passwordsDifferent) {
        repeatPassword.setErrors(null);
      }
      return null;
    };
  }
}
