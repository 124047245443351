import { Provider } from '@angular/core';
import {
  AmountUnitsApiService,
  ArticlesApiService,
  AuthApiService,
  CountriesApiService,
  DeliveryConditionApiService,
  PaymentConditionsApiService,
  RapsApiService,
  TimeUnitsApiService,
  UsersApiService
} from '@api';
import { AmountUnitApiMockService } from '@app/mock/api/amount-unit.api-mock.service';
import { RapApiMockService } from '@app/mock/api/rap';
import { RECEIPT_PROVIDER } from '@app/mock/api/receipts';
import { SETTINGS_PROVIDER } from '@app/mock/api/settings';
import { TimeUnitApiMockService } from '@app/mock/api/time-unit.api-mock.service';
import { VEHICLE_TYPE_PROVIDERS } from '@app/mock/api/vehicle-type';
import { ArticlesApiMockService } from './articles-api-mock.service';
import { AuthApiMockService } from './auth-api-mock.service';
import { CONSTRUCTION_PROJECTS_PROVIDER } from './construction-project';
import { CONTACT_PERSON_PROVIDER } from './contact-person';
import { CountriesApiMockService } from './countries-api-mock.service';
import { CUSTOMER_PROVIDER } from './customer';
import { DeliveryConditionsApiMockService } from './delivery-conditions.api-mock.service';
import { PaymentConditionMockService } from './payment-condition-mock.service';
import { UsersApiMockService } from './users-api-mock.service';
import { VEHICLE_PROVIDERS } from '@app/mock/api/vehicle';

export const API_PROVIDER: Provider[] = [
  ...CONSTRUCTION_PROJECTS_PROVIDER,
  ...CONTACT_PERSON_PROVIDER,
  ...CUSTOMER_PROVIDER,
  ...RECEIPT_PROVIDER,
  ...VEHICLE_PROVIDERS,
  ...VEHICLE_TYPE_PROVIDERS,
  ...SETTINGS_PROVIDER,
  { provide: AmountUnitsApiService, useClass: AmountUnitApiMockService},
  { provide: ArticlesApiService, useClass: ArticlesApiMockService},
  { provide: AuthApiService, useClass: AuthApiMockService},
  { provide: CountriesApiService, useClass: CountriesApiMockService},
  { provide: DeliveryConditionApiService, useClass: DeliveryConditionsApiMockService},
  { provide: PaymentConditionsApiService, useClass: PaymentConditionMockService},
  { provide: TimeUnitsApiService, useClass: TimeUnitApiMockService},
  { provide: UsersApiService, useClass: UsersApiMockService},
  { provide: RapsApiService, useClass: RapApiMockService }
];
