import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SimpleDialogConfig } from '../simple-dialog/simple-dialog.component';

export interface MarkdownDialogConfig extends SimpleDialogConfig {
  messageData?: any;
}

@Component({
  selector: 'app-markdown-dialog',
  templateUrl: './markdown-dialog.component.html',
  styleUrls: ['./markdown-dialog.component.scss']
})
export class MarkdownDialogComponent {

  public readonly data: Required<MarkdownDialogConfig>;

  constructor(private matDialogRef: MatDialogRef<MarkdownDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              data: MarkdownDialogConfig) {
    this.data = {
      dismissActionName: 'NO',
      submitActionName: 'YES',
      messageData: {},
      ...data,
    };
  }

  dismiss() {
    this.matDialogRef.close(false);
  }

  submit() {
    this.matDialogRef.close(true);
  }
}
