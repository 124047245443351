import { Injectable } from '@angular/core';
import {
  DigitLimitType,
  PlateCountStatisticsApiService
} from '@api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlateCountStatisticsService {

  constructor(private plateCountApi: PlateCountStatisticsApiService) { }

  loadPlateCounts(zipCodeDigitLimit: DigitLimitType): Observable<{ zipCodePrefix: string, plateCount: number }[]> {
    return this.plateCountApi.loadPlateCounts(this.mapZipCodeLimitNumber(zipCodeDigitLimit)).pipe(
      map(resultData => resultData.data)
    );
  }

  private mapZipCodeLimitNumber(zipCodeLimit: DigitLimitType): number {
    switch (zipCodeLimit) {
      case 'first':
        return 1;
      case 'first-two':
        return 2;
      case 'first-three':
        return 3;
      default:
        return 2;
    }
  }
}
