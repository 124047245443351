import { Injectable } from '@angular/core';
import { AuthApiService, ResetPasswordDto } from '@api';
import { Observable } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationResult, NotificationService } from '@antony/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PasswordResetService {

  constructor(private api: AuthApiService,
              private router: Router,
              private notification: NotificationService,
              private translate: TranslateService) {
  }

  resetPassword(dto: ResetPasswordDto): Observable<boolean> {
    const response$ = this.sendDataToApi(dto);
    return response$.pipe(
      tap(
        () => this.sendSuccessNotification(),
        () => this.sendFailureNotification()
      ),
      finalize(() => this.router.navigate(['auth', 'login']))
    );
  }

  private sendDataToApi(dto: ResetPasswordDto): Observable<boolean> {
    return this.api.resetPassword(dto).pipe(
      map(res => res.success)
    );
  }

  private sendSuccessNotification(): void {
    this.notification.openLowPriority({
      message: this.translate.instant('AUTHENTICATION.PASSWORD_RESET.NOTIFICATIONS.SUCCESS.MESSAGE')
    });
  }

  private sendFailureNotification() {
    const notificationRef = this.notification.openMediumPriority({
      message: this.translate.instant('AUTHENTICATION.PASSWORD_RESET.NOTIFICATIONS.FAILURE.MESSAGE'),
      submitActionLabel: this.translate.instant('AUTHENTICATION.PASSWORD_RESET.NOTIFICATIONS.FAILURE.RETRY')
    });

    notificationRef.afterFinished().pipe(
      filter(result => result === NotificationResult.SUBMITTED),
      tap(() => this.router.navigate(['auth', 'forgot']))
    ).subscribe();
  }
}
