import { from, Observable, of, throwError } from 'rxjs';
import { ApiException, ApiSortDirection, BaseDto } from '@api';
import { concatMap, map, toArray } from 'rxjs/operators';
import { GenericApiResponse } from '@app/mock/models/generic-api-response';

export abstract class CrudBase<T extends BaseDto> {

  protected constructor(private readonly items: T[]) {  }

  getRange(skip?: number, take?: number, filterName?: string[] | null, filterValue?: string | null,
           sortName?: string[] | null, sortDirection?: ApiSortDirection): Observable<GenericApiResponse<T[]>> {
    skip = skip || 0;
    take = take || 20;
    const data = this.items.filter(item => {
      if (filterValue === undefined || filterValue === null || filterValue === '') {
        return true;
      }
      return Object
        .values<any>(item)
        .filter(v => v !== null)
        .some(value => value.toString().includes(filterValue))
        ;
    }).slice(skip, skip + take);
    return of({
      success: true,
      message: null,
      count: data.length,
      maxCount: this.items.length,
      data
    });
  }

  get(id: number): Observable<GenericApiResponse<T>> {
    const item = this.items.find(u => u.id === id);
    if (!item) {
      return this.throwNotFoundException();
    }
    return of(this.createApiResponse(item));
  }

  create(item: T): Observable<GenericApiResponse<T>> {
    const newItem = {
      ...item,
      id: this.items.length
    };

    this.items.push(newItem);
    return of(this.createApiResponse(newItem));
  }

  update(id: number, item: T): Observable<GenericApiResponse<T>> {
    const idx = this.items.findIndex(u => u.id === id);

    if (idx < 0) {
      return this.throwNotFoundException();
    }

    this.items[idx] = {
      ...this.items[idx],
      ...item
    };
    return of(this.createApiResponse(this.items[idx]));
  }

  delete(id: number): Observable<GenericApiResponse<T>> {
    const idx = this.items.findIndex(u => u.id === id);

    if (idx < 0) {
      return this.throwNotFoundException();
    }

    const item = this.items[idx];
    this.items.splice(idx, 1);
    return of(this.createApiResponse(item));
  }

  deleteBulk(ids: number[]): Observable<GenericApiResponse<GenericApiResponse<T>[]>> {
    return from(ids).pipe(
      concatMap(id => this.delete(id)),
      toArray(),
      map((responses: GenericApiResponse<T>[]) => ({
        success: true,
        message: null,
        data: responses,
        count: responses.length,
        maxCount: responses.length
      }))
    );
  }

  protected createApiResponse<U>(data: U): GenericApiResponse<U> {
    return {
      data,
      success: true,
      message: null,
      count: null,
      maxCount: null
    };
  }

  private throwNotFoundException(): Observable<any> {
    const exception = this.createApiException(404, 'NOT FOUND');
    return throwError(exception);
  }

  private createApiException(code: number, message: string): ApiException {
    return new ApiException(message, code, '', {}, null);
  }
}
