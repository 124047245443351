import { ApiSortDirection, CustomerContactPersonDto, CustomerDto, IContactPersonCustomersApiService } from '@api';
import { Injectable } from '@angular/core';
import { CrudBase, GenericApiResponse } from '@app/mock/models';
import { NEVER, Observable } from 'rxjs';
import { CUSTOMERS } from '@app/mock/data/customers';

@Injectable()
export class ContactPersonCustomerApiMockService extends CrudBase<CustomerDto>
  implements IContactPersonCustomersApiService {

  constructor() {
    super(CUSTOMERS);
  }

  getPagedCustomers(contactPersonId: number, skip?: number, take?: number, filterName?: string[], filterValue?: string,
                    sortName?: string[], sortDirection?: ApiSortDirection)
    : Observable<GenericApiResponse<CustomerDto[]>> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  createCustomer(contactPersonId: number, customerId: number)
    : Observable<GenericApiResponse<CustomerContactPersonDto>> {
    return NEVER;
  }

  deleteCustomer(contactPersonId: number, customerId: number)
    : Observable<GenericApiResponse<CustomerContactPersonDto>> {
    return NEVER;
  }

  deleteBulkCustomers(contactPersonId: number, customerId?: number[])
    : Observable<GenericApiResponse<GenericApiResponse<CustomerContactPersonDto>[]>> {
    return NEVER;
  }


}
