import { GenericApiResponse } from '@app/mock/models';
import { AntDataResponse } from '@antony/ng-ui';
import { Observable } from 'rxjs';
import { ApiErrorUtils } from './api-error.utils';

export class ApiResponseUtils {

  public static getSuccessFromResponse<T>(res: GenericApiResponse<T>): boolean {
    return res?.success;
  }


  public static getDataFromResponse<T>(res: GenericApiResponse<T>): T {
    return res?.data as T;
  }

  public static getAntDataResponseFromResponse<T>(res: GenericApiResponse<T[]>): AntDataResponse<T> {
    return {
      data: res?.data || [],
      maxCount: res?.maxCount || 0
    };
  }

  public static getSuccessesFromResponseOfResponse<T>(responseOfResponses: GenericApiResponse<GenericApiResponse<T>[]>): boolean[] {
    const responses = ApiResponseUtils.getDataFromResponse(responseOfResponses) || [];
    return responses.map(response => ApiResponseUtils.getSuccessFromResponse(response));
  }
}
