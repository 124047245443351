import { CrudBase } from '@app/mock/models';
import {
  AmountUnitDto,
  ApiResponseOfAmountUnitDto,
  ApiResponseOfIEnumerableOfAmountUnitDto,
  ApiSortDirection,
  IAmountUnitsApiService
} from '@api';
import { AMOUNT_UNITS } from '@app/mock/data/amount-units';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AmountUnitApiMockService extends CrudBase<AmountUnitDto> implements IAmountUnitsApiService {
  constructor() {
    super(AMOUNT_UNITS);
  }

  getPagedAmountUnits(skip?: number, take?: number, filterName?: string[], filterValue?: string,
                      sortName?: string[], sortDirection?: ApiSortDirection): Observable<ApiResponseOfIEnumerableOfAmountUnitDto> {
    return this.getRange(skip, take, filterName, filterValue, sortName, sortDirection);
  }

  getAmountUnit(id: number): Observable<ApiResponseOfAmountUnitDto> {
    return this.get(id);
  }
}
