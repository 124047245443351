import {
  AntDataResponse,
  AntSortLoadingParams
} from '@antony/ng-ui';
import {
  EMPTY,
  Observable,
  of
} from 'rxjs';
import {
  map,
  mapTo,
  switchMap,
  switchMapTo
} from 'rxjs/operators';
import {
  CreateApiService,
  GetApiService,
  GetDataApiService,
  ReceiptStatusUpdateDto,
  UpdateApiService
} from '@api/models';
import { LoadingParamsUtils } from '@api/utils';
import { BaseReceiptDto } from './base-receipt.dto';
import { BaseReceiptVersionDto } from './base-receipt-version.dto';
import { ReceiptApiService } from './receipt-api-service';
import {
  BaseReceiptPositionDto,
  DefaultPositionCalculationDto,
  DownloadReportDto,
  ReportMode,
  UpdateReceiptStatusApiService
} from '@core/api';

export abstract class ReceiptService<T extends BaseReceiptDto<V>,
  V extends BaseReceiptVersionDto<P>,
  P extends BaseReceiptPositionDto> implements GetDataApiService<T>,
  GetApiService<T>,
  CreateApiService<V>,
  UpdateApiService<V>,
  UpdateReceiptStatusApiService {

  protected constructor(protected api: ReceiptApiService<T, V, P>) {
  }

  getData(params: Partial<AntSortLoadingParams<T>>): Observable<AntDataResponse<T>> {
    const paramsAsArray = LoadingParamsUtils.transformToArray(params);
    return this.api.getPagedReceipts(...paramsAsArray).pipe(
      map(res => ({
        data: res.data || [],
        maxCount: res.maxCount || 0
      }))
    );
  }

  get(resourceId: number): Observable<T> {
    return this.api.getReceipt(resourceId).pipe(
      map(res => res.data as T)
    );
  }

  create(dto: V): Observable<V> {
    return this.update(dto.receiptId || 0, dto);
  }

  update(resourceId: number, dto: V): Observable<V> {
    return this.api.updateReceipt(resourceId, dto).pipe(
      map(res => res.data as V)
    );
  }

  downloadReport(receiptId: number,
                 documentMode?: ReportMode,
                 attachAgb?: boolean,
                 attachImportantNotes?: boolean): Observable<string> {
    return this.api.requestReportDownload(receiptId, documentMode, attachAgb, attachImportantNotes).pipe(
      map(res => res?.data as DownloadReportDto),
      map(data => data?.url)
    );
  }

  calculatePositionDefaults(receiptId: number, dto: DefaultPositionCalculationDto): Observable<P | null> {
    return this.api.calculatePositionDefaults(receiptId, dto).pipe(
      map(data => data.data)
    );
  }


  updateReceiptStatus(multiStatusUpdate: ReceiptStatusUpdateDto): Observable<void> {
    return this.api.updateStatus(multiStatusUpdate).pipe(
      mapTo(undefined)
    );
  }
}
