import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ActivateComponent } from '@core/authentication/activate/activate.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { AuthenticationComponent } from '@core/authentication/authentication/authentication.component';
import { PasswordResetComponent } from '@core/authentication/password-reset/password-reset.component';

const routes: Routes = [
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'activate',
        component: ActivateComponent
      },
      {
        path: 'forgot',
        component: PasswordForgotComponent
      },
      {
        path: 'reset',
        component: PasswordResetComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth/login',
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {
}
