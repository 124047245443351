import { Injectable } from '@angular/core';
import { AntDataResponse } from '@antony/ng-ui';
import {
  AddCleaningPositionDto,
  AddMovePositionDto,
  AddRollingPositionDto,
  AddTheftProtectionPositionDto,
  AddUnsuccessfullDeliveryPositionDto,
  AddWaitingTimePositionDto,
  CreditNoteDto,
  InvoicesApiService,
  InvoiceDto,
  InvoicePositionDto,
  InvoiceStatusDto,
  InvoiceVersionDto,
  TransferInvoiceDto
} from '@api/api.service';
import { AntCollectionSortLoadingParams, BaseReceiptSystemPropertiesDto } from '@api/models';
import { ApiResponseUtils, LoadingParamsUtils } from '@api/utils';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { ReceiptService } from '../base';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends ReceiptService<InvoiceDto, InvoiceVersionDto, InvoicePositionDto> {
  constructor(protected api: InvoicesApiService) {
    super(api);
  }

  addUnsuccessfulDeliveryPositions(dto: AddUnsuccessfullDeliveryPositionDto, invoiceId: number): Observable<boolean> {
    return this.api
    .addUnsuccessfullDeliveryPositions(dto, invoiceId)
    .pipe(
      map((res: any) => {
        return !!res;
      })
    );
  }

  addWaitingTime(invoiceId: number, waitingTimePosition: AddWaitingTimePositionDto): Observable<boolean> {
    return this.api.addWaitingTimePosition(waitingTimePosition, invoiceId)
      .pipe(
        mapTo(true)
      );
  }

  releaseInvoice(invoiceId: number): Observable<InvoiceVersionDto | null> {
    return this.api
      .release(invoiceId)
      .pipe(
        catchError(err => of(null)),
        map((result) => {
          if (!result) {
            return null;
          } else {
            return result.data as InvoiceVersionDto;
          }
        })
      );
  }

  addRollingPosition(invoiceId: number, rollingPositionDto: AddRollingPositionDto): Observable<InvoiceVersionDto> {
    return this.api
               .addRollingPosition(rollingPositionDto, invoiceId)
               .pipe(map((result) => result.data as InvoiceVersionDto));
  }

  addCleaningPosition(invoiceId: number, cleaningPosition: AddCleaningPositionDto): Observable<InvoiceVersionDto> {
    return this.api.addCleaningPosition(cleaningPosition, invoiceId)
      .pipe(map((res) => res.data as InvoiceVersionDto));
  }

  transfer(invoiceId: number, transferData: TransferInvoiceDto): Observable<CreditNoteDto> {
    return this.api.transferReceipt(transferData, invoiceId).pipe(
      map(res => ApiResponseUtils.getDataFromResponse(res))
    );
  }

  getTransferredCreditNotes(params: AntCollectionSortLoadingParams<CreditNoteDto>): Observable<AntDataResponse<CreditNoteDto>> {
    return this.api.getTransferredCreditNotes(
      LoadingParamsUtils.getIdFrom(params),
      ...LoadingParamsUtils.transformToArray(params)
    ).pipe(
      map(res => ApiResponseUtils.getAntDataResponseFromResponse(res))
    );
  }

  updateReleasedReceipt(invoiceId: number, data: BaseReceiptSystemPropertiesDto<InvoiceStatusDto>): Observable<InvoiceVersionDto> {
    return this.api.updateReleasedReceipt(invoiceId, data).pipe(
      map(res => ApiResponseUtils.getDataFromResponse(res))
    );
  }

  addMovePosition(invoiceId: number, movePositionDto: AddMovePositionDto): Observable<InvoiceVersionDto> {
    return this.api.addMovePosition(movePositionDto, invoiceId).pipe(
      map(ApiResponseUtils.getDataFromResponse)
    );
  }

  addTheftProtectionPosition(invoiceId: number, infos: AddTheftProtectionPositionDto): Observable<InvoiceDto | null> {
    return this.api.addTheftProtection(invoiceId, infos).pipe(map(result => result.data));
  }
}
