import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@core/models';
import { BreadcrumbService } from '../services';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: readonly Breadcrumb[] = [];
  readonly maxBreadcrumbsStart = 2;
  readonly maxBreadcrumbsEnd = 3;
  readonly maxBreadcrumbCount = this.maxBreadcrumbsStart + this.maxBreadcrumbsEnd;

  constructor(protected breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.breadcrumbs = this.breadcrumbService.getAll();
  }

  getLinkByIndex(i: number): string {
    const all = this.breadcrumbs;
    const link = ['.'];

    for (let h = 0; h <= i && h < all.length; h++) {
      link.push(all[h].link);
    }
    return link.join('/');
  }

}
