import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from '@shared/permissions/has-permission.directive';
import { HasPermissionPipe } from '@shared/permissions/has-permission.pipe';


@NgModule({
  declarations: [
    HasPermissionDirective,
    HasPermissionPipe
  ],
  exports: [
    HasPermissionDirective
  ],
  imports: [
    CommonModule
  ]
})
export class PermissionsModule { }
