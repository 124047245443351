import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { HasPermissionService } from '@shared/permissions/services/has-permission.service';
import {
  combineLatest,
  from,
  Observable,
  of
} from 'rxjs';
import {
  combineAll,
  map,
  switchMap,
  take,
  mapTo,
  tap
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private service: HasPermissionService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const permission = next.data?.permission;
    const permissions = next.data?.permissions ?? [];
    return this.hasPermissionOrReturnUrlTree(permission, permissions);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const permission = next.data?.permission;
    const permissions = next.data?.permissions ?? [];
    return this.hasPermissionOrReturnUrlTree(permission, permissions);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    const permission = route.data?.permission;
    const permissions = route.data?.permissions ?? [];
    return this.hasPermissionOrReturnUrlTree(permission, permissions);
  }

  private hasPermission(permission: string | null | undefined): Observable<boolean> {
    return this.service.hasPermission(permission).pipe(
      switchMap(hasPermission => {
        if (!hasPermission){
          return this.router.navigate(['/no-permissions']);
        }
        return of(hasPermission);
      })
    );
  }

  private hasPermissionOrReturnUrlTree(permission: string | null | undefined, permissions: string[]): Observable<boolean | UrlTree> {
    const allPermissions = [
      permission,
      ...permissions
    ];
    return combineLatest(allPermissions.map(nextPermission => this.hasPermission(nextPermission))).pipe(
      take(1),
      map(permissionResult => {
        if (permissionResult.every(res => res)) {
          return true;
        }
        return this.router.createUrlTree(['dashboard']);
      }),
    );
  }
}
